export default class Photo {
    constructor(
        iri,
        contentUrl,
    ) {
        this.iri = iri;
        this.contentUrl = contentUrl;
    }

    static transformFromAPINested(photoData) {
        return new this(
            photoData['@id'],
            photoData.contentUrl,
        );
    }

    static transformFromLocalCacheNested(photoData) {
        return new this(
            photoData.iri,
            photoData.contentUrl,
        );
    }
}
