<template>
    <div class="VueTables__limit-field">
        <select
            :id="props.selectAttrs.id"
            :class="props.selectAttrs.class"
            @change="props.selectEvents.change"
        >
            <option
                v-for="val in props.perPageValues"
                :key="val"
                :value="val"
                :selected="val===props.selectAttrs.value"
            >
                {{ val }}
            </option>
        </select>

        <label :class="props.labelClass" :for="`VueTables__limit_${props.id}`">
            {{ props.display('limit') }}
        </label>
    </div>
</template>

<script>
export default {
    name: "MyPerPageSelector",
    props: {
        props: {
            type: Object,
            default: null,
        },
    },
}
</script>