import LanguageAPI from "../../api/language";
import LanguageOfflineRepository from "../persistence/offlineRepository/language";

const RENEW_CACHED_LANGUAGES = "RENEW_CACHED_LANGUAGES",
    RENEW_CACHED_LANGUAGES_SUCCESS = "RENEW_CACHED_LANGUAGES_SUCCESS",
    RENEW_CACHED_LANGUAGES_ERROR = "RENEW_CACHED_LANGUAGES_ERROR",

    FETCH_CACHED_LANGUAGES = "FETCH_CACHED_LANGUAGES",
    FETCH_CACHED_LANGUAGES_SUCCESS = "FETCH_CACHED_LANGUAGES_SUCCESS",
    FETCH_CACHED_LANGUAGES_ERROR = "FETCH_CACHED_LANGUAGES_ERROR";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        languages: [],
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        languages(state) {
            return Object.values(state.languages);
        },
        languageByIri: (state) => (iri) => {
            return state.languages[iri];
        },
    },
    mutations: {
        [RENEW_CACHED_LANGUAGES](state) {
            state.isLoading = true;
        },
        [RENEW_CACHED_LANGUAGES_SUCCESS](state, languages) {
            state.isLoading = false;
            state.languages = languages;
        },
        [RENEW_CACHED_LANGUAGES_ERROR](state) {
            state.isLoading = false;
        },

        [FETCH_CACHED_LANGUAGES](state) {
            state.isLoading = true;
        },
        [FETCH_CACHED_LANGUAGES_SUCCESS](state, languages) {
            state.isLoading = false;
            state.languages = languages;
        },
        [FETCH_CACHED_LANGUAGES_ERROR](state) {
            state.isLoading = false;
            state.languages = [];
        },
    },
    actions: {
        async renewAllCached({commit}) {
            commit(RENEW_CACHED_LANGUAGES);

            try {
                let languages = await LanguageAPI.findAll();

                await LanguageOfflineRepository.overwriteAll(languages);

                commit(RENEW_CACHED_LANGUAGES_SUCCESS, languages);
            }
            catch (exception) {
                commit(RENEW_CACHED_LANGUAGES_ERROR);
                throw exception;
            }
        },
        async fetchAllCached({commit}) {
            commit(FETCH_CACHED_LANGUAGES);

            try {
                let languages = await LanguageOfflineRepository.findAll();

                commit(FETCH_CACHED_LANGUAGES_SUCCESS, languages);
            }
            catch (exception) {
                commit(FETCH_CACHED_LANGUAGES_ERROR);
                throw exception;
            }
        },
    }
};
