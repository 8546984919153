var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-client-table',{attrs:{"columns":[
            'syncStatus',
            'name',
            'address',
            'vatNumber' ].concat( _vm.canViewSensitiveRetailChainFields ? ['buyerName'] : [],
            ['actions'] ),"options":{
            sortable: [
                'name',
                'address',
                'vatNumber' ].concat( _vm.canViewSensitiveRetailChainFields ? ['buyerName'] : [] ),
            columnsDisplay: Object.assign({}, {'address': 'min_tablet',
                'vatNumber': 'min_tabletL'},
                _vm.canViewSensitiveRetailChainFields ? { 'buyerName': 'min_tabletL' } : {}),
            filterAlgorithm: {
                address: function address(row, query) {
                    return row.address.toString().toLowerCase().includes(query.toLowerCase());
                },
            },
            columnsClasses: {
                'actions': 'whitespace-nowrap',
            },
            resizableColumns: false,
        }},on:{"row-click":_vm.rowClicked},scopedSlots:_vm._u([{key:"afterFilter",fn:function(){return [(_vm.canCreateRetailChain)?_c('router-link',{staticClass:"ml-2 inline-flex flex-row items-center btn-liq-default",attrs:{"to":{ name: 'retail_chain_create' }}},[_c('base-icon',{staticClass:"w-4 h-4 mr-1 -ml-1",attrs:{"name":"plus"}}),_vm._v(" Create ")],1):_vm._e()]},proxy:true},{key:"syncStatus",fn:function(ref){
        var row = ref.row;
return [_c('entity-sync-status',{attrs:{"sync-status":row.syncStatus}})]}},{key:"address",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.address.toString())+" ")]}},{key:"h__syncStatus",fn:function(){return [_vm._v(" ​ ")]},proxy:true},{key:"h__vatNumber",fn:function(){return [_vm._v(" VAT number ")]},proxy:true},{key:"h__buyerName",fn:function(){return [_vm._v(" Buyer ")]},proxy:true},{key:"actions",fn:function(ref){
        var row = ref.row;
return [(_vm.canUpdateRetailChain)?_c('router-link',{staticClass:"inline-block rounded-full transition-colors duration-200 ease-out hover:bg-liq-b hover:text-liq-c",attrs:{"to":{ name: 'retail_chain_edit', params: { retailChainId: row.id }}}},[_c('base-icon',{staticClass:"w-4 h-4 m-2",attrs:{"name":"pencil"}})],1):_vm._e()]}},{key:"afterTable",fn:function(){return [_c('table-loader',{attrs:{"display-loader":_vm.displayLoader}})]},proxy:true}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}}),_c('data-export-button',{attrs:{"table-export-mapping":_vm.excelExportMapping,"items-to-export":_vm.items,"data-subject":"RetailChain"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }