import UserAPI from "../../api/user";
import UserOfflineRepository from "../persistence/offlineRepository/user";
import UserModel from "../../model/user";
import SyncStatusEnum from "../sync/syncStatusEnum";
import ApiIdAlreadyInsertedException from "../../exception/apiIdAlreadyInsertedException";

const CREATING_USER = "CREATING_USER",
    CREATING_USER_SUCCESS = "CREATING_USER_SUCCESS",
    CREATING_USER_ERROR = "CREATING_USER_ERROR",

    UPDATING_USER = "UPDATING_USER",
    UPDATING_USER_SUCCESS = "UPDATING_USER_SUCCESS",
    UPDATING_USER_ERROR = "UPDATING_USER_ERROR",

    RENEW_CACHED_USERS = "RENEW_CACHED_USERS",
    RENEW_CACHED_USERS_SUCCESS = "RENEW_CACHED_USERS_SUCCESS",
    RENEW_CACHED_USERS_ERROR = "RENEW_CACHED_USERS_ERROR",

    FETCH_CACHED_USERS = "FETCH_CACHED_USERS",
    FETCH_CACHED_USERS_SUCCESS = "FETCH_CACHED_USERS_SUCCESS",
    FETCH_CACHED_USERS_ERROR = "FETCH_CACHED_USERS_ERROR",

    SYNC_LOCALLY_CREATED_USERS = "SYNC_LOCALLY_CREATED_USERS",
    SYNC_LOCALLY_CREATED_USERS_SUCCESS = "SYNC_LOCALLY_CREATED_USERS_SUCCESS",
    SYNC_LOCALLY_CREATED_USERS_ERROR = "SYNC_LOCALLY_CREATED_USERS_ERROR",

    SYNC_LOCALLY_UPDATED_USERS = "SYNC_LOCALLY_UPDATED_USERS",
    SYNC_LOCALLY_UPDATED_USERS_SUCCESS = "SYNC_LOCALLY_UPDATED_USERS_SUCCESS",
    SYNC_LOCALLY_UPDATED_USERS_ERROR = "SYNC_LOCALLY_UPDATED_USERS_ERROR";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        users: []
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        users(state) {
            return Object.values(state.users);
        },
        userById: (state) => (id) => {
            return Object.values(state.users).find(user => user.id === id)
        },
        userByIri: (state) => (iri) => {
            return state.users[iri];
        },
        locallyCreatedUsers(state) {
            return Object.values(state.users).filter(user => SyncStatusEnum.CREATED_LOCALLY === user.syncStatus)
        },
        locallyUpdatedUsers(state) {
            return Object.values(state.users).filter(user => SyncStatusEnum.UPDATED_LOCALLY === user.syncStatus)
        },
    },
    mutations: {
        [CREATING_USER](state) {
            state.isLoading = true;
        },
        [CREATING_USER_SUCCESS](state, users) {
            state.isLoading = false;
            state.users = users;
        },
        [CREATING_USER_ERROR](state) {
            state.isLoading = false;
        },

        [UPDATING_USER](state) {
            state.isLoading = true;
        },
        [UPDATING_USER_SUCCESS](state, users) {
            state.isLoading = false;
            state.users = users;
        },
        [UPDATING_USER_ERROR](state) {
            state.isLoading = false;
        },

        [RENEW_CACHED_USERS](state) {
            state.isLoading = true;
        },
        [RENEW_CACHED_USERS_SUCCESS](state, users) {
            state.isLoading = false;
            state.users = users;
        },
        [RENEW_CACHED_USERS_ERROR](state) {
            state.isLoading = false;
        },

        [FETCH_CACHED_USERS](state) {
            state.isLoading = true;
        },
        [FETCH_CACHED_USERS_SUCCESS](state, users) {
            state.isLoading = false;
            state.users = users;
        },
        [FETCH_CACHED_USERS_ERROR](state) {
            state.isLoading = false;
            state.users = [];
        },

        [SYNC_LOCALLY_CREATED_USERS](state) {
            state.isLoading = true;
        },
        [SYNC_LOCALLY_CREATED_USERS_SUCCESS](state) {
            state.isLoading = false;
        },
        [SYNC_LOCALLY_CREATED_USERS_ERROR](state) {
            state.isLoading = false;
        },

        [SYNC_LOCALLY_UPDATED_USERS](state) {
            state.isLoading = true;
        },
        [SYNC_LOCALLY_UPDATED_USERS_SUCCESS](state) {
            state.isLoading = false;
        },
        [SYNC_LOCALLY_UPDATED_USERS_ERROR](state) {
            state.isLoading = false;
        },
    },
    actions: {
        async create({commit}, formData) {
            commit(CREATING_USER);

            try {
                let user = UserModel.createNew(formData);

                let savedUsers = await UserOfflineRepository.saveNewUser(user);

                commit(CREATING_USER_SUCCESS, savedUsers);
            }
            catch (exception) {
                commit(CREATING_USER_ERROR);
                throw exception;
            }
        },
        async update({commit, getters}, formData) {
            commit(UPDATING_USER);

            try {
                let user = getters["userById"](formData.id);

                user.commitUpdate(formData);

                let users = await UserOfflineRepository.saveExistingUser(user);

                commit(UPDATING_USER_SUCCESS, users);
            }
            catch (exception) {
                commit(UPDATING_USER_ERROR);
                throw exception;
            }
        },
        async renewAllCached({commit}, payload) {
            commit(RENEW_CACHED_USERS);

            try {
                let newOrUpdatedUsers = await UserAPI.findAllModifiedSince(
                    (payload && 'lastSyncedAt' in payload) ? payload.lastSyncedAt : null
                );

                const users = await UserOfflineRepository.appendUsers(newOrUpdatedUsers);

                commit(RENEW_CACHED_USERS_SUCCESS, users);
            }
            catch (exception) {
                commit(RENEW_CACHED_USERS_ERROR);
                throw exception;
            }
        },
        async fetchAllCached({commit}) {
            commit(FETCH_CACHED_USERS);

            try {
                let users = await UserOfflineRepository.findAll();

                commit(FETCH_CACHED_USERS_SUCCESS, users);
            }
            catch (exception) {
                commit(FETCH_CACHED_USERS_ERROR);
                throw exception;
            }
        },
        async syncAllLocallyCreated({commit, getters}) {
            commit(SYNC_LOCALLY_CREATED_USERS);

            try {
                let createdUsers = getters["locallyCreatedUsers"];

                for (const user of createdUsers) {
                    try {
                        await UserAPI.create(user);
                    }
                    catch (exception) {
                        if (!(exception instanceof ApiIdAlreadyInsertedException)) { // See README.md for explanation
                            throw exception;
                        }
                    }

                    const users = await UserOfflineRepository.deleteUser(user);
                    commit(RENEW_CACHED_USERS_SUCCESS, users);
                }

                commit(SYNC_LOCALLY_CREATED_USERS_SUCCESS);
            }
            catch (exception) {
                commit(SYNC_LOCALLY_CREATED_USERS_ERROR);
                throw exception;
            }
        },
        async syncAllLocallyUpdated({commit, getters}) {
            commit(SYNC_LOCALLY_UPDATED_USERS);

            try {
                let updatedUsers = getters["locallyUpdatedUsers"];

                for (const user of updatedUsers) {
                    await UserAPI.partialUpdate(user.id, user);
                    const users = await UserOfflineRepository.deleteUser(user);
                    commit(RENEW_CACHED_USERS_SUCCESS, users);
                }

                commit(SYNC_LOCALLY_UPDATED_USERS_SUCCESS);
            }
            catch (exception) {
                commit(SYNC_LOCALLY_UPDATED_USERS_ERROR);
                throw exception;
            }
        },
    }
};
