import Storage from "../persistentStorage";
import CountryModel from "../../../model/country";

const KEY_COUNTRIES = "liq_countries";

export default {
    async findAll() {
        let countries = await Storage.getItem(KEY_COUNTRIES);

        if (null === countries) {
            return [];
        }

        let countryObjects = [];
        const countryEntries = Object.entries(countries);
        for (const [countryIri, countryData] of countryEntries) {
            countryObjects[countryIri] = CountryModel.transformFromLocalCache(countryData);
        }

        return countryObjects;
    },
    async overwriteAll(countries) {
        await Storage.setItem(KEY_COUNTRIES, countries);
    }
};
