export default class Country {
    constructor(
        iri,
        id,
        name,
    ) {
        this.iri = iri;
        this.id = id;
        this.name = name;
    }

    static transformFromAPI(countryData) {
        return new this(
            countryData['@id'],
            countryData.id,
            countryData.name,
        );
    }

    static transformFromLocalCache(countryData) {
        return new this(
            countryData.iri,
            countryData.id,
            countryData.name,
        );
    }
}
