import StoreModel from "../model/store";
import baseApi from "./base/baseApi";
import baseAxios from "./base/baseAxios";

export default {
    async create(store) {
        const payload = store.transformToApi(true);

        return await baseAxios.post(
            "/stores",
            payload,
            {
                addAuthorizationHeader: true,
            }
        );
    },
    async partialUpdate(storeId, store) {
        const payload = store.transformToApi(false);

        return await baseAxios.put(
            `/stores/${storeId}`,
            payload,
            {
                addAuthorizationHeader: true,
            }
        );
    },
    async delete(storeId) {
        return await baseAxios.delete(
            `/stores/${storeId}`,
            {
                addAuthorizationHeader: true,
            }
        );
    },
    async findAllModifiedSince(lastSyncedAt) {
        return await baseApi.fetchCollectionPage(
            "/stores",
            1,
            lastSyncedAt,
            (itemData) => {
                return StoreModel.transformFromAPI(itemData)
            },
        );
    },
};
