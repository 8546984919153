<template>
    <t-input-group
        :label="label ? (label + (field.$params.required ? ' *' : '')) : null"
        :variant="{
            error: field.$dirty && field.$error,
            success: field.$dirty && !field.$error
        }"
    >
        <t-input
            v-model.trim.lazy="field.$model"
            :variant="{
                error: field.$dirty && field.$error,
                success: field.$dirty && !field.$error,
            }"
            :disabled="disabled"
            :type="type"
            :placeholder="placeholder"
            @focus="'number' === type ? $event.target.select() : null"
        />
        <template v-slot:feedback>
            <textual-validations
                :field="field"
                :label="label"
            />
        </template>
    </t-input-group>
</template>

<script>
import TextualValidations from "./validation/TextualValidations";
export default {
    name: "ValidatedInput",
    components: {TextualValidations},
    props: {
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
