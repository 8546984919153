<template>
    <div>
        <breadcrumbs
            v-if="user"
            :override-route-name="user.username"
        />

        <router-link
            :to="{ name: 'user_edit', params: { userId: userId }}"
            class="btn-liq-default inline-block float-right"
        >
            Edit
        </router-link>

        <h1>
            View user detail
            <entity-sync-status v-if="user" :sync-status="user.syncStatus" />
        </h1>

        <div v-if="user">
            <group-label>General</group-label>
            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <detail-item label="Username">
                        {{ user.username }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Role">
                        {{ user.roles.map(role => roleLabelByKey(role)).join(',') }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Function title">
                        {{ userFunctionLabelByKey(user.userFunction) }}
                    </detail-item>
                </div>
            </div>

            <router-link
                :to="{ name: 'user_overview' }"
                class="btn-liq-default inline-block mt-4"
            >
                Back
            </router-link>
        </div>
        <div v-else>
            Loading data...
        </div>
    </div>
</template>

<script>

import EntitySyncStatus from "../../components/EntitySyncStatus";
import GroupLabel from "../../components/ui-components/form-elements/GroupLabel";
import DetailItem from "../../components/ui-components/form-elements/DetailItem";
import Roles from "../../util/roles";
import UserFunction from "../../util/userFunction";
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    name: "UserDetail",
    components: {
        Breadcrumbs,
        DetailItem,
        GroupLabel,
        EntitySyncStatus
    },
    props: {
        userId: {
            type: [String],
            default: function () {
                return null;
            }
        }
    },
    computed: {
        user() {
            return this.$store.getters["user/userById"](this.userId);
        },
    },
    methods: {
        roleLabelByKey(roleKey) {
            return Roles.getLabelForKey(roleKey);
        },
        userFunctionLabelByKey(userFunctionKey) {
            return UserFunction.getLabelForKey(userFunctionKey);
        }
    },
};
</script>
