import StoreAPI from "../../api/store";
import StoreOfflineRepository from "../persistence/offlineRepository/store";
import StoreModel from "../../model/store";
import SyncStatusEnum from "../sync/syncStatusEnum";
import ApiIdAlreadyInsertedException from "../../exception/apiIdAlreadyInsertedException";

const CREATING_STORE = "CREATING_STORE",
    CREATING_STORE_SUCCESS = "CREATING_STORE_SUCCESS",
    CREATING_STORE_ERROR = "CREATING_STORE_ERROR",

    UPDATING_STORE = "UPDATING_STORE",
    UPDATING_STORE_SUCCESS = "UPDATING_STORE_SUCCESS",
    UPDATING_STORE_ERROR = "UPDATING_STORE_ERROR",

    DELETING_STORE = "DELETING_STORE",
    DELETING_STORE_SUCCESS = "DELETING_STORE_SUCCESS",
    DELETING_STORE_ERROR = "DELETING_STORE_ERROR",

    RENEW_CACHED_STORES = "RENEW_CACHED_STORES",
    RENEW_CACHED_STORES_SUCCESS = "RENEW_CACHED_STORES_SUCCESS",
    RENEW_CACHED_STORES_ERROR = "RENEW_CACHED_STORES_ERROR",

    FETCH_CACHED_STORES = "FETCH_CACHED_STORES",
    FETCH_CACHED_STORES_SUCCESS = "FETCH_CACHED_STORES_SUCCESS",
    FETCH_CACHED_STORES_ERROR = "FETCH_CACHED_STORES_ERROR",

    SYNC_LOCALLY_CREATED_STORES = "SYNC_LOCALLY_CREATED_STORES",
    SYNC_LOCALLY_CREATED_STORES_SUCCESS = "SYNC_LOCALLY_CREATED_STORES_SUCCESS",
    SYNC_LOCALLY_CREATED_STORES_ERROR = "SYNC_LOCALLY_CREATED_STORES_ERROR",

    SYNC_LOCALLY_UPDATED_STORES = "SYNC_LOCALLY_UPDATED_STORES",
    SYNC_LOCALLY_UPDATED_STORES_SUCCESS = "SYNC_LOCALLY_UPDATED_STORES_SUCCESS",
    SYNC_LOCALLY_UPDATED_STORES_ERROR = "SYNC_LOCALLY_UPDATED_STORES_ERROR",

    SYNC_LOCALLY_DELETED_STORES = "SYNC_LOCALLY_DELETED_STORES",
    SYNC_LOCALLY_DELETED_STORES_SUCCESS = "SYNC_LOCALLY_DELETED_STORES_SUCCESS",
    SYNC_LOCALLY_DELETED_STORES_ERROR = "SYNC_LOCALLY_DELETED_STORES_ERROR";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        stores: [],
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        stores(state) {
            return Object.values(state.stores).filter(store => SyncStatusEnum.DELETED_LOCALLY !== store.syncStatus && SyncStatusEnum.IN_SYNC_DELETED !== store.syncStatus);
        },
        storesByRetailChainIri: (state) => (retailChainIri) => {
            return Object.values(state.stores).filter(store => SyncStatusEnum.DELETED_LOCALLY !== store.syncStatus && SyncStatusEnum.IN_SYNC_DELETED !== store.syncStatus && retailChainIri === store.retailChainIri);
        },
        locallyCreatedStores(state) {
            return Object.values(state.stores).filter(store => SyncStatusEnum.CREATED_LOCALLY === store.syncStatus)
        },
        locallyUpdatedStores(state) {
            return Object.values(state.stores).filter(store => SyncStatusEnum.UPDATED_LOCALLY === store.syncStatus)
        },
        locallyDeletedStores(state) {
            return Object.values(state.stores).filter(store => SyncStatusEnum.DELETED_LOCALLY === store.syncStatus)
        },
        storeById: (state) => (id) => {
            return Object.values(state.stores).find(store => store.id === id)
        },
        storeByIri: (state) => (iri) => {
            return state.stores[iri];
        },
    },
    mutations: {
        [CREATING_STORE](state) {
            state.isLoading = true;
        },
        [CREATING_STORE_SUCCESS](state, stores) {
            state.isLoading = false;
            state.stores = stores;
        },
        [CREATING_STORE_ERROR](state) {
            state.isLoading = false;
        },

        [UPDATING_STORE](state) {
            state.isLoading = true;
        },
        [UPDATING_STORE_SUCCESS](state, stores) {
            state.isLoading = false;
            state.stores = stores;
        },
        [UPDATING_STORE_ERROR](state) {
            state.isLoading = false;
        },

        [DELETING_STORE](state) {
            state.isLoading = true;
        },
        [DELETING_STORE_SUCCESS](state, stores) {
            state.isLoading = false;
            state.stores = stores;
        },
        [DELETING_STORE_ERROR](state) {
            state.isLoading = false;
        },

        [RENEW_CACHED_STORES](state) {
            state.isLoading = true;
        },
        [RENEW_CACHED_STORES_SUCCESS](state, stores) {
            state.isLoading = false;
            state.stores = stores;
        },
        [RENEW_CACHED_STORES_ERROR](state) {
            state.isLoading = false;
        },

        [FETCH_CACHED_STORES](state) {
            state.isLoading = true;
        },
        [FETCH_CACHED_STORES_SUCCESS](state, stores) {
            state.isLoading = false;
            state.stores = stores;
        },
        [FETCH_CACHED_STORES_ERROR](state) {
            state.isLoading = false;
            state.stores = [];
        },

        [SYNC_LOCALLY_CREATED_STORES](state) {
            state.isLoading = true;
        },
        [SYNC_LOCALLY_CREATED_STORES_SUCCESS](state) {
            state.isLoading = false;
        },
        [SYNC_LOCALLY_CREATED_STORES_ERROR](state) {
            state.isLoading = false;
        },

        [SYNC_LOCALLY_UPDATED_STORES](state) {
            state.isLoading = true;
        },
        [SYNC_LOCALLY_UPDATED_STORES_SUCCESS](state) {
            state.isLoading = false;
        },
        [SYNC_LOCALLY_UPDATED_STORES_ERROR](state) {
            state.isLoading = false;
        },

        [SYNC_LOCALLY_DELETED_STORES](state) {
            state.isLoading = true;
        },
        [SYNC_LOCALLY_DELETED_STORES_SUCCESS](state) {
            state.isLoading = false;
        },
        [SYNC_LOCALLY_DELETED_STORES_ERROR](state) {
            state.isLoading = false;
        },
    },
    actions: {
        async create({commit}, formData) {
            commit(CREATING_STORE);

            try {
                let store = StoreModel.createNew(formData);

                let savedStores = await StoreOfflineRepository.saveNewStore(store);

                commit(CREATING_STORE_SUCCESS, savedStores);
            }
            catch (exception) {
                commit(CREATING_STORE_ERROR);
                throw exception;
            }
        },
        async update({commit, getters}, formData) {
            commit(UPDATING_STORE);

            try {
                let store = getters["storeById"](formData.id);

                store.commitUpdate(formData);

                let stores = await StoreOfflineRepository.saveExistingStore(store);

                commit(UPDATING_STORE_SUCCESS, stores);
            }
            catch (exception) {
                commit(UPDATING_STORE_ERROR);
                throw exception;
            }
        },
        async delete({commit, getters}, parameters) {
            commit(DELETING_STORE);

            try {
                let store = getters["storeById"](parameters.id);

                let stores = await StoreOfflineRepository.deleteStore(store);

                commit(DELETING_STORE_SUCCESS, stores);
            }
            catch (exception) {
                commit(DELETING_STORE_ERROR);
                throw exception;
            }
        },
        async renewAllCached({commit}, payload) {
            commit(RENEW_CACHED_STORES);

            try {
                let newOrUpdatedStores = await StoreAPI.findAllModifiedSince(
                    (payload && 'lastSyncedAt' in payload) ? payload.lastSyncedAt : null
                );

                const stores = await StoreOfflineRepository.appendStores(newOrUpdatedStores);

                commit(RENEW_CACHED_STORES_SUCCESS, stores);
            }
            catch (exception) {
                commit(RENEW_CACHED_STORES_ERROR);
                throw exception;
            }
        },
        async fetchAllCached({commit}) {
            commit(FETCH_CACHED_STORES);

            try {
                let stores = await StoreOfflineRepository.findAll();

                commit(FETCH_CACHED_STORES_SUCCESS, stores);
            }
            catch (exception) {
                commit(FETCH_CACHED_STORES_ERROR);
                throw exception;
            }
        },
        async syncAllLocallyCreated({commit, getters}) {
            commit(SYNC_LOCALLY_CREATED_STORES);

            try {
                let createdStores = getters["locallyCreatedStores"];

                for (const store of createdStores) {
                    try {
                        await StoreAPI.create(store);
                    }
                    catch (exception) {
                        if (!(exception instanceof ApiIdAlreadyInsertedException)) { // See README.md for explanation
                            throw exception;
                        }
                    }

                    const stores = await StoreOfflineRepository.deleteStore(store);
                    commit(RENEW_CACHED_STORES_SUCCESS, stores);
                }

                commit(SYNC_LOCALLY_CREATED_STORES_SUCCESS);
            }
            catch (exception) {
                commit(SYNC_LOCALLY_CREATED_STORES_ERROR);
                throw exception;
            }
        },
        async syncAllLocallyUpdated({commit, getters}) {
            commit(SYNC_LOCALLY_UPDATED_STORES);

            try {
                let updatedStores = getters["locallyUpdatedStores"];

                for (const store of updatedStores) {
                    await StoreAPI.partialUpdate(store.id, store);
                    const stores = await StoreOfflineRepository.deleteStore(store);
                    commit(RENEW_CACHED_STORES_SUCCESS, stores);
                }

                commit(SYNC_LOCALLY_UPDATED_STORES_SUCCESS);
            }
            catch (exception) {
                commit(SYNC_LOCALLY_UPDATED_STORES_ERROR);
                throw exception;
            }
        },
        async syncAllLocallyDeleted({commit, getters}) {
            commit(SYNC_LOCALLY_DELETED_STORES);

            try {
                let deletedStores = getters["locallyDeletedStores"];

                for (const store of deletedStores) {
                    await StoreAPI.delete(store.id);
                    const stores = await StoreOfflineRepository.deleteStore(store);
                    commit(RENEW_CACHED_STORES_SUCCESS, stores);
                }

                commit(SYNC_LOCALLY_DELETED_STORES_SUCCESS);
            }
            catch (exception) {
                commit(SYNC_LOCALLY_DELETED_STORES_ERROR);
                throw exception;
            }
        },
    }
};
