var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('required-validation',{attrs:{"field":_vm.field,"label":_vm.label}}),(_vm.field.$dirty && !_vm.field.minLength && _vm.field.$params.minLength)?[_vm._v(" "+_vm._s(_vm.label)+" must have at least "+_vm._s(_vm.field.$params.minLength.min)+" letters. ")]:_vm._e(),(_vm.field.$dirty && !_vm.field.maxLength && _vm.field.$params.maxLength)?[_vm._v(" "+_vm._s(_vm.label)+" must have no more than "+_vm._s(_vm.field.$params.maxLength.max)+" letters. ")]:_vm._e(),(_vm.field.$dirty && !_vm.field.minValue && _vm.field.$params.minValue)?[_vm._v(" "+_vm._s(_vm.label)+" must have a minimum value of "+_vm._s(_vm.field.$params.minValue.min)+". ")]:_vm._e(),(_vm.field.$dirty && !_vm.field.alpha && _vm.field.$params.alpha)?[_vm._v(" "+_vm._s(_vm.label)+" must only contain alphabet characters. ")]:_vm._e(),(_vm.field.$dirty
            && undefined !== _vm.field.sameAsPlaintextPassword
            && false === _vm.field.sameAsPlaintextPassword)?[_vm._v(" Passwords must be identical. ")]:_vm._e(),(_vm.field.$dirty
            && undefined !== _vm.field.email
            && false === _vm.field.email)?[_vm._v(" This field is not a valid email address. ")]:_vm._e(),(_vm.field.$dirty
            && undefined !== _vm.field.vat
            && false === _vm.field.vat)?[_vm._v(" This field is not a valid VAT number. ")]:_vm._e(),(_vm.field.$dirty
            && undefined !== _vm.field.isUnique
            && false === _vm.field.isUnique)?[_vm._v(" "+_vm._s(_vm.label)+" must be unique. ")]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }