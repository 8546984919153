import WordArray from 'crypto-js/lib-typedarrays';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

/*
 * S256 code_challenge = BASE64URL-ENCODE(SHA256(ASCII(code_verifier)))
 */
export default class Pkce {
    // creates a cryptographically-random code_verifier
    createCodeVerifier() {
        const randomKey = WordArray.random(32);

        return this.base64URLEncode(randomKey);
    }

    // and from this generate a code_challenge
    createCodeChallenge(codeVerifier) {
        return this.base64URLEncode(
            sha256(
                codeVerifier
            )
        );
    }

    base64URLEncode(string) {
        return string.toString(Base64)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    }
}
