import {
    TInput,
    TTextarea,
    //TSelect,
    //TRadio,
    TCheckbox,
    //TButton,
    TInputGroup,
    TCard,
    //TAlert,
    //TModal,
    TDropdown,
    TRichSelect,
    TPagination,
    //TTag,
    //TRadioGroup,
    //TCheckboxGroup,
    //TTable,
    TDatepicker,
    //TToggle,
    TDialog,
} from 'vue-tailwind/dist/components';

const borderClasses = 'border rounded shadow-sm';
const borderClassesUntouched = 'border-edge-primary';

const inputFixedClasses = 'block w-full px-3 py-2 leading-normal focus:outline-none focus:ring-2 ring-liq-c focus:border-liq-b disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200 ease-in-out';

const inputClassesUntouched = 'bg-white placeholder-gray-300';
const inputClassesError = 'text-red-600 bg-red-50 border-red-300 placeholder-red-300';
const inputClassesSuccess = 'text-green-600 bg-green-50 border-green-300 placeholder-green-300';

const vueTailwindSettings = {
    't-input': {
        component: TInput,
        props: {
            fixedClasses: inputFixedClasses + ' ' + borderClasses,
            classes: inputClassesUntouched + ' ' + borderClassesUntouched,
            variants: {
                error: inputClassesError,
                success: inputClassesSuccess,
            },
        },
    },

    't-textarea': {
        component: TTextarea,
        props: {
            fixedClasses: inputFixedClasses + ' ' + borderClasses,
            classes: inputClassesUntouched + ' ' + borderClassesUntouched,
            variants: {
                error: inputClassesError,
                success: inputClassesSuccess,
            },
        },
    },

    't-input-group': {
        component: TInputGroup,
        props: {
            fixedClasses: {
                wrapper: 'mb-4',
                label: 'block mb-1 transition-colors duration-200 ease-in-out',
                body: '',
                feedback: 'text-sm transition-colors duration-200 ease-in-out',
                description: 'text-sm'
            },
            classes: {
                wrapper: '',
                label: '',
                body: '',
                feedback: 'text-gray-500',
                description: 'text-gray-500'
            },
            variants: {
                error: {
                    label: 'text-red-500',
                    feedback: 'text-red-500'
                },
                success: {
                    label: 'text-green-500',
                    feedback: 'text-green-500'
                },
            },
        },
    },

    't-checkbox': {
        component: TCheckbox,
        props: {
            fixedClasses: 'transition duration-100 ease-in-out rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed',
            classes: 'text-liq-a border-gray-300 ',
            variants: {
                error: 'text-red-500 border-red-300',
            },
        },
    },

    't-dropdown': {
        /**
         * The dropdown for the sync menu and other things:
         * On larger screen sizes, show the popup with a fixed width, with scale + opacity animation
         * On smaller screens, prevent the popup from flowing off screen, so we use fixed positioning with margin left and right. Only add opacity animation, not the scale!
         * Because adding a transform on a fixed element breaks it:
         * https://stackoverflow.com/questions/28157125/why-does-transform-break-position-fixed
         */
        component: TDropdown,
        props: {
            fixedClasses: {
                button: 'h-full border-solid border-l border-edge-liq-a focus:outline-none hover:bg-liq-a-alt', // this is unused, it gets overrided by the trigger slot
                dropdownWrapper: 'relative z-10',
                dropdown: 'fixed sm:absolute w-56 rounded-md shadow-lg mt-2',
                enterClass: '',
                enterActiveClass: 'transition ease-out duration-100 sm:transform opacity-0 sm:scale-95',
                enterToClass: 'sm:transform opacity-100 sm:scale-100',
                leaveClass: 'transition ease-in sm:transform opacity-100 sm:scale-100',
                leaveActiveClass: '',
                leaveToClass: 'sm:transform opacity-0 sm:scale-95 duration-75'
            },
            classes: {
                wrapper: 'h-full inline-flex flex-col',
                dropdown: 'origin-top-right right-0 bg-white'
            },
            variants: {
                wide: {
                    dropdown: 'origin-top-right right-4 sm:right-0 left-4 sm:left-auto bg-white w-auto sm:w-96',
                },
                tooltip: {
                    dropdown: 'absolute origin-top-left left-1/2 transform -translate-x-1/2 p-4 bg-liq-a text-liq-c text-center',
                },
            },
        },
    },

    't-rich-select': {
        component: TRichSelect,
        props: {
            fixedClasses: {
                wrapper: 'relative',
                buttonWrapper: 'inline-block relative w-full',
                selectButton: inputFixedClasses + ' ' + borderClasses + ' flex text-left justify-between items-center',
                selectButtonLabel: 'block truncate',
                selectButtonPlaceholder: 'block truncate',
                selectButtonIcon: 'fill-current flex-shrink-0 ml-1 h-4 w-4',
                selectButtonClearButton: 'rounded flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6 transition duration-100 ease-in-out',
                selectButtonClearIcon: 'fill-current h-3 w-3',
                dropdown: 'absolute w-full z-10 -mt-1 absolute border-b border-l border-r rounded-b shadow-sm z-10',
                dropdownFeedback: '',
                loadingMoreResults: '',
                optionsList: 'overflow-auto',
                searchWrapper: 'inline-block w-full',
                searchBox: 'inline-block w-full',
                optgroup: '',
                option: 'cursor-pointer',
                disabledOption: 'opacity-50 cursor-not-allowed',
                highlightedOption: 'cursor-pointer',
                selectedOption: 'cursor-pointer',
                selectedHighlightedOption: 'cursor-pointer',
                optionContent: '',
                optionLabel: 'truncate block',
                selectedIcon: 'fill-current h-4 w-4',
                enterClass: '',
                enterActiveClass: '',
                enterToClass: '',
                leaveClass: '',
                leaveActiveClass: '',
                leaveToClass: ''
            },
            classes: {
                wrapper: '',
                buttonWrapper: '',
                selectButton: inputClassesUntouched + ' ' + borderClassesUntouched,
                selectButtonLabel: '',
                selectButtonPlaceholder: 'text-gray-400',
                selectButtonIcon: 'text-gray-600',
                selectButtonClearButton: 'hover:bg-blue-100 text-gray-600',
                selectButtonClearIcon: '',
                dropdown: 'bg-white border-gray-300',
                dropdownFeedback: 'pb-2 px-3 text-gray-400 text-sm',
                loadingMoreResults: 'pb-2 px-3 text-gray-400 text-sm',
                optionsList: '',
                searchWrapper: 'p-2 placeholder-gray-400',
                searchBox: 'px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300',
                optgroup: 'text-gray-400 uppercase text-xs py-1 px-2 font-medium',
                option: '',
                disabledOption: '',
                highlightedOption: 'bg-liq-b text-white',
                selectedOption: 'bg-liq-b-alt text-liq-c',
                selectedHighlightedOption: 'bg-liq-b text-white',
                optionContent: 'flex justify-between items-center px-3 py-2',
                optionLabel: '',
                selectedIcon: '',
                enterClass: '',
                enterActiveClass: 'opacity-0 transition ease-out duration-100',
                enterToClass: 'opacity-100',
                leaveClass: 'transition ease-in opacity-100',
                leaveActiveClass: '',
                leaveToClass: 'opacity-0 duration-75'
            },
            variants: {
                error: {
                    selectButton: inputClassesError,
                    selectButtonPlaceholder: 'text-red-200',
                    selectButtonIcon: 'text-red-500',
                    selectButtonClearButton: 'hover:bg-red-200 text-red-500',
                    dropdown: 'bg-red-50 border-red-300'
                },
                success: {
                    selectButton: inputClassesSuccess,
                    selectButtonIcon: 'text-green-500',
                    selectButtonClearButton: 'hover:bg-green-200 text-green-500',
                    dropdown: 'bg-green-50 border-green-300'
                },
            },
        },
    },

    't-datepicker': {
        component: TDatepicker,
        props: {
            fixedClasses: {
                navigator: 'flex',
                navigatorViewButton: 'flex items-center',
                navigatorViewButtonIcon: 'flex-shrink-0 h-5 w-5',
                navigatorViewButtonBackIcon: 'flex-shrink-0 h-5 w-5',
                navigatorLabel: 'flex items-center py-1',
                navigatorPrevButtonIcon: 'h-6 w-6 inline-flex',
                navigatorNextButtonIcon: 'h-6 w-6 inline-flex',
                inputWrapper: 'relative',
                viewGroup: 'inline-flex flex-wrap',
                view: 'w-64',
                calendarDaysWrapper: 'grid grid-cols-7',
                calendarHeaderWrapper: 'grid grid-cols-7',
                monthWrapper: 'grid grid-cols-4',
                yearWrapper: 'grid grid-cols-4',
                input: inputFixedClasses + ' ' + borderClasses,
                clearButton: 'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6',
                clearButtonIcon: 'fill-current h-3 w-3'
            },
            classes: {
                wrapper: 'flex flex-col',
                dropdownWrapper: 'relative z-10',
                dropdown: 'origin-top-left absolute rounded shadow bg-white overflow-hidden mt-1',
                enterClass: '',
                enterActiveClass: 'transition ease-out duration-100 transform opacity-0 scale-95',
                enterToClass: 'transform opacity-100 scale-100',
                leaveClass: 'transition ease-in transform opacity-100 scale-100',
                leaveActiveClass: '',
                leaveToClass: 'transform opacity-0 scale-95 duration-75',
                inlineWrapper: '',
                inlineViews: 'rounded bg-white border mt-1 inline-flex',
                inputWrapper: '',
                input: inputClassesUntouched + ' ' + borderClassesUntouched,
                clearButton: 'hover:bg-gray-100 rounded transition duration-100 ease-in-out text-gray-600',
                clearButtonIcon: '',
                viewGroup: '',
                view: '',
                navigator: 'pt-2 px-3',
                navigatorViewButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100',
                navigatorViewButtonIcon: 'fill-current text-gray-400',
                navigatorViewButtonBackIcon: 'fill-current text-gray-400',
                navigatorViewButtonMonth: 'text-gray-700 font-medium',
                navigatorViewButtonYear: 'text-gray-500 ml-1',
                navigatorViewButtonYearRange: 'text-gray-500 ml-1',
                navigatorLabel: 'py-1',
                navigatorLabelMonth: 'text-gray-700 font-medium',
                navigatorLabelYear: 'text-gray-500 ml-1',
                navigatorPrevButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed',
                navigatorNextButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed',
                navigatorPrevButtonIcon: 'text-gray-400',
                navigatorNextButtonIcon: 'text-gray-400',
                calendarWrapper: 'px-3 pt-2',
                calendarHeaderWrapper: '',
                calendarHeaderWeekDay: 'uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center',
                calendarDaysWrapper: '',
                calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',
                otherMonthDay: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-liq-c hover:text-liq-b text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed',
                emptyDay: '',
                inRangeFirstDay: 'text-sm bg-liq-a text-white w-full h-8 rounded-l-full',
                inRangeLastDay: 'text-sm bg-liq-a text-white w-full h-8 rounded-r-full',
                inRangeDay: 'text-sm bg-blue-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed',
                selectedDay: 'text-sm rounded-full w-8 h-8 mx-auto bg-liq-a text-white disabled:opacity-50 disabled:cursor-not-allowed',
                activeDay: 'text-sm rounded-full bg-liq-c w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
                highlightedDay: 'text-sm rounded-full bg-blue-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed',
                day: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-liq-c hover:text-liq-b disabled:opacity-50 disabled:cursor-not-allowed',
                today: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-liq-c hover:text-liq-b disabled:opacity-50 disabled:cursor-not-allowed border border-liq-a',
                monthWrapper: 'px-3 pt-2',
                selectedMonth: 'text-sm rounded w-full h-12 mx-auto bg-liq-a text-white',
                activeMonth: 'text-sm rounded w-full h-12 mx-auto bg-liq-c',
                month: 'text-sm rounded w-full h-12 mx-auto hover:bg-liq-c hover:text-liq-b',
                yearWrapper: 'px-3 pt-2',
                year: 'text-sm rounded w-full h-12 mx-auto hover:bg-liq-c hover:text-liq-b',
                selectedYear: 'text-sm rounded w-full h-12 mx-auto bg-liq-a text-white',
                activeYear: 'text-sm rounded w-full h-12 mx-auto bg-liq-c'
            },
            variants: {
                error: {
                    input: inputClassesError,
                },
                success: {
                    input: inputClassesSuccess,
                },
            },
        },
    },

    't-pagination': {
        component: TPagination,
        props: {
            classes: {
                wrapper: 'table border-collapse text-center bg-white shadow-sm',
                element: 'w-8 md:w-10 h-8 md:h-10 border border-edge-primary table-cell',
                activeElement: 'w-8 md:w-10 h-8 md:h-10 border border-edge-primary border-liq-a table-cell hover:border-liq-a',
                disabledElement: 'w-8 md:w-10 h-8 md:h-10 border border-edge-primary table-cell',
                ellipsisElement: 'w-8 md:w-10 h-8 md:h-10 border border-edge-primary hidden md:table-cell',
                activeButton: 'bg-liq-a w-full h-full text-white hover:bg-liq-a-alt transition duration-100 ease-in-out focus:outline-none text-sm md:text-base',
                disabledButton: 'opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out text-sm md:text-base',
                button: 'hover:bg-liq-c hover:text-liq-b w-full h-full transition duration-100 ease-in-out focus:outline-none text-sm md:text-base',
                ellipsis: ''
            },
        },
    },

    't-card': {
        component: TCard,
        props: {
            fixedClasses: {
                wrapper: borderClasses + ' mb-4',
                body: 'p-6',
                header: 'border-b p-3 rounded-t',
                footer: 'border-t p-3 rounded-b'
            },
            classes: {
                wrapper: borderClassesUntouched + ' bg-white',
                body: '',
                header: 'border-gray-100',
                footer: 'border-gray-100'
            },
            variants: {
                danger: {
                    wrapper: 'bg-red-50 text-red-700 border-red-200',
                    header: 'border-red-200 text-red-700',
                    footer: 'border-red-200 text-red-700'
                },
            },
        },
    },

    't-dialog': {
        component: TDialog,
        props: {
            fixedClasses: {
                overlay: 'overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed',
                wrapper: 'relative mx-auto',
                modal: 'overflow-visible relative ',
                close: 'flex items-center justify-center',
                dialog: 'overflow-visible relative',
            },
            classes: {
                close: 'bg-gray-100 text-gray-600 rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out hover:bg-gray-200 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
                closeIcon: 'fill-current h-4 w-4',

                overlay: 'z-40 bg-black bg-opacity-50',
                wrapper: 'z-50 max-w-lg px-3 py-12',
                dialog: 'bg-white shadow rounded text-left',

                body: 'p-3 space-y-3',
                buttons: 'p-3 flex space-x-4 justify-center bg-gray-100 rounded-b',

                iconWrapper: 'bg-gray-100 flex flex-shrink-0 h-12 items-center justify-center rounded-full w-12 mx-auto',
                icon: 'w-6 h-6 text-gray-500',
                content: 'w-full flex justify-center flex-col',

                titleWrapper: 'mb-4',
                title: 'text-lg font-medium text-center text-liq-b',

                textWrapper: 'text-left w-full',
                text: '',

                cancelButton: 'block px-4 py-2 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-100 focus:border-gray-100 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs',
                okButton: 'block px-4 py-2 text-white transition duration-100 ease-in-out bg-liq-a border border-transparent rounded shadow-sm hover:bg-liq-a-alt focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full max-w-xs',

                inputWrapper: 'mt-3 flex items-center space-x-3',

                input: 'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full',
                select: 'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50  disabled:opacity-50 disabled:cursor-not-allowed w-full',

                radioWrapper: 'flex items-center space-x-2',
                radio: 'text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
                radioText: '',

                checkboxWrapper: 'flex items-center space-x-2',
                checkbox: 'text-blue-500 transition duration-100 ease-in-out border-gray-300 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed',
                checkboxText: '',

                errorMessage: 'text-red-500 block text-sm',

                busyWrapper: 'absolute bg-opacity-50 bg-white flex h-full items-center justify-center left-0 top-0 w-full',
                busyIcon: 'animate-spin h-6 w-6 fill-current text-gray-500',

                overlayEnterClass: '',
                overlayEnterActiveClass: 'opacity-0 transition ease-out duration-100',
                overlayEnterToClass: 'opacity-100',
                overlayLeaveClass: 'transition ease-in opacity-100',
                overlayLeaveActiveClass: '',
                overlayLeaveToClass: 'opacity-0 duration-75',

                enterClass: '',
                enterActiveClass: '',
                enterToClass: '',
                leaveClass: '',
                leaveActiveClass: '',
                leaveToClass: '',
            },
        },
    },
}

export default vueTailwindSettings;
