<template>
    <div>
        <h1>Authorization callback</h1>
    </div>
</template>

<script>

export default {
    name: "AuthorizationCallback",
};
</script>
