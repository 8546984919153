<template>
    <tr
        :class="`VueTables__row ${props.rowAttrs.class}`"
        class="border-solid border-b border-edge-primary cursor-pointer transition-colors duration-200 ease-out hover:text-liq-b hover:bg-liq-c"
        v-bind="props.rowAttrs.attrs"
        @click="props.rowEvents.click"
    >
        <vt-child-row-toggler v-if="props.childRowTogglerFirst" :row-id="props.rowId" />
        <vt-table-cell v-for="(column, i) in props.columns" :key="i" :column="column" />
        <vt-child-row-toggler v-if="props.childRowTogglerLast" :row-id="props.rowId" />
    </tr>
</template>

<script>
import VtTableCell from "vue-tables-2/compiled/components/VtTableCell";
import VtChildRowToggler from "vue-tables-2/compiled/components/VtChildRowToggler";

export default {
    name: "MyTableRow",
    components: {VtTableCell, VtChildRowToggler},
    props: {
        props: {
            type: Object,
            default: null,
        },
    },
}
</script>

<style scoped>

</style>
