import Storage from "../persistentStorage";
import RetailChainModel from "../../../model/retailChain";

const KEY_RETAIL_CHAINS = "liq_retail_chains";

export default {
    async findAll() {
        let retailChains = await Storage.getItem(KEY_RETAIL_CHAINS);

        if (null === retailChains) {
            return [];
        }

        let retailChainsObjects = [];
        const retailChainEntries = Object.entries(retailChains);
        for (const [retailChainIri, retailChainData] of retailChainEntries) {
            retailChainsObjects[retailChainIri] = RetailChainModel.transformFromLocalCache(retailChainData);
        }

        return retailChainsObjects;
    },
    async saveNewRetailChain(retailChain) {
        let currentRetailChains = await this.findAll();

        currentRetailChains[retailChain.iri] = retailChain;
        await this.overwriteAll(currentRetailChains);

        return currentRetailChains;
    },
    async saveExistingRetailChain(updatedRetailChain) {
        let currentRetailChains = await this.findAll();

        currentRetailChains[updatedRetailChain.iri] = updatedRetailChain;

        await this.overwriteAll(currentRetailChains);

        return currentRetailChains;
    },
    async deleteRetailChain(retailChainToRemove) {
        let currentRetailChains = await this.findAll();

        if (null === retailChainToRemove) {
            return currentRetailChains;
        }

        delete currentRetailChains[retailChainToRemove.iri];

        await this.overwriteAll(currentRetailChains);

        return currentRetailChains;
    },
    async overwriteAll(retailChains) {
        await Storage.setItem(KEY_RETAIL_CHAINS, retailChains);
    },
    async appendRetailChains(newOrUpdatedRetailChains) {
        let currentRetailChains = await this.findAll();

        const newOrUpdatedRetailChainEntries = Object.entries(newOrUpdatedRetailChains);
        for (const [newOrUpdatedRetailChainIri, newOrUpdatedRetailChain] of newOrUpdatedRetailChainEntries) {
            currentRetailChains[newOrUpdatedRetailChainIri] = newOrUpdatedRetailChain;
        }

        await Storage.setItem(KEY_RETAIL_CHAINS, currentRetailChains);

        return currentRetailChains;
    },
};
