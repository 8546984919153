import SyncOfflineRepository from "../persistence/offlineRepository/sync";
import store from "../index";
import ServerTimeAPI from "../../api/serverTime";

const FETCHED_INITIAL_STATE = "FETCHED_INITIAL_STATE",
    SYNCING = "SYNCING",
    SYNCING_SUCCESSFUL = "SYNCING_SUCCESSFUL",
    SYNCING_FAILED = "SYNCING_FAILED",
    NETWORK_STATUS_CHANGED = "NETWORK_STATUS_CHANGED";

export default {
    namespaced: true,
    state: {
        lastSyncedAt: null,
        syncInProgress: false,
        isOnline: navigator.onLine, // ask this initially to the browser. The v-offline plugin then updates this value using events
    },
    getters: {
        lastSyncedAt(state) {
            return state.lastSyncedAt;
        },
        syncInProgress(state) {
            return state.syncInProgress;
        },
        isOnline(state) {
            return state.isOnline;
        },
        // `getters` is localized to this module's getters
        // you can use rootGetters via 4th argument of getters to access getters from other modules
        isAuthenticated(state, getters, rootState, rootGetters) {
            return rootGetters['security/isAuthenticated'];
        },
    },
    mutations: {
        [FETCHED_INITIAL_STATE](state, lastSyncedAt) {
            state.lastSyncedAt = lastSyncedAt;
        },
        [SYNCING](state) {
            state.syncInProgress = true;
        },
        [SYNCING_SUCCESSFUL](state, lastSyncedAt) {
            state.syncInProgress = false;
            state.lastSyncedAt = lastSyncedAt;
        },
        [SYNCING_FAILED](state) {
            state.syncInProgress = false;
        },
        [NETWORK_STATUS_CHANGED](state, isOnline) {
            state.isOnline = isOnline;
        },
    },
    actions: {
        async fetchInitialState({commit}) {
            // Initialisation action to set the default state of this store
            let lastSyncedAt = await SyncOfflineRepository.findLastSyncedAt();
            commit(FETCHED_INITIAL_STATE, lastSyncedAt);
        },
        syncData({commit, getters}, payload) {
            if (true === getters['syncInProgress']) {
                this._vm.showInfoNotification("Synchronisation already in progress", "Please wait...");

                return;
            }

            commit(SYNCING);

            if (undefined === payload) {
                payload = {};
            }
            if (undefined === payload.silenceNotifications) {
                payload.silenceNotifications = false; // If silenceNotifications = true, only notify the user of errors or if they are offline!
            }

            /*
             * Sync rules
             *
             * 1) Deletes always take precedence over other changes
             * 2) With multiple updates, the most recent version always wins
             * 3) Multiple inserts in the same position in a collection will place all conflicting elements in chronological order according to their insertion time
             *
             * Our solution:
             * 1) Do deletes first
             * 2) We don't save versioning between updates, so it's always the latest version
             * 3) Elements cannot be ordered, but they might have timestamps.
             * As a simplification, the server will accept ANY changes and overwrite anything. Per-field conflict resolution is not (yet) supported, only per-entity!
             * On error, stop the synchronisation! This avoids unsynced data being overwritten during cache renewal.
             */

            if (false === payload.silenceNotifications) {
                this._vm.showInfoNotification("Synchronisation in progress", "Synchronizing your changes to the cloud...");
            }

            const isOnline = getters["isOnline"];
            if (false === isOnline) {
                setTimeout(() => {
                    this._vm.showInfoNotification("No internet connection", "No connection is currently available - your changes will stored on your device and synced as soon as you reconnect!");
                }, 100);
                commit(SYNCING_FAILED);
                return;
            }
            const lastSyncedAt = getters["lastSyncedAt"];

            // Deletions
            store.dispatch("store/syncAllLocallyDeleted")

                // Creations
                .then(() => store.dispatch("user/syncAllLocallyCreated"))
                .then(() => store.dispatch("retailChain/syncAllLocallyCreated"))
                .then(() => store.dispatch("store/syncAllLocallyCreated"))
                .then(() => store.dispatch("fieldVisit/syncAllLocallyCreated"))

                // Updates
                .then(() => store.dispatch("user/syncAllLocallyUpdated"))
                .then(() => store.dispatch("retailChain/syncAllLocallyUpdated"))
                .then(() => store.dispatch("store/syncAllLocallyUpdated"))
                .then(() => store.dispatch("fieldVisit/syncAllLocallyUpdated"))

                // Renew all content
                .then(() => store.dispatch("user/renewAllCached", { lastSyncedAt: lastSyncedAt }))
                .then(() => store.dispatch("language/renewAllCached"))
                .then(() => store.dispatch("country/renewAllCached"))
                .then(() => store.dispatch("retailChain/renewAllCached", { lastSyncedAt: lastSyncedAt }))
                .then(() => store.dispatch("store/renewAllCached", { lastSyncedAt: lastSyncedAt }))
                .then(() => store.dispatch("fieldVisit/renewAllCached", { lastSyncedAt: lastSyncedAt }))

                .then(async () => {
                    const lastSyncedAt = await ServerTimeAPI.getServerTime();
                    await SyncOfflineRepository.saveLastSyncedAt(lastSyncedAt);
                    commit(SYNCING_SUCCESSFUL, lastSyncedAt);

                    if (false === payload.silenceNotifications) {
                        this._vm.showSuccessNotification("Synchronisation successful", "All data is up to date!");
                    }
                })
                .catch(exception => {
                    commit(SYNCING_FAILED);

                    this._vm.showErrorNotification("Synchronisation error", `An error occurred during data synchronisation:<br><br>${exception.message}`);
                    console.error(exception);
                })
            ;
        },
        updateNetworkStatus({commit, dispatch, getters}, payload) {
            if (undefined === payload) {
                payload = {};
            }
            if (undefined === payload.isOnline) {
                payload.isOnline = false;
            }

            commit(NETWORK_STATUS_CHANGED, payload.isOnline);

            if (true === payload.isOnline) { // When user regains connection, trigger resync
                console.log('Device regained connection, triggering content sync');

                if (false === getters['isAuthenticated']) {
                    console.log('Skipping content sync since not authenticated yet');
                    return;
                }

                dispatch("syncData");
            }
        },
    }
}
