import SecurityAPI from "../../api/security";
import Authenticator from "../../security/authenticator";
import SecurityOfflineRepository from "../persistence/offlineRepository/security";
import AuthException from "../../exception/authException";

const FETCHED_INITIAL_STATE = "FETCHED_INITIAL_STATE",
    REQUESTING_USER_INFO = "REQUESTING_USER_INFO",
    REQUESTING_USER_INFO_SUCCESS = "REQUESTING_USER_INFO_SUCCESS",
    REQUESTING_USER_INFO_ERROR = "REQUESTING_USER_INFO_ERROR",
    REQUESTING_TOKEN = "REQUESTING_TOKEN",
    REQUESTING_TOKEN_SUCCESS = "REQUESTING_TOKEN_SUCCESS",
    REQUESTING_TOKEN_ERROR = "REQUESTING_TOKEN_ERROR",
    REFRESHING_TOKEN = "REFRESHING_TOKEN",
    REFRESHING_TOKEN_SUCCESS = "REFRESHING_TOKEN_SUCCESS",
    REFRESHING_TOKEN_ERROR = "REFRESHING_TOKEN_ERROR",
    ERASING_CREDENTIALS_SUCCESS = "ERASING_CREDENTIALS_SUCCESS";

export default {
    namespaced: true,
    state: {
        accessToken: null,
        refreshToken: null,
        isLoading: false,
        isAuthenticated: false,
        appUserId: null,
        appUserRoles: null,
    },
    getters: {
        accessToken(state) {
            if (null === state.accessToken) {
                Authenticator.redirectToLogin();

                throw Error("Access token not found - authentication required");
            }

            return state.accessToken;
        },
        refreshToken(state) {
            if (null === state.refreshToken) {
                Authenticator.redirectToLogin();

                throw Error("Refresh token not found - authentication required");
            }

            return state.refreshToken;
        },
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
        // `getters` is localized to this module's getters
        // you can use rootGetters via 4th argument of getters to access getters from other modules
        appUser(state, getters, rootState, rootGetters) {
            return rootGetters['user/userById'](state.appUserId);
        },
        appUserId(state) {
            return state.appUserId;
        },
        hasRole(state) {
            return role => {
                return state.appUserRoles && state.appUserRoles.indexOf(role.key) !== -1;
            }
        },
        isMemberOfRoles(state) {
            return roles => {
                return state.appUserRoles && state.appUserRoles.some(userRoleKey => roles.map(role => role.key).includes(userRoleKey));
            }
        }
    },
    mutations: {
        [FETCHED_INITIAL_STATE](state, payload) {
            state.accessToken = payload.token.accessToken;
            state.refreshToken = payload.token.refreshToken;
            state.appUserId = payload.appUser.id;
            state.appUserRoles = payload.appUser.roles;
            state.isAuthenticated = null !== state.appUserId;
        },

        [REQUESTING_USER_INFO](state) {
            state.isLoading = true;
        },
        [REQUESTING_USER_INFO_SUCCESS](state, appUser) {
            state.appUserId = appUser.id;
            state.appUserRoles = appUser.roles;
            state.isLoading = false;
        },
        [REQUESTING_USER_INFO_ERROR](state) {
            state.isLoading = false;
        },

        [REQUESTING_TOKEN](state) {
            state.isLoading = true;
        },
        [REQUESTING_TOKEN_SUCCESS](state, token) {
            state.accessToken = token.accessToken;
            state.refreshToken = token.refreshToken;
            state.isLoading = false;
        },
        [REQUESTING_TOKEN_ERROR](state) {
            state.isLoading = false;
        },

        [REFRESHING_TOKEN](state) {
            state.isLoading = true;
        },
        [REFRESHING_TOKEN_SUCCESS](state, payload) {
            state.accessToken = payload.accessToken;
            state.refreshToken = payload.refreshToken;
            state.isLoading = false;
        },
        [REFRESHING_TOKEN_ERROR](state) {
            state.isLoading = false;
        },
        [ERASING_CREDENTIALS_SUCCESS](state) {
            state.accessToken = null;
            state.refreshToken = null;
        },
    },
    actions: {
        async fetchInitialState({commit}) {
            // Initialisation action to set the default state of this store
            const token = await SecurityOfflineRepository.findToken();
            const appUser = await SecurityOfflineRepository.findAppUser();

            commit(FETCHED_INITIAL_STATE, {
                token: token,
                appUser: appUser,
            });
        },
        fetchUserInfo({commit}, params) {
            commit(REQUESTING_USER_INFO);

            return SecurityAPI
                .getUserInfo(
                    params.userInfoUrl,
                ).then(async (userInfoResponse) => {
                    if (!userInfoResponse.id || !userInfoResponse.roles) {
                        commit(REQUESTING_USER_INFO_ERROR);

                        return Promise.reject(
                            new AuthException("Request user info call contained unexpected user data")
                        );
                    }

                    await SecurityOfflineRepository.saveAppUser(
                        userInfoResponse.id,
                        userInfoResponse.roles
                    );

                    commit(REQUESTING_USER_INFO_SUCCESS, {
                        id: userInfoResponse.id,
                        roles: userInfoResponse.roles,
                    });
                })
                .catch((exception) => {
                    commit(REQUESTING_USER_INFO_ERROR);

                    if (exception instanceof AuthException) {
                        return Promise.reject(exception);
                    }

                    return Promise.reject(
                        new AuthException(exception.message)
                    );
                })
            ;
        },
        performTokenRequest({commit}, params) {
            commit(REQUESTING_TOKEN);

            return SecurityAPI
                .performTokenRequest(
                    params.tokenUrl,
                    params.clientId,
                    params.redirectUri,
                    params.authorizationCode,
                    params.codeVerifier,
                ).then(async (tokenResponse) => {
                    if (!tokenResponse.access_token || !tokenResponse.refresh_token) {
                        commit(REQUESTING_TOKEN_ERROR);

                        return Promise.reject(
                            new AuthException("Request token call contained no access tokens")
                        );
                    }

                    await SecurityOfflineRepository.saveToken(
                        tokenResponse.access_token,
                        tokenResponse.refresh_token
                    );

                    commit(REQUESTING_TOKEN_SUCCESS, {
                        accessToken: tokenResponse.access_token,
                        refreshToken: tokenResponse.refresh_token,
                    });
                })
                .catch((exception) => {
                    commit(REQUESTING_TOKEN_ERROR);

                    if (exception instanceof AuthException) {
                        return Promise.reject(exception);
                    }

                    return Promise.reject(
                        new AuthException(exception.message)
                    );
                })
            ;
        },
        performTokenRefresh({commit, getters}, params) {
            commit(REFRESHING_TOKEN);

            const refreshToken = getters["refreshToken"];
            if (null === refreshToken) {
                commit(REFRESHING_TOKEN_ERROR);

                return Promise.reject(new AuthException("No refresh token found"));
            }

            return SecurityAPI
                .performTokenRefresh(
                    params.tokenUrl,
                    params.clientId,
                    refreshToken,
                )
                .then(async (tokenResponse) => {
                    if (!tokenResponse.access_token || !tokenResponse.refresh_token) {
                        commit(REFRESHING_TOKEN_ERROR);

                        return Promise.reject(
                            new AuthException("Refresh token call contained no access tokens")
                        );
                    }

                    await SecurityOfflineRepository.saveToken(
                        tokenResponse.access_token,
                        tokenResponse.refresh_token
                    );

                    commit(REFRESHING_TOKEN_SUCCESS, {
                        accessToken: tokenResponse.access_token,
                        refreshToken: tokenResponse.refresh_token,
                    });

                    return tokenResponse.access_token;
                })
                .catch((exception) => {
                    commit(REFRESHING_TOKEN_ERROR);

                    if (exception instanceof AuthException) {
                        return Promise.reject(exception);
                    }

                    return Promise.reject(
                        new AuthException(exception.message)
                    );
                })
            ;
        },
        async eraseCredentials({commit}) {
            await SecurityOfflineRepository.eraseCredentials();

            commit(ERASING_CREDENTIALS_SUCCESS);
        },
    }
}