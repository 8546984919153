<template>
    <div>
        <v-client-table
            v-model="items"
            :columns="[
                'syncStatus',
                'name',
                'address',
                'vatNumber',
                ... canViewSensitiveRetailChainFields ? ['buyerName'] : [],
                'actions',
            ]"
            :options="{
                sortable: [
                    'name',
                    'address',
                    'vatNumber',
                    ... canViewSensitiveRetailChainFields ? ['buyerName'] : [],
                ],
                columnsDisplay: {
                    'address': 'min_tablet',
                    'vatNumber': 'min_tabletL',
                    ... canViewSensitiveRetailChainFields ? { 'buyerName': 'min_tabletL' } : {},
                },
                filterAlgorithm: {
                    address(row, query) {
                        return row.address.toString().toLowerCase().includes(query.toLowerCase());
                    },
                },
                columnsClasses: {
                    'actions': 'whitespace-nowrap',
                },
                resizableColumns: false,
            }"
            @row-click="rowClicked"
        >
            <template v-slot:afterFilter>
                <router-link
                    v-if="canCreateRetailChain"
                    :to="{ name: 'retail_chain_create' }"
                    class="ml-2 inline-flex flex-row items-center btn-liq-default"
                >
                    <base-icon name="plus" class="w-4 h-4 mr-1 -ml-1" />
                    Create
                </router-link>
            </template>
            <template v-slot:syncStatus="{row}">
                <entity-sync-status :sync-status="row.syncStatus" />
            </template>
            <template v-slot:address="{row}">
                {{ row.address.toString() }}
            </template>
            <template v-slot:h__syncStatus>
                &#8203;
            </template>
            <template v-slot:h__vatNumber>
                VAT number
            </template>
            <template v-slot:h__buyerName>
                Buyer
            </template>
            <template v-slot:actions="{row}">
                <router-link
                    v-if="canUpdateRetailChain"
                    :to="{ name: 'retail_chain_edit', params: { retailChainId: row.id }}"
                    class="inline-block rounded-full transition-colors duration-200 ease-out hover:bg-liq-b hover:text-liq-c"
                >
                    <base-icon name="pencil" class="w-4 h-4 m-2" />
                </router-link>
            </template>
            <template v-slot:afterTable>
                <table-loader :display-loader="displayLoader" />
            </template>
        </v-client-table>

        <data-export-button :table-export-mapping="excelExportMapping" :items-to-export="items" data-subject="RetailChain" />
    </div>
</template>

<script>
import BaseIcon from "../../components/BaseIcon";
import EntitySyncStatus from "../../components/EntitySyncStatus";
import RetailChainVoter from "../../security/voter/retailChainVoter";
import DataExportButton from "../../components/DataExportButton";
import RetailChain from "../../model/retailChain";
import TableLoader from "../../components/TableLoader";

export default {
    name: "RetailChainTable",
    components: {
        TableLoader,
        DataExportButton,
        EntitySyncStatus,
        BaseIcon
    },
    props: {
        items: {
            type: Array,
            default: null,
        },
        displayLoader: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        canCreateRetailChain() {
            return RetailChainVoter.canCreateRetailChain();
        },
        canUpdateRetailChain() {
            return RetailChainVoter.canUpdateRetailChain();
        },
        canViewSensitiveRetailChainFields() {
            return RetailChainVoter.canViewSensitiveRetailChainFields();
        },
    },
    methods: {
        rowClicked(row) {
            this.$router.push({name: 'retail_chain_detail', params: { retailChainId: row.row.id }});
        },
        excelExportMapping: RetailChain.excelExportMapping,
    },
}
</script>
