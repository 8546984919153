var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-input-group',{attrs:{"label":_vm.label ? (_vm.label + (_vm.field.$params.sameAs || _vm.field.$params.sameAsIf ? ' *' : '')) : null,"variant":{
        error: _vm.field.$dirty && _vm.field.$error,
    }},scopedSlots:_vm._u([{key:"feedback",fn:function(){return [(
                _vm.field.$dirty && (
                    'sameAs' in _vm.field && !_vm.field.sameAs ||
                    'sameAsIf' in _vm.field && !_vm.field.sameAsIf
                )
            )?[_vm._v(" This field must be checked ")]:_vm._e()]},proxy:true}])},[_c('t-checkbox',{attrs:{"disabled":_vm.disabled,"variant":{
            error: _vm.field.$dirty && _vm.field.$error
        }},model:{value:(_vm.field.$model),callback:function ($$v) {_vm.$set(_vm.field, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"field.$model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }