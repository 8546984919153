import Vue from "vue";
import Vuex from "vuex";
import SyncModule from "./modules/sync";
import SecurityModule from "./modules/security";
import UserModule from "./modules/user";
import RetailChainModule from "./modules/retailChain";
import StoreModule from "./modules/store";
import FieldVisitModule from "./modules/fieldVisit";
import CountryModule from "./modules/country";
import LanguageModule from "./modules/language";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        sync: SyncModule,
        security: SecurityModule,
        user: UserModule,
        retailChain: RetailChainModule,
        store: StoreModule,
        fieldVisit: FieldVisitModule,
        country: CountryModule,
        language: LanguageModule,
    }
});
