import ApiException from "../exception/apiException";
import dayjs from "dayjs";
import baseAxios from "./base/baseAxios";

export default {
    async getServerTime() {
        let response = await baseAxios.get(
            '/server_time',
            {
                addAuthorizationHeader: true,
            }
        );

        if (null === response || !Object.prototype.hasOwnProperty.call(response, "data")) {
            return Promise.reject(
                new ApiException("No response data returned", response)
            );
        }

        const timeString = response.data;

        if (!dayjs(timeString).isValid()) {
            return Promise.reject(
                new ApiException("Invalid time string returned from server", timeString)
            );
        }

        return timeString;
    },
};
