<template>
    <div>
        <breadcrumbs :override-route-name="formData.username" />

        <h1>
            <span v-if="true === editingCurrentAppUser">
                Edit your user settings
            </span>
            <span v-else>
                <span v-if="!isEditForm">Create</span>
                <span v-else>Edit</span>
                user
            </span>
            <entity-sync-status v-if="user" :sync-status="user.syncStatus" />
        </h1>

        <div v-if="isFormPopulated">
            <form>
                <group-label>General</group-label>
                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <validated-input
                            label="Username"
                            :field="$v.formData.username"
                            :disabled="isEditForm"
                        />
                    </div>
                    <div>
                        <validated-rich-select
                            label="Role"
                            :field="$v.selectedRole"
                            :items="roles"
                            value-attribute="key"
                            text-attribute="label"
                            :hide-search-box="true"
                            :clearable="false"
                            :disabled="!canChangeRole"
                        />
                    </div>
                    <div>
                        <validated-rich-select
                            label="Function title"
                            :field="$v.formData.userFunction"
                            :items="userFunctions"
                            value-attribute="key"
                            text-attribute="label"
                            :disabled="!canChangeFunctionTitle"
                        />
                    </div>
                </div>

                <group-label>Change password</group-label>
                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <validated-input
                            label="Password"
                            type="password"
                            :field="$v.plaintextPassword"
                            :disabled="!canChangePassword"
                        />
                    </div>
                    <div>
                        <validated-input
                            label="Repeat password"
                            type="password"
                            :field="$v.repeatPlaintextPassword"
                            :disabled="!canChangePassword"
                        />
                    </div>
                </div>

                <button
                    type="button"
                    class="btn-liq-primary block sm:inline-block"
                    @click="submit()"
                >
                    <span v-if="!isEditForm">Create</span>
                    <span v-else>Edit</span>
                    user
                </button>

                <router-link
                    :to="{ name: 'user_overview' }"
                    class="btn-liq-default inline-block ml-0 sm:ml-2 mt-2 sm:mt-0"
                >
                    Back
                </router-link>
            </form>
        </div>
        <div v-else>
            Loading data...
        </div>
    </div>
</template>

<script>

import EntitySyncStatus from "../../components/EntitySyncStatus";
import {required, sameAs, requiredIf, minLength, alpha} from 'vuelidate/lib/validators'
import FormSubmitStatus from "../../util/formSubmitStatus";
import ValidatedInput from "../../components/ui-components/form-elements/ValidatedInput";
import ValidatedRichSelect from "../../components/ui-components/form-elements/ValidatedRichSelect";
import GroupLabel from "../../components/ui-components/form-elements/GroupLabel";
import SkuType from "../../util/skuType";
import Roles from "../../util/roles";
import UserFunction from "../../util/userFunction";
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    name: "UserForm",
    components: {Breadcrumbs, ValidatedInput, GroupLabel, ValidatedRichSelect, EntitySyncStatus},
    props: {
        userId: {
            type: [String],
            default: function () {
                return null;
            }
        },
        presetRetailChainId: {
            type: [String],
            default: function () {
                return null;
            }
        },
    },
    data() {
        const skuTypes = SkuType.all();

        return {
            isEditForm: false,
            isFormPopulated: false,
            submitStatus: null,

            skuTypes: skuTypes,
            roles: Roles.all(),
            userFunctions: UserFunction.all(),
            selectedRole: Roles.defaultRole().key,
            plaintextPassword: "",
            repeatPlaintextPassword: "",

            formData: {
                username: "",
                userFunction: UserFunction.defaultFunction().key,
            },

            createdAt: null,
            updatedAt: null,
            syncedAt: null,
            syncStatus: "",
        };
    },
    validations: {
        formData: {
            username: {
                required,
                minLength: minLength(2),
                alpha,
                isUnique(value) {
                    // standalone validator ideally should not assume a field is required
                    if (value === '') return true;

                    if (this.user && value === this.user.username) { // is it the current user? Then it's okay
                        return true;
                    }

                    if (this.users.find(user => value === user.username)) {
                        return false;
                    }

                    return true;
                },
            },
            userFunction: {
                required,
            },
        },
        plaintextPassword: {
            required: requiredIf(function () {
                return false === this.isEditForm;
            }),
            minLength: minLength(10),
        },
        repeatPlaintextPassword: {
            sameAsPlaintextPassword: sameAs('plaintextPassword'),
        },
        selectedRole: {
            required,
        },
    },
    computed: {
        users() {
            return this.$store.getters["user/users"];
        },
        user() {
            return this.$store.getters["user/userById"](this.userId);
        },
        editingCurrentAppUser() {
            const appUserId = this.$store.getters['security/appUserId'];

            return appUserId === this.userId;
        },
        canChangeRole() {
            // @improvement might move this to the UserVoter but not required
            return this.$store.getters["security/hasRole"](Roles.ROLE_ADMINISTRATOR) && false === this.editingCurrentAppUser;
        },
        canChangeFunctionTitle() {
            // @improvement might move this to the UserVoter but not required
            return this.$store.getters["security/hasRole"](Roles.ROLE_ADMINISTRATOR);
        },
        canChangePassword() {
            // @improvement might move this to the UserVoter but not required
            if (this.$store.getters["security/hasRole"](Roles.ROLE_ADMINISTRATOR)) {
                return true;
            }

            return true === this.editingCurrentAppUser;
        },
    },
    watch: {
        users: function() {
            // This watcher triggers when the users have finished loading (once per app load)
            this.populateForm();
        },
    },
    created() {
        if (null !== this.userId) {
            this.isEditForm = true;
        }
    },
    mounted() {
        // This event triggers everytime the form opens, but the first time (on reload) a user may not yet be available
        this.populateForm();
    },
    methods: {
        populateForm() {
            if (this.isFormPopulated) {
                return;
            }

            if (!this.user && this.isEditForm) {
                return;
            }

            if (this.isEditForm) {
                this.formData.username = this.user.username;
                this.selectedRole = this.user.roles.length > 0 ? this.user.roles[0] : Roles.defaultRole();
                this.formData.userFunction = this.user.userFunction;
            }

            this.isFormPopulated = true;
        },
        async submit() {
            this.$v.$touch()

            if (this.$v.$invalid) {
                this.submitStatus = FormSubmitStatus.STATUS_ERROR;
                this.showErrorNotification("Validation error", "Whoops! Please correct any validation errors and try again.");

                return;
            }

            this.submitStatus = FormSubmitStatus.STATUS_SUBMITTING;

            this.formData.roles = [
                this.selectedRole,
            ];

            if ('' !== this.plaintextPassword) {
                this.formData.newPassword = this.plaintextPassword;
            }

            try {
                if (this.isEditForm) {
                    await this.$store.dispatch(
                        "user/update",
                        {
                            id: this.userId,
                            ...this.formData,
                        }
                    );
                }
                else {
                    await this.$store.dispatch(
                        "user/create",
                        this.formData
                    );
                }
            }
            catch (exception) {
                this.showErrorNotification("Save error", `An error occurred while saving: "${exception.message}"`);
            }

            this.showSuccessNotification("Success", `User "${this.formData.username}" has been saved!`);

            await this.$store.dispatch("sync/syncData", {
                silenceNotifications: true,
            });

            this.$router.push({name: 'user_overview'});
        },
        skuTypeLabelByKey(skuTypeKey) {
            return SkuType.getLabelForKey(skuTypeKey);
        },
    },
    beforeRouteLeave(to, from, next) {
        if (!this.$v.$anyDirty) {
            next();
            return;
        }

        if (FormSubmitStatus.STATUS_SUBMITTING === this.submitStatus) {
            next();
            return;
        }

        this.$dialog
            .confirm({
                title: 'Leave without saving changes?',
                text: 'All changes will be lost!',
                icon: 'info',
                cancelButtonText: 'No, stay here!',
                okButtonText: 'Yes, leave',
            })
            .then((result) => {
                if (result && result.isOk) {
                    next();
                    return;
                }

                next(false);
            })
        ;
    },
};
</script>
