<template>
    <div>
        <v-client-table
            v-model="items"
            :columns="[
                'syncStatus',
                'username',
                'roles',
                'userFunction',
                'actions',
            ]"
            :options="{
                sortable: [
                    'username',
                    'roles',
                    'userFunction',
                ],
                columnsDisplay: {
                    'roles': 'min_tablet',
                    'userFunction': 'min_tabletL',
                },
                filterAlgorithm: {
                    roles(row, query) {
                        return row.roles.some(role => roleLabelByKey(role).toLowerCase().includes(query.toLowerCase()));
                    },
                    userFunction(row, query) {
                        return userFunctionLabelByKey(row.userFunction).toLowerCase().includes(query.toLowerCase());
                    },
                },
                columnsClasses: {
                    'actions': 'whitespace-nowrap',
                },
                resizableColumns: false,
            }"
            @row-click="rowClicked"
        >
            <template v-slot:afterFilter>
                <router-link
                    v-if="canCreateUser"
                    :to="{ name: 'user_create' }"
                    class="ml-2 inline-flex flex-row items-center btn-liq-default"
                >
                    <base-icon name="plus" class="w-4 h-4 mr-1 -ml-1" />
                    Create
                </router-link>
            </template>
            <template v-slot:syncStatus="{row}">
                <entity-sync-status :sync-status="row.syncStatus" />
            </template>
            <template v-slot:roles="{row}">
                {{ row.roles.map(role => roleLabelByKey(role)).join(',') }}
            </template>
            <template v-slot:userFunction="{row}">
                {{ userFunctionLabelByKey(row.userFunction) }}
            </template>
            <template v-slot:actions="{row}">
                <router-link
                    v-if="canUpdateUser(row.id)"
                    :to="{ name: 'user_edit', params: { userId: row.id }}"
                    class="inline-block rounded-full transition-colors duration-200 ease-out hover:bg-liq-b hover:text-liq-c"
                >
                    <base-icon name="pencil" class="w-4 h-4 m-2" />
                </router-link>
            </template>
            <template v-slot:h__syncStatus>
                &#8203;
            </template>
            <template v-slot:h__roles>
                Role
            </template>
            <template v-slot:h__userFunction>
                Function title
            </template>
            <template v-slot:afterTable>
                <table-loader :display-loader="displayLoader" />
            </template>
        </v-client-table>

        <data-export-button :table-export-mapping="excelExportMapping" :items-to-export="items" data-subject="User" />
    </div>
</template>

<script>
import Roles from "../../util/roles";
import BaseIcon from "../../components/BaseIcon";
import EntitySyncStatus from "../../components/EntitySyncStatus";
import UserFunction from "../../util/userFunction";
import UserVoter from "../../security/voter/userVoter";
import User from "../../model/user";
import DataExportButton from "../../components/DataExportButton";
import TableLoader from "../../components/TableLoader";

export default {
    name: "UserTable",
    components: {
        TableLoader,
        DataExportButton,
        EntitySyncStatus,
        BaseIcon
    },
    props: {
        items: {
            type: Array,
            default: null,
        },
        displayLoader: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        canCreateUser() {
            return UserVoter.canCreateUser();
        },
    },
    methods: {
        rowClicked(row) {
            this.$router.push({name: 'user_detail', params: { userId: row.row.id }});
        },
        roleLabelByKey(roleKey) {
            return Roles.getLabelForKey(roleKey);
        },
        userFunctionLabelByKey(userFunctionKey) {
            return UserFunction.getLabelForKey(userFunctionKey);
        },
        canUpdateUser(userId) {
            return UserVoter.canUpdateUser(userId);
        },
        excelExportMapping: User.excelExportMapping,
    },
}
</script>
