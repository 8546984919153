<template>
    <t-dropdown
        variant="tooltip"
        toggle-on-hover
        tag-name="span"
    >
        <span slot="trigger">
            <base-icon name="questionMarkCircle" class="w-6 h-6 hover:text-light-text" />
        </span>

        <template>
            <slot />
        </template>
    </t-dropdown>
</template>

<script>
import BaseIcon from "../../BaseIcon";
export default {
    name: "HelpPopover",
    components: {BaseIcon},
}
</script>
