import urlparse from 'url-parse';
import store from "../store/index";
import router from "../router/index";
import {uuid} from "vue-uuid";
import Pkce from "./pkce";
import Storage from "../store/persistence/persistentStorage";

const settings = {
    client_id: 'app_user',
    authorize_url: process.env.VUE_APP_API_URL + '/authorize',
    redirect_uri: window.location.origin + '/authorization_callback',
    token_url: process.env.VUE_APP_API_URL + '/token',
    logout_url: process.env.VUE_APP_API_URL + '/security/logout',
    pkce_code_challenge_method: 'S256',
    user_info_url: process.env.VUE_APP_API_URL + '/userinfo',
};

const KEY_AUTHORIZATION_CODE_STATE = "liq_authorization_code_state";
const KEY_PKCE_CODE_VERIFIER_SECRET = "liq_pkce_code_verifier_secret";

const pkceHelper = new Pkce();

export default {
    redirectToLogin() {
        /*
         * The state parameter is used to protect against XSRF.
         * Your application generates a random string and send it to the authorization server using the state parameter.
         * The authorization server sends back the state parameter.
         * If both states are the same => OK. If state parameters are differents, someone else has initiated the request.
         *
         * The common technique for an SPA is to store the state parameter in the browser via HTML5 session storage
         * and remove it when the response is received. It is just an unguessable value that changes for every redirect.
         */
        const randomState = uuid.v4();

        // Use localStorage instead of localForage since we need this value synchronously!
        localStorage.setItem(KEY_AUTHORIZATION_CODE_STATE, randomState);

        // creates a cryptographically-random code_verifier for PKCE
        const codeVerifier = pkceHelper.createCodeVerifier();
        // and from this generate a code_challenge
        const codeChallenge = pkceHelper.createCodeChallenge(codeVerifier);

        localStorage.setItem(KEY_PKCE_CODE_VERIFIER_SECRET, codeVerifier);

        window.location.href = settings.authorize_url
            + "?response_type=code"
            + "&client_id=" + settings.client_id
            + "&state=" + randomState
            + "&redirect_uri=" + settings.redirect_uri
            + "&code_challenge=" + codeChallenge
            + "&code_challenge_method=" + settings.pkce_code_challenge_method
        ;
    },

    redirectToLogout() {
        window.location.href = settings.logout_url;
    },

    async handleLoginResponse() {
        // If the page loads with a state and code query parameter we classify it as a valid OAuth response
        const urlData = urlparse(location.href, true);
        if (!urlData.query || !urlData.query.state || !urlData.query.code) {
            throw Error('Invalid request - required auth parameters not set');
        }

        const savedState = localStorage.getItem(KEY_AUTHORIZATION_CODE_STATE);
        localStorage.removeItem(KEY_AUTHORIZATION_CODE_STATE);
        if (!savedState) {
            throw Error('Invalid request - no saved state parameter found');
        }

        const savedPkceCodeVerifier = localStorage.getItem(KEY_PKCE_CODE_VERIFIER_SECRET);
        localStorage.removeItem(KEY_PKCE_CODE_VERIFIER_SECRET);
        if (!savedPkceCodeVerifier) {
            throw Error('Invalid request - no saved PKCE code verifier found');
        }

        if (urlData.query.state !== savedState) {
            throw Error('Invalid request - invalid state parameter');
        }

        await store
            .dispatch("security/performTokenRequest", {
                tokenUrl: settings.token_url,
                clientId: settings.client_id,
                redirectUri: settings.redirect_uri,
                authorizationCode: urlData.query.code,
                codeVerifier: savedPkceCodeVerifier,
            })
            .then(async () => {
                await store.dispatch("security/fetchUserInfo", {
                    userInfoUrl: settings.user_info_url,
                });
            })
            .then(() => this.removeAllAppData())
        ;

        // Always replace the browser location, to remove OAuth details from back navigation (auth code & state)
        history.replaceState({}, document.title, settings.redirect_uri);

        router.push({name: "home"});
    },

    removeAllAppData() {
        return Storage.iterate(function(value, key/*, iterationNumber*/) {
            // Resulting key/value pair -- this callback will be executed for every item in the database.

            // Return the app to the minimal state without requiring the user to re-log in
            const ignoredItems = [
                'liq_access_token',
                'liq_app_user_id',
                'liq_app_user_roles',
                'liq_refresh_token',
            ];

            if (!ignoredItems.includes(key)) {
                console.log('Deleting data with key', key);

                Storage.removeItem(key);
            }
        }).then(function() {
            console.log('Clearing stored data has completed');
        }).catch(function(err) {
            console.log(err);
        });
    },

    refreshToken() {
        return store.dispatch("security/performTokenRefresh", {
            tokenUrl: settings.token_url,
            clientId: settings.client_id,
        });
    },

    revokeCredentials() {
        this
            .removeAllAppData()
            .then(() => store.dispatch("security/eraseCredentials"))
            .then(() => {
                this.redirectToLogin();
            })
        ;
    },

    logout() {
        this
            .removeAllAppData()
            .then(() => store.dispatch("security/eraseCredentials"))
            .then(() => {
                this.redirectToLogout();
            })
        ;
    },
}
