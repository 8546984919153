import FieldVisitAPI from "../../api/fieldVisit";
import FieldVisitOfflineRepository from "../persistence/offlineRepository/fieldVisit";
import FieldVisitModel from "../../model/fieldVisit";
import SyncStatusEnum from "../sync/syncStatusEnum";
import ApiIdAlreadyInsertedException from "../../exception/apiIdAlreadyInsertedException";

const CREATING_FIELD_VISIT = "CREATING_FIELD_VISIT",
    CREATING_FIELD_VISIT_SUCCESS = "CREATING_FIELD_VISIT_SUCCESS",
    CREATING_FIELD_VISIT_ERROR = "CREATING_FIELD_VISIT_ERROR",

    UPDATING_FIELD_VISIT = "UPDATING_FIELD_VISIT",
    UPDATING_FIELD_VISIT_SUCCESS = "UPDATING_FIELD_VISIT_SUCCESS",
    UPDATING_FIELD_VISIT_ERROR = "UPDATING_FIELD_VISIT_ERROR",

    RENEW_CACHED_FIELD_VISITS = "RENEW_CACHED_FIELD_VISITS",
    RENEW_CACHED_FIELD_VISITS_SUCCESS = "RENEW_CACHED_FIELD_VISITS_SUCCESS",
    RENEW_CACHED_FIELD_VISITS_ERROR = "RENEW_CACHED_FIELD_VISITS_ERROR",

    FETCH_CACHED_FIELD_VISITS = "FETCH_CACHED_FIELD_VISITS",
    FETCH_CACHED_FIELD_VISITS_SUCCESS = "FETCH_CACHED_FIELD_VISITS_SUCCESS",
    FETCH_CACHED_FIELD_VISITS_ERROR = "FETCH_CACHED_FIELD_VISITS_ERROR",

    SYNC_LOCALLY_CREATED_FIELD_VISITS = "SYNC_LOCALLY_CREATED_FIELD_VISITS",
    SYNC_LOCALLY_CREATED_FIELD_VISITS_SUCCESS = "SYNC_LOCALLY_CREATED_FIELD_VISITS_SUCCESS",
    SYNC_LOCALLY_CREATED_FIELD_VISITS_ERROR = "SYNC_LOCALLY_CREATED_FIELD_VISITS_ERROR",

    SYNC_LOCALLY_UPDATED_FIELD_VISITS = "SYNC_LOCALLY_UPDATED_FIELD_VISITS",
    SYNC_LOCALLY_UPDATED_FIELD_VISITS_SUCCESS = "SYNC_LOCALLY_UPDATED_FIELD_VISITS_SUCCESS",
    SYNC_LOCALLY_UPDATED_FIELD_VISITS_ERROR = "SYNC_LOCALLY_UPDATED_FIELD_VISITS_ERROR";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        fieldVisits: [],
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        fieldVisits(state) {
            return Object.values(state.fieldVisits).filter(fieldVisit => SyncStatusEnum.IN_SYNC_DELETED !== fieldVisit.syncStatus);
        },
        fieldVisitsByStoreIri: (state) => (storeIri) => {
            return Object.values(state.fieldVisits).filter(fieldVisit => SyncStatusEnum.IN_SYNC_DELETED !== fieldVisit.syncStatus && storeIri === fieldVisit.storeIri);
        },
        locallyCreatedFieldVisits(state) {
            return Object.values(state.fieldVisits).filter(fieldVisit => SyncStatusEnum.CREATED_LOCALLY === fieldVisit.syncStatus)
        },
        locallyUpdatedFieldVisits(state) {
            return Object.values(state.fieldVisits).filter(fieldVisit => SyncStatusEnum.UPDATED_LOCALLY === fieldVisit.syncStatus)
        },
        fieldVisitById: (state) => (id) => {
            return Object.values(state.fieldVisits).find(fieldVisit => fieldVisit.id === id)
        },
    },
    mutations: {
        [CREATING_FIELD_VISIT](state) {
            state.isLoading = true;
        },
        [CREATING_FIELD_VISIT_SUCCESS](state, fieldVisits) {
            state.isLoading = false;
            state.fieldVisits = fieldVisits;
        },
        [CREATING_FIELD_VISIT_ERROR](state) {
            state.isLoading = false;
        },

        [UPDATING_FIELD_VISIT](state) {
            state.isLoading = true;
        },
        [UPDATING_FIELD_VISIT_SUCCESS](state, fieldVisits) {
            state.isLoading = false;
            state.fieldVisits = fieldVisits;
        },
        [UPDATING_FIELD_VISIT_ERROR](state) {
            state.isLoading = false;
        },

        [RENEW_CACHED_FIELD_VISITS](state) {
            state.isLoading = true;
        },
        [RENEW_CACHED_FIELD_VISITS_SUCCESS](state, fieldVisits) {
            state.isLoading = false;
            state.fieldVisits = fieldVisits;
        },
        [RENEW_CACHED_FIELD_VISITS_ERROR](state) {
            state.isLoading = false;
        },

        [FETCH_CACHED_FIELD_VISITS](state) {
            state.isLoading = true;
        },
        [FETCH_CACHED_FIELD_VISITS_SUCCESS](state, fieldVisits) {
            state.isLoading = false;
            state.fieldVisits = fieldVisits;
        },
        [FETCH_CACHED_FIELD_VISITS_ERROR](state) {
            state.isLoading = false;
            state.fieldVisits = [];
        },

        [SYNC_LOCALLY_CREATED_FIELD_VISITS](state) {
            state.isLoading = true;
        },
        [SYNC_LOCALLY_CREATED_FIELD_VISITS_SUCCESS](state) {
            state.isLoading = false;
        },
        [SYNC_LOCALLY_CREATED_FIELD_VISITS_ERROR](state) {
            state.isLoading = false;
        },

        [SYNC_LOCALLY_UPDATED_FIELD_VISITS](state) {
            state.isLoading = true;
        },
        [SYNC_LOCALLY_UPDATED_FIELD_VISITS_SUCCESS](state) {
            state.isLoading = false;
        },
        [SYNC_LOCALLY_UPDATED_FIELD_VISITS_ERROR](state) {
            state.isLoading = false;
        },
    },
    actions: {
        async create({commit}, formData) {
            commit(CREATING_FIELD_VISIT);

            try {
                let fieldVisit = FieldVisitModel.createNew(formData);

                let savedFieldVisits = await FieldVisitOfflineRepository.saveNewFieldVisit(fieldVisit);

                commit(CREATING_FIELD_VISIT_SUCCESS, savedFieldVisits);
            }
            catch (exception) {
                commit(CREATING_FIELD_VISIT_ERROR);
                throw exception;
            }
        },
        async update({commit, getters}, formData) {
            commit(UPDATING_FIELD_VISIT);

            try {
                let fieldVisit = getters["fieldVisitById"](formData.id);

                fieldVisit.commitUpdate(formData);

                let fieldVisits = await FieldVisitOfflineRepository.saveExistingFieldVisit(fieldVisit);

                commit(UPDATING_FIELD_VISIT_SUCCESS, fieldVisits);
            }
            catch (exception) {
                commit(UPDATING_FIELD_VISIT_ERROR);
                throw exception;
            }
        },
        async renewAllCached({commit}, payload) {
            commit(RENEW_CACHED_FIELD_VISITS);

            try {
                let newOrUpdatedFieldVisits = await FieldVisitAPI.findAllModifiedSince(
                    (payload && 'lastSyncedAt' in payload) ? payload.lastSyncedAt : null
                );

                const fieldVisits = await FieldVisitOfflineRepository.appendFieldVisits(newOrUpdatedFieldVisits);

                commit(RENEW_CACHED_FIELD_VISITS_SUCCESS, fieldVisits);
            }
            catch (exception) {
                commit(RENEW_CACHED_FIELD_VISITS_ERROR);
                throw exception;
            }
        },
        async fetchAllCached({commit}) {
            commit(FETCH_CACHED_FIELD_VISITS);

            try {
                let fieldVisits = await FieldVisitOfflineRepository.findAll();

                commit(FETCH_CACHED_FIELD_VISITS_SUCCESS, fieldVisits);
            }
            catch (exception) {
                commit(FETCH_CACHED_FIELD_VISITS_ERROR);
                throw exception;
            }
        },
        async syncAllLocallyCreated({commit, getters}) {
            commit(SYNC_LOCALLY_CREATED_FIELD_VISITS);

            try {
                let createdFieldVisits = getters["locallyCreatedFieldVisits"];

                for (const fieldVisit of createdFieldVisits) {
                    try {
                        await FieldVisitAPI.create(fieldVisit);
                    }
                    catch (exception) {
                        if (!(exception instanceof ApiIdAlreadyInsertedException)) { // See README.md for explanation
                            throw exception;
                        }
                    }

                    const fieldVisits = await FieldVisitOfflineRepository.deleteFieldVisit(fieldVisit);
                    commit(RENEW_CACHED_FIELD_VISITS_SUCCESS, fieldVisits);
                }

                commit(SYNC_LOCALLY_CREATED_FIELD_VISITS_SUCCESS);
            }
            catch (exception) {
                commit(SYNC_LOCALLY_CREATED_FIELD_VISITS_ERROR);
                throw exception;
            }
        },
        async syncAllLocallyUpdated({commit, getters}) {
            commit(SYNC_LOCALLY_UPDATED_FIELD_VISITS);

            try {
                let updatedFieldVisits = getters["locallyUpdatedFieldVisits"];

                for (const fieldVisit of updatedFieldVisits) {
                    await FieldVisitAPI.partialUpdate(fieldVisit.id, fieldVisit);
                    const fieldVisits = await FieldVisitOfflineRepository.deleteFieldVisit(fieldVisit);
                    commit(RENEW_CACHED_FIELD_VISITS_SUCCESS, fieldVisits);
                }

                commit(SYNC_LOCALLY_UPDATED_FIELD_VISITS_SUCCESS);
            }
            catch (exception) {
                commit(SYNC_LOCALLY_UPDATED_FIELD_VISITS_ERROR);
                throw exception;
            }
        },
    }
};
