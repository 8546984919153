import SyncStatusEnum from "../store/sync/syncStatusEnum";
import dayjs from "dayjs";
import BrandValueCheckModel from "./fieldVisit/brandValueCheck";
import SkuSurveyModel from "./fieldVisit/skuSurvey";
import PhotoModel from "./fieldVisit/photo";
import {uuid} from "vue-uuid";
import ChangeSetComputer from "../util/changeSetComputer";
import SkuType from "../util/skuType";
import BrandValueCheckType from "../util/brandValueCheckType";
import store from "../store";

export default class FieldVisit {
    constructor(
        iri,
        id,
        storeIri,
        salesRepIri,
        visitedDate,
        newCustomerStatus,
        skuSurveys,
        brandValueChecks,
        comment,
        queuedMediaUploadsBeforeVisit,
        queuedMediaUploadsAfterVisit,
        photosBeforeVisit,
        photosAfterVisit,
        freezerManagerMotivation,
        freezerManagerIsHastily,
        managerName,
        createdAt,
        updatedAt,
        syncedAt,
        syncStatus,
        modifiedFields
    ) {
        this.iri = iri;
        this.id = id;
        this.storeIri = storeIri;
        this.salesRepIri = salesRepIri;
        this.visitedDate = visitedDate;
        this.newCustomerStatus = newCustomerStatus;
        this.skuSurveys = skuSurveys;
        this.brandValueChecks = brandValueChecks;
        this.comment = comment;
        this.queuedMediaUploadsBeforeVisit = queuedMediaUploadsBeforeVisit;
        this.queuedMediaUploadsAfterVisit = queuedMediaUploadsAfterVisit;
        this.photosBeforeVisit = photosBeforeVisit;
        this.photosAfterVisit = photosAfterVisit;
        this.freezerManagerMotivation = freezerManagerMotivation;
        this.freezerManagerIsHastily = freezerManagerIsHastily;
        this.managerName = managerName;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.syncedAt = syncedAt;

        this.syncStatus = syncStatus;
        this.modifiedFields = modifiedFields;
    }

    static transformFromAPI(fieldVisitData) {
        const skuTypes = SkuType.all();
        const brandValueCheckTypes = BrandValueCheckType.all();

        return new this(
            fieldVisitData['@id'],
            fieldVisitData.id,
            fieldVisitData.store,
            fieldVisitData.salesRep,
            fieldVisitData.visitedDate,
            fieldVisitData.newCustomerStatus,
            skuTypes.reduce((resultArray, skuType) => { // to make sure that they appear in the correct order
                let skuSurvey = fieldVisitData.skuSurveys.find(skuSurvey => skuType.key === skuSurvey.type);

                if (!skuSurvey) {
                    return resultArray;
                }

                resultArray.push(SkuSurveyModel.transformFromAPINested(skuSurvey));

                return resultArray;
            }, []),
            brandValueCheckTypes.reduce((resultArray, brandValueCheckType) => {
                let brandValueCheck = fieldVisitData.brandValueChecks.find(brandValueCheck => brandValueCheckType.key === brandValueCheck.type);

                if (!brandValueCheck) {
                    return resultArray;
                }

                resultArray.push(BrandValueCheckModel.transformFromAPINested(brandValueCheck));

                return resultArray;
            }, []),
            fieldVisitData.comment,
            [],
            [],
            fieldVisitData.photosBeforeVisit.map(
                photoData => PhotoModel.transformFromAPINested(photoData)
            ),
            fieldVisitData.photosAfterVisit.map(
                photoData => PhotoModel.transformFromAPINested(photoData)
            ),
            fieldVisitData.freezerManagerMotivation,
            fieldVisitData.freezerManagerIsHastily,
            fieldVisitData.managerName,
            fieldVisitData.createdAt,
            fieldVisitData.updatedAt,
            fieldVisitData.syncedAt,
            null === fieldVisitData.deletedAt ? SyncStatusEnum.IN_SYNC : SyncStatusEnum.IN_SYNC_DELETED,
            [],
        );
    }

    transformToApi(isNewItem) {
        const fieldTransformations = {
            id: isNewItem ? {
                outputKey: 'id',
                outputValue: this.id
            } : null,
            storeIri: isNewItem ? { // an existing field visit cannot be attached to another store
                outputKey: 'store',
                outputValue: this.storeIri,
                options: {
                    filterIfNotModified: true,
                },
            } : null,
            visitedDate: {
                outputKey: 'visitedDate',
                outputValue: dayjs(this.visitedDate).toISOString(),
                options: {
                    filterIfNotModified: true,
                },
            },
            newCustomerStatus: {
                outputKey: 'newCustomerStatus',
                outputValue: this.newCustomerStatus,
                options: {
                    filterIfNotModified: true,
                },
            },
            skuSurveys: {
                outputKey: 'skuSurveys',
                outputValue: this.skuSurveys.map(skuSurvey => ({
                    iri: isNewItem ? null : {
                        outputKey: '@id', // adding @id makes API platform edit the original record instead of creating a new one
                        outputValue: skuSurvey.iri,
                    },
                    type: {
                        outputKey: 'type',
                        outputValue: skuSurvey.type,
                    },
                    skuAmount: {
                        outputKey: 'skuAmount',
                        outputValue: parseInt(skuSurvey.skuAmount),
                    },
                    newSkuAmount: {
                        outputKey: 'newSkuAmount',
                        outputValue: parseInt(skuSurvey.newSkuAmount),
                    },
                    colliOrdered: {
                        outputKey: 'colliOrdered',
                        outputValue: parseInt(skuSurvey.colliOrdered),
                    },
                })),
                options: {
                    filterIfNotModified: true,
                },
            },
            brandValueChecks: {
                outputKey: 'brandValueChecks',
                outputValue: this.brandValueChecks.map(brandValueCheck => ({
                    iri: isNewItem ? null : {
                        outputKey: '@id', // adding @id makes API platform edit the original record instead of creating a new one
                        outputValue: brandValueCheck.iri,
                    },
                    type: {
                        outputKey: 'type',
                        outputValue: brandValueCheck.type,
                    },
                    okOnArrival: {
                        outputKey: 'okOnArrival',
                        outputValue: true === brandValueCheck.okOnArrival,
                    },
                    fixedDuringVisit: {
                        outputKey: 'fixedDuringVisit',
                        outputValue: true === brandValueCheck.fixedDuringVisit,
                    },
                    fixedDuringVisitComment: {
                        outputKey: 'fixedDuringVisitComment',
                        outputValue: brandValueCheck.fixedDuringVisitComment,
                    },
                })),
                options: {
                    filterIfNotModified: true,
                },
            },
            comment: {
                outputKey: 'comment',
                outputValue: this.comment,
                options: {
                    filterIfNotModified: true,
                },
            },
            photosBeforeVisit: {
                outputKey: 'photosBeforeVisit',
                outputValue: this.photosBeforeVisit.map(photo => photo.iri),
                options: {
                    filterIfNotModified: true,
                    plainArray: true,
                },
            },
            photosAfterVisit: {
                outputKey: 'photosAfterVisit',
                outputValue: this.photosAfterVisit.map(photo => photo.iri),
                options: {
                    filterIfNotModified: true,
                    plainArray: true,
                },
            },
            freezerManagerMotivation: {
                outputKey: 'freezerManagerMotivation',
                outputValue: this.freezerManagerMotivation,
                options: {
                    filterIfNotModified: true,
                },
            },
            freezerManagerIsHastily: {
                outputKey: 'freezerManagerIsHastily',
                outputValue: true === this.freezerManagerIsHastily,
                options: {
                    filterIfNotModified: true,
                },
            },
            managerName: {
                outputKey: 'managerName',
                outputValue: this.managerName,
                options: {
                    filterIfNotModified: true
                },
            },

            createdAt: isNewItem ? {
                outputKey: 'createdAt',
                outputValue: dayjs(this.createdAt).toISOString()
            } : null,
            updatedAt: {
                outputKey: 'updatedAt',
                outputValue: dayjs(this.updatedAt).toISOString()
            },
        };

        return ChangeSetComputer.filterFieldsByChangeSet(
            fieldTransformations,
            this.modifiedFields,
            isNewItem
        );
    }

    static transformFromLocalCache(fieldVisitData) {
        const skuTypes = SkuType.all();
        const brandValueCheckTypes = BrandValueCheckType.all();

        return new this(
            fieldVisitData.iri,
            fieldVisitData.id,
            fieldVisitData.storeIri,
            fieldVisitData.salesRepIri,
            fieldVisitData.visitedDate,
            fieldVisitData.newCustomerStatus,
            skuTypes.reduce((resultArray, skuType) => { // to make sure that they appear in the correct order
                let skuSurvey = fieldVisitData.skuSurveys.find(skuSurvey => skuType.key === skuSurvey.type);

                if (!skuSurvey) {
                    return resultArray;
                }

                resultArray.push(SkuSurveyModel.transformFromLocalCacheNested(skuSurvey));

                return resultArray;
            }, []),
            brandValueCheckTypes.reduce((resultArray, brandValueCheckType) => {
                let brandValueCheck = fieldVisitData.brandValueChecks.find(brandValueCheck => brandValueCheckType.key === brandValueCheck.type);

                if (!brandValueCheck) {
                    return resultArray;
                }

                resultArray.push(BrandValueCheckModel.transformFromLocalCacheNested(brandValueCheck));

                return resultArray;
            }, []),
            fieldVisitData.comment,
            fieldVisitData.queuedMediaUploadsBeforeVisit.map(
                queuedMediaUploadData => ({
                    fileName: queuedMediaUploadData.fileName,
                    fileBase64: queuedMediaUploadData.fileBase64,
                })
            ),
            fieldVisitData.queuedMediaUploadsAfterVisit.map(
                queuedMediaUploadData => ({
                    fileName: queuedMediaUploadData.fileName,
                    fileBase64: queuedMediaUploadData.fileBase64,
                })
            ),
            fieldVisitData.photosBeforeVisit.map(
                photoData => PhotoModel.transformFromLocalCacheNested(photoData)
            ),
            fieldVisitData.photosAfterVisit.map(
                photoData => PhotoModel.transformFromLocalCacheNested(photoData)
            ),
            fieldVisitData.freezerManagerMotivation,
            fieldVisitData.freezerManagerIsHastily,
            fieldVisitData.managerName,
            fieldVisitData.createdAt,
            fieldVisitData.updatedAt,
            fieldVisitData.syncedAt,
            fieldVisitData.syncStatus,
            fieldVisitData.modifiedFields,
        );
    }

    static createNew(formData) {
        const id = uuid.v4();
        const skuTypes = SkuType.all();
        const brandValueCheckTypes = BrandValueCheckType.all();
        var now = dayjs();

        return new this(
            this.buildIriFromId(id),
            id,
            formData.storeIri,
            null,
            formData.visitedDate,
            formData.newCustomerStatus,
            skuTypes.reduce((resultArray, skuType) => { // to make sure that they appear in the correct order
                let skuSurvey = formData.skuSurveys.find(skuSurvey => skuType.key === skuSurvey.type);

                if (!skuSurvey) {
                    return resultArray;
                }

                resultArray.push(SkuSurveyModel.createNewNested(skuSurvey));

                return resultArray;
            }, []),
            brandValueCheckTypes.reduce((resultArray, brandValueCheckType) => {
                let brandValueCheck = formData.brandValueChecks.find(brandValueCheck => brandValueCheckType.key === brandValueCheck.type);

                if (!brandValueCheck) {
                    return resultArray;
                }

                resultArray.push(BrandValueCheckModel.createNewNested(brandValueCheck));

                return resultArray;
            }, []),
            formData.comment,
            formData.queuedMediaUploadsBeforeVisit,
            formData.queuedMediaUploadsAfterVisit,
            [],
            [],
            formData.freezerManagerMotivation,
            formData.freezerManagerIsHastily,
            formData.managerName,
            now.format(),
            now.format(),
            null,
            SyncStatusEnum.CREATED_LOCALLY,
            [],
        );
    }

    commitUpdate(formData) {
        ChangeSetComputer.computeChangeSet(this, formData);

        this.visitedDate = formData.visitedDate;
        this.newCustomerStatus = formData.newCustomerStatus;

        if (!this.skuSurveys) {
            this.skuSurveys = [];
        }
        for (const updatedSkuSurvey of formData.skuSurveys) {
            let skuSurvey = this.skuSurveys.find(skuSurvey => updatedSkuSurvey.type === skuSurvey.type);

            if (!skuSurvey) {
                skuSurvey = SkuSurveyModel.createNewNested(updatedSkuSurvey);

                this.skuSurveys.push(skuSurvey);
                continue;
            }

            skuSurvey.commitUpdateNested(updatedSkuSurvey);
        }

        if (!this.brandValueChecks) {
            this.brandValueChecks = [];
        }
        for (const updatedBrandValueCheck of formData.brandValueChecks) {
            let brandValueCheck = this.brandValueChecks.find(brandValueCheck => updatedBrandValueCheck.type === brandValueCheck.type);

            if (!brandValueCheck) {
                brandValueCheck = BrandValueCheckModel.createNewNested(updatedBrandValueCheck);

                this.brandValueChecks.push(brandValueCheck);
                continue;
            }

            brandValueCheck.commitUpdateNested(updatedBrandValueCheck);
        }

        this.comment = formData.comment;

        this.queuedMediaUploadsBeforeVisit = formData.queuedMediaUploadsBeforeVisit;
        this.queuedMediaUploadsAfterVisit = formData.queuedMediaUploadsAfterVisit;

        this.freezerManagerMotivation = formData.freezerManagerMotivation;
        this.freezerManagerIsHastily = formData.freezerManagerIsHastily;
        this.managerName = formData.managerName;

        var now = dayjs();

        if (null === this.createdAt) {
            this.createdAt = now.format();
        }
        this.updatedAt = now.format();
        if (SyncStatusEnum.CREATED_LOCALLY !== this.syncStatus) {
            this.syncStatus = SyncStatusEnum.UPDATED_LOCALLY;
        }
    }

    toString() {
        const visitingStore = this.getVisitingStore();

        return visitingStore ? `Field visit for ${visitingStore.toString()}` : 'Field visit';
    }

    static buildIriFromId(id) {
        return `/field_visits/${id}`
    }

    getVisitingStore() {
        return store.getters["store/storeByIri"](this.storeIri);
    }

    getSalesRep() {
        if (undefined === this.salesRepIri || null === this.salesRepIri) {
            // if salesRepIri was set to null, we assume the item was created by the app user but not yet synced
            return store.getters["security/appUser"];
        }

        const salesRep = store.getters["user/userByIri"](this.salesRepIri);

        if (undefined !== salesRep && null !== salesRep) {
            return salesRep;
        }

        // if salesRep does not exist, we assume the item was created by the app user but not yet synced
        return store.getters["security/appUser"];
    }

    static excelExportMapping(record) {
        const mapping = {
            'store': record.getVisitingStore() ? record.getVisitingStore().toString() : '',
            'salesRep': record.getSalesRep() ? record.getSalesRep().toString() : '',
            'visitedDate': record.visitedDate,
            'newCustomerStatus': record.newCustomerStatus,
            'comment': record.comment,
            'freezerManagerMotivation': record.freezerManagerMotivation,
            'freezerManagerIsHastily': record.freezerManagerIsHastily,
            'managerName': record.managerName,
        };

        for (const skuSurvey of record.skuSurveys) {
            const skuTypeLabel = SkuType.getLabelForKey(skuSurvey.type);

            mapping[`SKU actuals ${skuTypeLabel}`] = skuSurvey.skuAmount;
            mapping[`SKU new amount ${skuTypeLabel}`] = skuSurvey.newSkuAmount;
            mapping[`SKU colli ordered ${skuTypeLabel}`] = skuSurvey.colliOrdered;
        }

        for (const brandValueCheck of record.brandValueChecks) {
            const brandValueCheckTypeLabel = BrandValueCheckType.getByKey(brandValueCheck.type).label;

            mapping[`${brandValueCheckTypeLabel} OK`] = brandValueCheck.okOnArrival;
            mapping[`${brandValueCheckTypeLabel} agreement store manager fixed`] = brandValueCheck.fixedDuringVisit;
            mapping[`${brandValueCheckTypeLabel} comment`] = brandValueCheck.fixedDuringVisitComment;
        }

        return mapping;
    }
}
