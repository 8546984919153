import RetailChainAPI from "../../api/retailChain";
import RetailChainOfflineRepository from "../persistence/offlineRepository/retailChain";
import RetailChainModel from "../../model/retailChain";
import SyncStatusEnum from "../sync/syncStatusEnum";
import ApiIdAlreadyInsertedException from "../../exception/apiIdAlreadyInsertedException";

const CREATING_RETAIL_CHAIN = "CREATING_RETAIL_CHAIN",
    CREATING_RETAIL_CHAIN_SUCCESS = "CREATING_RETAIL_CHAIN_SUCCESS",
    CREATING_RETAIL_CHAIN_ERROR = "CREATING_RETAIL_CHAIN_ERROR",

    UPDATING_RETAIL_CHAIN = "UPDATING_RETAIL_CHAIN",
    UPDATING_RETAIL_CHAIN_SUCCESS = "UPDATING_RETAIL_CHAIN_SUCCESS",
    UPDATING_RETAIL_CHAIN_ERROR = "UPDATING_RETAIL_CHAIN_ERROR",

    RENEW_CACHED_RETAIL_CHAINS = "RENEW_CACHED_RETAIL_CHAINS",
    RENEW_CACHED_RETAIL_CHAINS_SUCCESS = "RENEW_CACHED_RETAIL_CHAINS_SUCCESS",
    RENEW_CACHED_RETAIL_CHAINS_ERROR = "RENEW_CACHED_RETAIL_CHAINS_ERROR",

    FETCH_CACHED_RETAIL_CHAINS = "FETCH_CACHED_RETAIL_CHAINS",
    FETCH_CACHED_RETAIL_CHAINS_SUCCESS = "FETCH_CACHED_RETAIL_CHAINS_SUCCESS",
    FETCH_CACHED_RETAIL_CHAINS_ERROR = "FETCH_CACHED_RETAIL_CHAINS_ERROR",

    SYNC_LOCALLY_CREATED_RETAIL_CHAINS = "SYNC_LOCALLY_CREATED_RETAIL_CHAINS",
    SYNC_LOCALLY_CREATED_RETAIL_CHAINS_SUCCESS = "SYNC_LOCALLY_CREATED_RETAIL_CHAINS_SUCCESS",
    SYNC_LOCALLY_CREATED_RETAIL_CHAINS_ERROR = "SYNC_LOCALLY_CREATED_RETAIL_CHAINS_ERROR",

    SYNC_LOCALLY_UPDATED_RETAIL_CHAINS = "SYNC_LOCALLY_UPDATED_RETAIL_CHAINS",
    SYNC_LOCALLY_UPDATED_RETAIL_CHAINS_SUCCESS = "SYNC_LOCALLY_UPDATED_RETAIL_CHAINS_SUCCESS",
    SYNC_LOCALLY_UPDATED_RETAIL_CHAINS_ERROR = "SYNC_LOCALLY_UPDATED_RETAIL_CHAINS_ERROR";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        retailChains: [],
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        retailChains(state) {
            return Object.values(state.retailChains);
        },
        locallyCreatedRetailChains(state) {
            return Object.values(state.retailChains).filter(retailChain => SyncStatusEnum.CREATED_LOCALLY === retailChain.syncStatus)
        },
        locallyUpdatedRetailChains(state) {
            return Object.values(state.retailChains).filter(retailChain => SyncStatusEnum.UPDATED_LOCALLY === retailChain.syncStatus)
        },
        retailChainById: (state) => (id) => {
            return Object.values(state.retailChains).find(retailChain => retailChain.id === id)
        },
        retailChainByIri: (state) => (iri) => {
            return state.retailChains[iri];
        },
    },
    mutations: {
        [CREATING_RETAIL_CHAIN](state) {
            state.isLoading = true;
        },
        [CREATING_RETAIL_CHAIN_SUCCESS](state, retailChains) {
            state.isLoading = false;
            state.retailChains = retailChains;
        },
        [CREATING_RETAIL_CHAIN_ERROR](state) {
            state.isLoading = false;
        },

        [UPDATING_RETAIL_CHAIN](state) {
            state.isLoading = true;
        },
        [UPDATING_RETAIL_CHAIN_SUCCESS](state, retailChains) {
            state.isLoading = false;
            state.retailChains = retailChains;
        },
        [UPDATING_RETAIL_CHAIN_ERROR](state) {
            state.isLoading = false;
        },

        [RENEW_CACHED_RETAIL_CHAINS](state) {
            state.isLoading = true;
        },
        [RENEW_CACHED_RETAIL_CHAINS_SUCCESS](state, retailChains) {
            state.isLoading = false;
            state.retailChains = retailChains;
        },
        [RENEW_CACHED_RETAIL_CHAINS_ERROR](state) {
            state.isLoading = false;
        },

        [FETCH_CACHED_RETAIL_CHAINS](state) {
            state.isLoading = true;
        },
        [FETCH_CACHED_RETAIL_CHAINS_SUCCESS](state, retailChains) {
            state.isLoading = false;
            state.retailChains = retailChains;
        },
        [FETCH_CACHED_RETAIL_CHAINS_ERROR](state) {
            state.isLoading = false;
            state.retailChains = [];
        },

        [SYNC_LOCALLY_CREATED_RETAIL_CHAINS](state) {
            state.isLoading = true;
        },
        [SYNC_LOCALLY_CREATED_RETAIL_CHAINS_SUCCESS](state) {
            state.isLoading = false;
        },
        [SYNC_LOCALLY_CREATED_RETAIL_CHAINS_ERROR](state) {
            state.isLoading = false;
        },

        [SYNC_LOCALLY_UPDATED_RETAIL_CHAINS](state) {
            state.isLoading = true;
        },
        [SYNC_LOCALLY_UPDATED_RETAIL_CHAINS_SUCCESS](state) {
            state.isLoading = false;
        },
        [SYNC_LOCALLY_UPDATED_RETAIL_CHAINS_ERROR](state) {
            state.isLoading = false;
        },
    },
    actions: {
        async create({commit}, formData) {
            commit(CREATING_RETAIL_CHAIN);

            try {
                let retailChain = RetailChainModel.createNew(formData);

                let savedRetailChains = await RetailChainOfflineRepository.saveNewRetailChain(retailChain);

                commit(CREATING_RETAIL_CHAIN_SUCCESS, savedRetailChains);
            }
            catch (exception) {
                commit(CREATING_RETAIL_CHAIN_ERROR);
                throw exception;
            }
        },
        async update({commit, getters}, formData) {
            commit(UPDATING_RETAIL_CHAIN);

            try {
                let retailChain = getters["retailChainById"](formData.id);

                retailChain.commitUpdate(formData);

                let retailChains = await RetailChainOfflineRepository.saveExistingRetailChain(retailChain);

                commit(UPDATING_RETAIL_CHAIN_SUCCESS, retailChains);
            }
            catch (exception) {
                commit(UPDATING_RETAIL_CHAIN_ERROR);
                throw exception;
            }
        },
        async renewAllCached({commit}, payload) {
            commit(RENEW_CACHED_RETAIL_CHAINS);

            try {
                let newOrUpdatedRetailChains = await RetailChainAPI.findAllModifiedSince(
                    (payload && 'lastSyncedAt' in payload) ? payload.lastSyncedAt : null
                );

                const retailChains = await RetailChainOfflineRepository.appendRetailChains(newOrUpdatedRetailChains);

                commit(RENEW_CACHED_RETAIL_CHAINS_SUCCESS, retailChains);
            }
            catch (exception) {
                commit(RENEW_CACHED_RETAIL_CHAINS_ERROR);
                throw exception;
            }
        },
        async fetchAllCached({commit}) {
            commit(FETCH_CACHED_RETAIL_CHAINS);

            try {
                let retailChains = await RetailChainOfflineRepository.findAll();

                commit(FETCH_CACHED_RETAIL_CHAINS_SUCCESS, retailChains);
            }
            catch (exception) {
                commit(FETCH_CACHED_RETAIL_CHAINS_ERROR);
                throw exception;
            }
        },
        async syncAllLocallyCreated({commit, getters}) {
            commit(SYNC_LOCALLY_CREATED_RETAIL_CHAINS);

            try {
                let createdRetailChains = getters["locallyCreatedRetailChains"];

                for (const retailChain of createdRetailChains) {
                    try {
                        await RetailChainAPI.create(retailChain);
                    }
                    catch (exception) {
                        if (!(exception instanceof ApiIdAlreadyInsertedException)) { // See README.md for explanation
                            throw exception;
                        }
                    }

                    const retailChains = await RetailChainOfflineRepository.deleteRetailChain(retailChain);
                    commit(RENEW_CACHED_RETAIL_CHAINS_SUCCESS, retailChains);
                }

                commit(SYNC_LOCALLY_CREATED_RETAIL_CHAINS_SUCCESS);
            }
            catch (exception) {
                commit(SYNC_LOCALLY_CREATED_RETAIL_CHAINS_ERROR);
                throw exception;
            }
        },
        async syncAllLocallyUpdated({commit, getters}) {
            commit(SYNC_LOCALLY_UPDATED_RETAIL_CHAINS);

            try {
                let updatedRetailChains = getters["locallyUpdatedRetailChains"];

                for (const retailChain of updatedRetailChains) {
                    await RetailChainAPI.partialUpdate(retailChain.id, retailChain);
                    const retailChains = await RetailChainOfflineRepository.deleteRetailChain(retailChain);
                    commit(RENEW_CACHED_RETAIL_CHAINS_SUCCESS, retailChains);
                }

                commit(SYNC_LOCALLY_UPDATED_RETAIL_CHAINS_SUCCESS);
            }
            catch (exception) {
                commit(SYNC_LOCALLY_UPDATED_RETAIL_CHAINS_ERROR);
                throw exception;
            }
        },
    }
};
