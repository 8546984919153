<template>
    <div>
        <breadcrumbs />

        <h1>Stores</h1>

        <store-table :items="stores" :display-loader="isLoading" />
    </div>
</template>

<script>

import StoreTable from "./StoreTable";
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    name: "StoreOverview",
    components: {
        Breadcrumbs,
        StoreTable,
    },
    computed: {
        isLoading() {
            return this.$store.getters["store/isLoading"];
        },
        stores() {
            return this.$store.getters["store/stores"];
        },
    },
};
</script>