<template>
    <div>
        <breadcrumbs :override-route-name="formData.name" />

        <h1>
            <span v-if="!isEditForm">Create</span>
            <span v-else>Edit</span>
            store
            <entity-sync-status v-if="store" :sync-status="store.syncStatus" />
        </h1>

        <div v-if="isFormPopulated">
            <form>
                <group-label>General</group-label>
                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <validated-input label="Name" :field="$v.formData.name" />
                    </div>
                    <div>
                        <validated-rich-select label="Retail chain" :field="$v.formData.retailChainIri" :items="retailChains" value-attribute="iri" text-attribute="name" />
                    </div>
                    <div>
                        <validated-rich-select label="Language" :field="$v.formData.languageIri" :items="languages" value-attribute="iri" text-attribute="name" />
                    </div>
                </div>

                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <group-label>SKU targets</group-label>
                        <div v-for="(v, index) in $v.formData.skuTargets.$each.$iter" :key="'sku_target' + index">
                            <validated-input :label="'SKU target ' + skuTypeLabelByKey(v.$model.type).toLowerCase()" :field="v.skuTarget" type="number" />
                        </div>
                    </div>
                </div>

                <group-label>Address</group-label>
                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <validated-input label="Street" :field="$v.formData.address.street" />
                    </div>
                    <div>
                        <validated-input label="Number" :field="$v.formData.address.number" />
                    </div>
                    <div>
                        <validated-input label="Postal code/zipcode" :field="$v.formData.address.postalCode" />
                    </div>
                    <div>
                        <validated-input label="City" :field="$v.formData.address.city" />
                    </div>
                    <div>
                        <validated-rich-select label="Country" :field="$v.formData.address.countryIri" :items="countries" value-attribute="iri" text-attribute="name" />
                    </div>
                </div>

                <button
                    type="button"
                    class="btn-liq-primary block sm:inline-block"
                    @click="submit()"
                >
                    <span v-if="!isEditForm">Create</span>
                    <span v-else>Edit</span>
                    store
                </button>

                <router-link
                    :to="{ name: 'store_overview' }"
                    class="btn-liq-default inline-block ml-0 sm:ml-2 mt-2 sm:mt-0"
                >
                    Back
                </router-link>
            </form>
        </div>
        <div v-else>
            Loading data...
        </div>
    </div>
</template>

<script>

import EntitySyncStatus from "../../components/EntitySyncStatus";
import {required, minLength, minValue, integer} from 'vuelidate/lib/validators'
import FormSubmitStatus from "../../util/formSubmitStatus";
import ValidatedInput from "../../components/ui-components/form-elements/ValidatedInput";
import ValidatedRichSelect from "../../components/ui-components/form-elements/ValidatedRichSelect";
import GroupLabel from "../../components/ui-components/form-elements/GroupLabel";
import SkuType from "../../util/skuType";
import RetailChainModel from "../../model/retailChain";
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    name: "StoreForm",
    components: {Breadcrumbs, ValidatedInput, GroupLabel, ValidatedRichSelect, EntitySyncStatus},
    props: {
        storeId: {
            type: [String],
            default: function () {
                return null;
            }
        },
        presetRetailChainId: {
            type: [String],
            default: function () {
                return null;
            }
        },
    },
    data() {
        const skuTypes = SkuType.all();

        return {
            isEditForm: false,
            isFormPopulated: false,
            submitStatus: null,

            skuTypes: skuTypes,

            formData: {
                name: "",
                retailChainIri: "",
                languageIri: "",
                skuTargets: skuTypes.map(skuType => ({
                    type: skuType.key,
                    skuTarget: 0,
                })),
                address: {
                    street: "",
                    number: "",
                    postalCode: "",
                    city: "",
                    countryIri: "",
                },
            },

            createdAt: null,
            updatedAt: null,
            syncedAt: null,
            syncStatus: "",
        };
    },
    validations: {
        formData: {
            name: {
                required,
                minLength: minLength(2),
            },
            retailChainIri: {
            },
            languageIri: {
                required,
            },
            skuTargets: {
                $each: { // order of the fields is important! Follow the same order as the object gets loaded from cache.
                    skuTarget: {
                        required,
                        integer,
                        minValue: minValue(0),
                    },
                },
            },
            address: {
                street: {
                    required,
                    minLength: minLength(2),
                },
                number: {
                    required,
                    minLength: minLength(1),
                },
                postalCode: {
                    required,
                    minLength: minLength(1),
                },
                city: {
                    required,
                    minLength: minLength(2),
                },
                countryIri: {
                    required,
                },
            },
        },
    },
    computed: {
        stores() {
            return this.$store.getters["store/stores"];
        },
        retailChains() {
            return this.$store.getters["retailChain/retailChains"];
        },
        languages() {
            return this.$store.getters["language/languages"];
        },
        countries() {
            return this.$store.getters["country/countries"];
        },
        store() {
            return this.$store.getters["store/storeById"](this.storeId);
        },
    },
    watch: {
        stores: function() {
            // This watcher triggers when the stores have finished loading (once per app load)
            this.populateForm();
        },
    },
    created() {
        if (null !== this.storeId) {
            this.isEditForm = true;
        }
    },
    mounted() {
        // This event triggers everytime the form opens, but the first time (on reload) a store may not yet be available
        this.populateForm();
    },
    methods: {
        populateForm() {
            if (this.isFormPopulated) {
                return;
            }

            if (!this.store && this.isEditForm) {
                return;
            }

            if (this.isEditForm) {
                this.formData.name = this.store.name;
                this.formData.retailChainIri = this.store.retailChainIri;
                this.formData.languageIri = this.store.languageIri;

                this.formData.skuTargets = this.skuTypes.map(skuType => {
                    if (!this.store.skuTargets) {
                        this.store.skuTargets = [];
                    }

                    let skuTarget = this.store.skuTargets.find(skuTarget => skuType.key === skuTarget.type);

                    if (!skuTarget) {
                        return {
                            type: skuType.key,
                            skuTarget: 0,
                        };
                    }

                    return {
                        type: skuType.key,
                        skuTarget: skuTarget.skuTarget,
                    };
                });

                this.formData.address.street = this.store.address.street;
                this.formData.address.number = this.store.address.number;
                this.formData.address.postalCode = this.store.address.postalCode;
                this.formData.address.city = this.store.address.city;
                this.formData.address.countryIri = this.store.address.countryIri;
            }
            else {
                if (this.presetRetailChainId) {
                    this.formData.retailChainIri = RetailChainModel.buildIriFromId(this.presetRetailChainId);
                }
            }

            this.isFormPopulated = true;
        },
        async submit() {
            this.$v.$touch()

            if (this.$v.$invalid) {
                this.submitStatus = FormSubmitStatus.STATUS_ERROR;
                this.showErrorNotification("Validation error", "Whoops! Please correct any validation errors and try again.");

                return;
            }

            this.submitStatus = FormSubmitStatus.STATUS_SUBMITTING;

            try {
                if (this.isEditForm) {
                    await this.$store.dispatch(
                        "store/update",
                        {
                            id: this.storeId,
                            ...this.formData,
                        }
                    );
                }
                else {
                    await this.$store.dispatch(
                        "store/create",
                        this.formData
                    );
                }
            }
            catch (exception) {
                this.showErrorNotification("Save error", `An error occurred while saving: "${exception.message}"`);
            }

            this.showSuccessNotification("Success", `Store "${this.formData.name}" has been saved!`);

            await this.$store.dispatch("sync/syncData", {
                silenceNotifications: true,
            });

            this.$router.push({name: 'store_overview'});
        },
        skuTypeLabelByKey(skuTypeKey) {
            return SkuType.getLabelForKey(skuTypeKey);
        },
    },
    beforeRouteLeave(to, from, next) {
        if (!this.$v.$anyDirty) {
            next();
            return;
        }

        if (FormSubmitStatus.STATUS_SUBMITTING === this.submitStatus) {
            next();
            return;
        }

        this.$dialog
            .confirm({
                title: 'Leave without saving changes?',
                text: 'All changes will be lost!',
                icon: 'info',
                cancelButtonText: 'No, stay here!',
                okButtonText: 'Yes, leave',
            })
            .then((result) => {
                if (result && result.isOk) {
                    next();
                    return;
                }

                next(false);
            })
        ;
    },
};
</script>
