<template>
    <notificationGroup group="topNotifications">
        <div class="fixed inset-0 flex px-4 py-16 pointer-events-none p-6 items-start justify-end z-20">
            <div class="max-w-sm w-full">
                <notification v-slot="{notifications}">
                    <div
                        v-for="notification in notifications"
                        :key="notification.id"
                        class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4"
                    >
                        <div
                            :class="[
                                'success' === notification.type ? 'bg-liq-success' : '',
                                'error' === notification.type ? 'bg-liq-error' : '',
                                'info' === notification.type ? 'bg-liq-info' : '',
                            ]"
                            class="flex flex-shrink-0 justify-center items-center w-12"
                        >
                            <svg
                                class="h-6 w-6 fill-current text-white"
                                viewBox="0 0 40 40"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path v-if="'success' === notification.type" d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
                                <path v-else-if="'error' === notification.type" d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
                                <path v-else-if="'info' === notification.type" d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z" />
                            </svg>
                        </div>

                        <div class="-mx-3 py-2 px-4">
                            <div class="mx-3">
                                <span
                                    :class="[
                                        'success' === notification.type ? 'text-liq-success' : '',
                                        'error' === notification.type ? 'text-liq-error' : '',
                                        'info' === notification.type ? 'text-liq-info' : '',
                                    ]"
                                    class="font-medium inline-block mb-2"
                                >
                                    {{ notification.title }}
                                </span>
                                <!-- To preserve HTML <br> tags and other formatting, we use v-html. BUT it could lead to XSS attacks so don't use it to render user input! -->
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <p class="text-gray-600 text-sm" v-html="notification.text" />
                            </div>
                        </div>
                    </div>
                </notification>
            </div>
        </div>
    </notificationGroup>
</template>

<script>
export default {
    name: "NotificationContainer",
};
</script>
