import qs from "querystring";

export default class ApiException extends Error {
    constructor(message, apiResponse) {
        let method = apiResponse.config.method.toUpperCase();
        let route = apiResponse.config.url;
        let params = qs.stringify(apiResponse.config.params);

        super(`API [<b>${method}</b> <i>${route}${params ? "?" + params : ""}</i>]<br>${message}`);
    }
}
