import FieldVisitModel from "../model/fieldVisit";
import baseApi from "./base/baseApi";
import baseAxios from "./base/baseAxios";
import mediaUploadApi from "./mediaUpload";
import ApiException from "../exception/apiException";
import PhotoModel from "../model/fieldVisit/photo";

export default {
    async create(fieldVisit) {
        await this.handleMediaUploads(fieldVisit);

        const payload = fieldVisit.transformToApi(true);

        return await baseAxios.post(
            "/field_visits",
            payload,
            {
                addAuthorizationHeader: true,
            }
        );
    },
    async partialUpdate(fieldVisitId, fieldVisit) {
        await this.handleMediaUploads(fieldVisit);

        const payload = fieldVisit.transformToApi(false);

        return await baseAxios.put(
            `/field_visits/${fieldVisitId}`,
            payload,
            {
                addAuthorizationHeader: true,
            }
        );
    },
    async handleMediaUploads(fieldVisit) {
        for (const queuedMediaUpload of fieldVisit.queuedMediaUploadsBeforeVisit) {
            const uploadedPhoto = await this.handleMediaUpload(fieldVisit, queuedMediaUpload);

            if (undefined === fieldVisit.photosBeforeVisit) {
                fieldVisit.photosBeforeVisit = [];
            }

            fieldVisit.photosBeforeVisit.push(uploadedPhoto);

            if (!Object.prototype.hasOwnProperty.call(fieldVisit.modifiedFields, 'photosBeforeVisit')) {
                fieldVisit.modifiedFields['photosBeforeVisit'] = true;
            }
        }

        for (const queuedMediaUpload of fieldVisit.queuedMediaUploadsAfterVisit) {
            const uploadedPhoto = await this.handleMediaUpload(fieldVisit, queuedMediaUpload);

            if (undefined === fieldVisit.photosAfterVisit) {
                fieldVisit.photosAfterVisit = [];
            }

            fieldVisit.photosAfterVisit.push(uploadedPhoto);

            if (!Object.prototype.hasOwnProperty.call(fieldVisit.modifiedFields, 'photosAfterVisit')) {
                fieldVisit.modifiedFields['photosAfterVisit'] = true;
            }
        }
    },
    async handleMediaUpload(fieldVisit, queuedMediaUpload) {
        const mediaUploadResponse = await mediaUploadApi.create(queuedMediaUpload)

        if (null === mediaUploadResponse || !Object.prototype.hasOwnProperty.call(mediaUploadResponse, "data")) {
            return Promise.reject(
                new ApiException("No response data returned", mediaUploadResponse)
            );
        }

        if (!Object.prototype.hasOwnProperty.call(mediaUploadResponse.data, "@id")) {
            return Promise.reject(
                new ApiException("Unexpected response format, '@id' not found", mediaUploadResponse)
            );
        }

        if (!Object.prototype.hasOwnProperty.call(mediaUploadResponse.data, "contentUrl")) {
            return Promise.reject(
                new ApiException("Unexpected response format, 'contentUrl' not found", mediaUploadResponse)
            );
        }

        return PhotoModel.transformFromAPINested(mediaUploadResponse.data);
    },
    async findAllModifiedSince(lastSyncedAt) {
        return await baseApi.fetchCollectionPage(
            "/field_visits",
            1,
            lastSyncedAt,
            (itemData) => {
                return FieldVisitModel.transformFromAPI(itemData)
            },
        );
    },
};
