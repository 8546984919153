<template>
    <t-input-group
        :label="label ? (label + (field.$params.required ? ' *' : '')) : null"
        :variant="{
            error: field.$dirty && field.$error,
            success: field.$dirty && !field.$error
        }"
    >
        <t-rich-select
            v-model.trim.lazy="field.$model"
            :delay="filterDelay"
            :fetch-options="fetchOptions"
            placeholder="Select an option..."
            searching-text=""
            :disabled="disabled"
            :value-attribute="valueAttribute"
            :text-attribute="textAttribute"
            :clearable="clearable"
            :hide-search-box="hideSearchBox"
            :minimum-input-length="minimumInputLength"
            :variant="{
                error: field.$dirty && field.$error,
                success: field.$dirty && !field.$error,
            }"
        >
            <!-- Pass all the slots down to the user of this component -->
            <template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
                <slot :name="slotName" v-bind="slotScope" />
            </template>
        </t-rich-select>

        <template v-slot:feedback>
            <required-validation
                :field="field"
                :label="label"
            />
        </template>
    </t-input-group>
</template>

<script>
import RequiredValidation from "./validation/RequiredValidation";
export default {
    name: "ValidatedRichSelect",
    components: {RequiredValidation},
    props: {
        label: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
        items: {
            type: Array,
            default: null,
        },
        valueAttribute: {
            type: String,
            default: null,
        },
        textAttribute: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideSearchBox: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        minimumInputLength: {
            type: Number,
            default: undefined,
        },
    },
    data() {
        return {
            filterDelay: 100, // to make the select load fast initially (on page load)
        }
    },
    methods: {
        fetchOptions (query) {
            this.filterDelay = 250;

            if (!query) {
                return {
                    results: this.items
                };
            }

            const searchString = query.toLowerCase();

            return {
                results: this.items.filter(
                    item => item[this.textAttribute].toLowerCase().indexOf(searchString) > -1
                )
            };
        }
    }
}
</script>
