function computeChangeSet(object, fieldsToCommit) {
    if (undefined === object.modifiedFields) {
        throw Error("Unexpected 'undefined' value for list of modified fields!");
    }

    determineNewlyModifiedFields(
        object,
        fieldsToCommit,
        object.modifiedFields,
    );
}

function determineNewlyModifiedFields(object, fieldsToCommit, modifiedFields) {
    for (const fieldKey in fieldsToCommit) {
        if (!Object.prototype.hasOwnProperty.call(object, fieldKey)) {
            modifiedFields[fieldKey] = true; // unknown field in the current object, which means it has just been added, so it has certainly been modified.
            continue;
        }

        if (object[fieldKey] instanceof Array) { // this field is an array containing other objects with each a number of fields
            /**
             * In this case, if any sub fields change, mark the whole array as modified
             * Everything should be sent to the server, otherwise elements may disappear
             * or items may get reordered if some item was added in between existing elements
             */

            for (const [arrayItemIndex, arrayItem] of fieldsToCommit[fieldKey].entries()) {
                let childModifiedFields = {}; // temporary object to check later for any modified child fields

                determineNewlyModifiedFields(
                    (arrayItemIndex in object[fieldKey]) ? object[fieldKey][arrayItemIndex] : {},
                    arrayItem,
                    childModifiedFields,
                );

                // if any child item was modified, mark the whole array as modified
                if (Object.keys(childModifiedFields).length > 0) {
                    modifiedFields[fieldKey] = true;
                    break;
                }
            }

            continue;
        }

        if (object[fieldKey] instanceof Object) { // this field is an object containing other fields
            if (!(fieldKey in modifiedFields)) {
                modifiedFields[fieldKey] = {};
            }

            determineNewlyModifiedFields(
                object[fieldKey],
                fieldsToCommit[fieldKey],
                modifiedFields[fieldKey],
            );

            continue;
        }

        if (fieldKey in modifiedFields) {
            continue; // this field was already changed before
        }

        if (object[fieldKey] === fieldsToCommit[fieldKey]) {
            continue; // this field did not change
        }

        modifiedFields[fieldKey] = true;
    }
}

// To only send the changed values to the cloud
function filterFieldsByChangeSet(fieldTransformations, modifiedFields, isNewItem) {
    let payload = {};

    for (const fieldKey in fieldTransformations) {
        if (!Object.prototype.hasOwnProperty.call(fieldTransformations, fieldKey)) {
            continue; // unknown field. This should not occur, this is to make PHPStorm linter happy
        }

        const transformation = fieldTransformations[fieldKey];

        if (null === transformation) {
            // If the transformation is null, that means the field should not be sent.
            continue;
        }

        const { outputKey, outputValue, options } = transformation;

        if (isNewItem === false && options && true === options.filterIfNotModified && !(fieldKey in modifiedFields)) {
            // if not a new item, and field is not modified, and this field can be filtered is set to ON => filter this field
            continue;
        }

        if (outputValue instanceof Array) {
            /**
             * In this case, if any sub fields change, mark the whole array as modified
             * Everything should be sent to the server, otherwise elements may disappear
             * or items may get reordered if some item was added in between existing elements
             */

            // if it's just an array of items with primitive values (no objects) like IRI's, just send that
            if (options && true === options.plainArray) {
                payload[outputKey] = outputValue;
                continue;
            }

            for (const [arrayItemIndex, arrayItem] of outputValue.entries()) {
                if (!(outputKey in payload)) {
                    payload[outputKey] = [];
                }

                payload[outputKey][arrayItemIndex] = this.filterFieldsByChangeSet(
                    arrayItem,
                    undefined, // no need to filter any fields, send them all
                    isNewItem
                );
            }

            continue;
        }

        if (outputValue instanceof Object) {
            // nested resource found

            if (isNewItem === false && Object.keys(modifiedFields[fieldKey]).length === 0) {
                continue;
            }

            payload[outputKey] = this.filterFieldsByChangeSet(
                outputValue,
                modifiedFields[fieldKey],
                isNewItem
            );

            continue;
        }

        payload[outputKey] = outputValue;
    }

    return payload;
}

export default {
    computeChangeSet,
    filterFieldsByChangeSet,
}
