import CountryAPI from "../../api/country";
import CountryOfflineRepository from "../persistence/offlineRepository/country";

const RENEW_CACHED_COUNTRIES = "RENEW_CACHED_COUNTRIES",
    RENEW_CACHED_COUNTRIES_SUCCESS = "RENEW_CACHED_COUNTRIES_SUCCESS",
    RENEW_CACHED_COUNTRIES_ERROR = "RENEW_CACHED_COUNTRIES_ERROR",

    FETCH_CACHED_COUNTRIES = "FETCH_CACHED_COUNTRIES",
    FETCH_CACHED_COUNTRIES_SUCCESS = "FETCH_CACHED_COUNTRIES_SUCCESS",
    FETCH_CACHED_COUNTRIES_ERROR = "FETCH_CACHED_COUNTRIES_ERROR";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        countries: [],
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        countries(state) {
            return Object.values(state.countries);
        },
        countryByIri: (state) => (iri) => {
            return state.countries[iri];
        },
    },
    mutations: {
        [RENEW_CACHED_COUNTRIES](state) {
            state.isLoading = true;
        },
        [RENEW_CACHED_COUNTRIES_SUCCESS](state, countries) {
            state.isLoading = false;
            state.countries = countries;
        },
        [RENEW_CACHED_COUNTRIES_ERROR](state) {
            state.isLoading = false;
        },

        [FETCH_CACHED_COUNTRIES](state) {
            state.isLoading = true;
        },
        [FETCH_CACHED_COUNTRIES_SUCCESS](state, countries) {
            state.isLoading = false;
            state.countries = countries;
        },
        [FETCH_CACHED_COUNTRIES_ERROR](state) {
            state.isLoading = false;
            state.countries = [];
        },
    },
    actions: {
        async renewAllCached({commit}) {
            commit(RENEW_CACHED_COUNTRIES);

            try {
                let countries = await CountryAPI.findAll();

                await CountryOfflineRepository.overwriteAll(countries);

                commit(RENEW_CACHED_COUNTRIES_SUCCESS, countries);
            }
            catch (exception) {
                commit(RENEW_CACHED_COUNTRIES_ERROR);
                throw exception;
            }
        },
        async fetchAllCached({commit}) {
            commit(FETCH_CACHED_COUNTRIES);

            try {
                let countries = await CountryOfflineRepository.findAll();

                commit(FETCH_CACHED_COUNTRIES_SUCCESS, countries);
            }
            catch (exception) {
                commit(FETCH_CACHED_COUNTRIES_ERROR);
                throw exception;
            }
        },
    }
};
