<template>
    <t-checkbox
        v-model="value"
        :disabled="true"
    />
</template>

<script>
export default {
    name: "DetailCheckbox",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
