var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row h-full flex-nowrap items-stretch"},[_c('sidebar',{on:{"toggled-expanded-state":_vm.toggledSidebarExpandedState}}),_c('main',{staticClass:"flex-1 flex flex-col overflow-x-auto"},[_c('header',{staticClass:"flex-shrink-0 flex flex-row flex-no-wrap h-12 md:h-16 bg-liq-a items-center justify-between text-white"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"h-5 sm:h-7 md:h-9 inline-block ml-4 md:ml-8 mr-4 md:mr-8",attrs:{"src":require("./../assets/img/logo-liq-white.svg"),"alt":"LiQ"}})]),_c('span',{staticClass:"text-sm md:text-base"},[_c('span',{staticClass:"hidden",class:{
                        'sm:block': true === _vm.expandedSidebar,
                        'xs:block': false === _vm.expandedSidebar,
                    }},[_vm._v(" Field Sales "),_c('span',{staticClass:"hidden sm:inline-block"},[_vm._v("Portal")])]),(_vm.appEnvironment !== 'production')?_c('span',{staticClass:"text-xs uppercase hidden sm:block"},[_vm._v(" ("+_vm._s(_vm.appEnvironment)+") "),(_vm.appUser)?_c('span',[_vm._v("- "+_vm._s(_vm.appUser.roles.join(',')))]):_vm._e()]):_vm._e()]),_c('sync-popover',{staticClass:"ml-auto"}),(_vm.isAuthenticated)?_c('t-dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                    var mousedownHandler = ref.mousedownHandler;
                    var focusHandler = ref.focusHandler;
                    var blurHandler = ref.blurHandler;
                    var keydownHandler = ref.keydownHandler;
                    var isShown = ref.isShown;
return _c('div',{staticClass:"h-full"},[_c('button',{staticClass:"h-full border-solid border-l border-edge-liq-a focus:outline-none hover:bg-liq-a-alt",class:{ 'bg-liq-a-alt': isShown },attrs:{"aria-label":"User menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('span',{staticClass:"flex flex-row items-center mx-2 md:mx-4 sm:bg-liq-b rounded-full"},[_c('span',{staticClass:"relative block w-6 md:w-8 h-6 md:h-8"},[_c('base-icon',{staticClass:"rounded-full text-liq-c",attrs:{"name":"user"}}),_c('svg',{staticClass:"w-4 h-4 absolute -right-0.5 -bottom-0.5 border-2 border-liq-a rounded-full",class:{'text-status-online': _vm.isOnline, 'text-status-offline': !_vm.isOnline},attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('circle',{attrs:{"cx":"10","cy":"10","r":"10","fill":"currentColor"}})])],1),(_vm.appUser)?_c('span',{staticClass:"ml-1 md:ml-2 mr-2 md:mr-3 text-liq-c whitespace-nowrap text-sm md:text-base hidden sm:block"},[_vm._v(" Hi, "),_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.appUser.username)+"!")])]):_vm._e()])])])}},{key:"default",fn:function(ref){
                    var blurHandler = ref.blurHandler;
return _c('div',{staticClass:"py-2 rounded-md shadow-xs"},[_c('router-link',{staticClass:"block w-full text-left px-4 py-2 text-sm leading-5 text-dropdown-text hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out",attrs:{"to":{
                            name: 'user_edit',
                            params: {
                                userId: _vm.appUserId
                            }
                        }}},[_vm._v(" User settings ")]),_c('button',{staticClass:"block w-full text-left px-4 py-2 text-sm leading-5 text-dropdown-text hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out",attrs:{"role":"menuitem"},on:{"click":_vm.logout,"blur":blurHandler}},[_vm._v(" Sign out ")])],1)}}],null,false,1511811185)}):_c('span',{staticClass:"border-l border-edge-liq-a h-full flex flex-row items-center px-2 whitespace-nowrap"},[_c('span',[_vm._v(" Not logged in ")])])],1),_c('div',{staticClass:"flex-auto p-4 md:p-8 overflow-y-auto"},[_c('router-view')],1)]),_c('notification-container'),_c('v-offline',{on:{"detected-condition":_vm.updateOnlineStatus}}),(false === _vm.isAuthenticated)?_c('authentication-preloader',{attrs:{"auth-error":_vm.authError}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }