<template>
    <div>
        <breadcrumbs />

        <h1>Field visits</h1>

        <field-visit-table :items="fieldVisits" :display-loader="isLoading" />
    </div>
</template>

<script>

import FieldVisitTable from "./FieldVisitTable";
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    name: "FieldVisitOverview",
    components: {
        Breadcrumbs,
        FieldVisitTable,
    },
    computed: {
        isLoading() {
            return this.$store.getters["fieldVisit/isLoading"];
        },
        fieldVisits() {
            return this.$store.getters["fieldVisit/fieldVisits"];
        },
    },
};
</script>