<template>
    <tr class="VueTables__no-results">
        <td
            class="text-center px-3 py-4 focus:outline-none"
            :tabindex="props.tabIndex"
            :colspan="props.colspan"
        >
            {{ props.display(props.message) }}
        </td>
    </tr>
</template>

<script>
export default {
    name: "MyNoResultsRow",
    props: {
        props: {
            type: Object,
            default: null,
        },
    },
}
</script>
