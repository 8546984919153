var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-client-table',{attrs:{"columns":[
            'syncStatus',
            'retailChainIri',
            'name',
            'address',
            'actions' ],"options":{
            sortable: [
                'retailChainIri',
                'name' ],
            columnsDisplay: {
                'retailChainIri': 'min_tablet',
                'address': 'min_tabletL',
            },
            filterAlgorithm: {
                retailChainIri: function retailChainIri(row, query) {
                    var retailChain = row.getRetailChain();

                    if (!retailChain) {
                        return false;
                    }

                    return retailChain.toString().toLowerCase().includes(query.toLowerCase());
                },
                address: function address(row, query) {
                    return row.address.toString().toLowerCase().includes(query.toLowerCase());
                },
            },
            columnsClasses: {
                'actions': 'whitespace-nowrap',
            },
            resizableColumns: false,
        }},on:{"row-click":_vm.rowClicked},scopedSlots:_vm._u([{key:"afterFilter",fn:function(){return [(_vm.canCreateStore)?_c('router-link',{staticClass:"ml-2 inline-flex flex-row items-center btn-liq-default",attrs:{"to":null !== _vm.forRetailChainId ? { name: 'store_create', params: { presetRetailChainId: _vm.forRetailChainId } } : { name: 'store_create' }}},[_c('base-icon',{staticClass:"w-4 h-4 mr-1 -ml-1",attrs:{"name":"plus"}}),_vm._v(" Create ")],1):_vm._e()]},proxy:true},{key:"syncStatus",fn:function(ref){
        var row = ref.row;
return [_c('entity-sync-status',{attrs:{"sync-status":row.syncStatus}})]}},{key:"retailChainIri",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.getRetailChain() ? row.getRetailChain().toString() : '')+" ")]}},{key:"address",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.address.toString())+" ")]}},{key:"actions",fn:function(ref){
        var row = ref.row;
return [(_vm.canUpdateStore)?_c('router-link',{staticClass:"inline-block rounded-full transition-colors duration-200 ease-out hover:bg-liq-b hover:text-liq-c",attrs:{"to":{ name: 'store_edit', params: { storeId: row.id }}}},[_c('base-icon',{staticClass:"w-4 h-4 m-2",attrs:{"name":"pencil"}})],1):_vm._e(),(_vm.canDeleteStore)?_c('button',{staticClass:"inline-block rounded-full transition-colors duration-200 ease-out hover:bg-liq-b hover:text-liq-c",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteItem(row)}}},[_c('base-icon',{staticClass:"w-4 h-4 m-2",attrs:{"name":"trash"}})],1):_vm._e()]}},{key:"h__syncStatus",fn:function(){return [_vm._v(" ​ ")]},proxy:true},{key:"h__retailChainIri",fn:function(){return [_vm._v(" Retail chain ")]},proxy:true},{key:"afterTable",fn:function(){return [_c('table-loader',{attrs:{"display-loader":_vm.displayLoader}})]},proxy:true}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}}),_c('data-export-button',{attrs:{"table-export-mapping":_vm.excelExportMapping,"items-to-export":_vm.items,"data-subject":"Store"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }