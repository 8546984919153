import Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(relativeTime);
dayjs.extend(customParseFormat); // required to parse ISO 8601 date formats correctly for the ValidatedDatePicker (otherwise the timezone isn't parsed correctly)

Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs;
        }
    }
});
