import Storage from "../persistentStorage";

const KEY_ACCESS_TOKEN = "liq_access_token";
const KEY_REFRESH_TOKEN = "liq_refresh_token";
const KEY_APP_USER_ID = "liq_app_user_id";
const KEY_APP_USER_ROLES = "liq_app_user_roles";

export default {
    async findToken() {
        return {
            accessToken: await Storage.getItem(KEY_ACCESS_TOKEN),
            refreshToken: await Storage.getItem(KEY_REFRESH_TOKEN),
        }
    },
    async saveToken(accessToken, refreshToken) {
        await Storage.setItem(KEY_ACCESS_TOKEN, accessToken);
        await Storage.setItem(KEY_REFRESH_TOKEN, refreshToken);
    },

    async findAppUser() {
        return {
            id: await Storage.getItem(KEY_APP_USER_ID),
            roles: await Storage.getItem(KEY_APP_USER_ROLES),
        }
    },
    async saveAppUser(appUserId, appUserRoles) {
        await Storage.setItem(KEY_APP_USER_ID, appUserId);
        await Storage.setItem(KEY_APP_USER_ROLES, appUserRoles);
    },

    async eraseCredentials() {
        await Storage.removeItem(KEY_ACCESS_TOKEN);
        await Storage.removeItem(KEY_REFRESH_TOKEN);
        await Storage.removeItem(KEY_APP_USER_ID);
        await Storage.removeItem(KEY_APP_USER_ROLES);
    },
};
