import baseAxios from "./base/baseAxios";

export default {
    async create(mediaUpload) {
        const blob = await fetch(mediaUpload.fileBase64).then(res => res.blob());

        const file = new File([blob], mediaUpload.fileName);

        const formData = new FormData();
        formData.append('file', file);

        return await baseAxios.post(
            "/media_uploads",
            formData,
            {
                addAuthorizationHeader: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
    },
};
