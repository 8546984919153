import CountryModel from "../model/country";
import baseApi from "./base/baseApi";

export default {
    async findAll() {
        return await baseApi.fetchCollectionPage(
            "/countries",
            1,
            null,
            (itemData) => {
                return CountryModel.transformFromAPI(itemData)
            },
        );
    },
};
