export default class Language {
    constructor(
        iri,
        id,
        name,
    ) {
        this.iri = iri;
        this.id = id;
        this.name = name;
    }

    static transformFromAPI(languageData) {
        return new this(
            languageData['@id'],
            languageData.id,
            languageData.name,
        );
    }

    static transformFromLocalCache(languageData) {
        return new this(
            languageData.iri,
            languageData.id,
            languageData.name,
        );
    }

    toString() {
        return this.name;
    }
}
