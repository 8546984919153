const ROLE_ADMINISTRATOR = {
        'key': 'ROLE_ADMINISTRATOR',
        'label': 'Administrator',
    },
    ROLE_ACCOUNT_MANAGER = {
        'key': 'ROLE_ACCOUNT_MANAGER',
        'label': 'Account Manager',
    },
    ROLE_SALES_MANAGER = {
        'key': 'ROLE_SALES_MANAGER',
        'label': 'Sales Manager',
    },
    ROLE_BRAND_MANAGER = {
        'key': 'ROLE_BRAND_MANAGER',
        'label': 'Brand Manager',
    };

function all() {
    return [
        ROLE_ADMINISTRATOR,
        ROLE_ACCOUNT_MANAGER,
        ROLE_SALES_MANAGER,
        ROLE_BRAND_MANAGER,
    ];
}

function defaultRole() {
    return ROLE_ADMINISTRATOR;
}

function getLabelForKey(roleKey) {
    const role = this.all().find(role => roleKey === role.key);

    return role.label;
}

export default {
    ROLE_ADMINISTRATOR,
    ROLE_ACCOUNT_MANAGER,
    ROLE_SALES_MANAGER,
    ROLE_BRAND_MANAGER,
    all,
    defaultRole,
    getLabelForKey,
};
