import store from "../store/index";
import Authenticator from "../security/authenticator";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import ApiBadRequestException from "../exception/apiBadRequestException";
import baseAxios from "./../api/base/baseAxios";
import ApiIdAlreadyInsertedException from "../exception/apiIdAlreadyInsertedException";

function addInterceptors() {
    addRequestInterceptors();
    addResponseInterceptors();
}

function addRequestInterceptors() {
    // Add the Accept or Content-Type header to every axios request to ask the right response format to the API
    baseAxios.interceptors.request.use(function (config) {
        if (true === config.addAuthorizationHeader) {
            addAuthorization(config);
        }

        if ("get" === config.method) {
            addHeaderIfNotPresent(config, "Accept", "application/ld+json");

            return config;
        }

        if ("put" === config.method) {
            addHeaderIfNotPresent(config, "Content-Type", "application/ld+json");

            return config;
        }

        /*if ("patch" === config.method) {
            addHeaderIfNotPresent(config, "Content-Type", "application/merge-patch+json");

            return config;
        }*/

        addHeaderIfNotPresent(config, "Content-Type", "application/ld+json");

        return config;
    });
}

function addResponseInterceptors() {
    /*
     * To handle unauthorized requests (expired access tokens) we make use of a library that
     * helps you implement automatic refresh of authorization via axios interceptors.
     * You can easily intercept the original request when it fails, refresh the authorization
     * and continue with the original request, without any user interaction.
     * https://github.com/Flyrell/axios-auth-refresh
     * What happens when the request fails due to authorization is all up to you.
     * You can either run a refresh call for a new authorization token or run a custom logic.
     *
     * The plugin stalls additional requests that have come in while waiting for a new
     * authorization token and resolves them when a new token is available.
     */
    const refreshAuthLogic = failedRequest => Authenticator.refreshToken()
        .then((newAccessToken) => {
            addUpdatedAuthorization(failedRequest.response.config, newAccessToken);

            return Promise.resolve();
        })
        .catch((exception) => {
            // At this point, a token refresh was already attempted but failed. The only option left is to return to make the user re-authenticate.
            console.warn("An error occurred while refreshing access token, redirecting to login. Reason:", exception.message);

            Authenticator.revokeCredentials();

            return Promise.reject(exception); // This makes sure the stalled failed regular API request doesn't get executed anymore.
        })
    ;

    /*
     * When making a call, if it returns a 401 error, the refreshAuthLogic will be run,
     * and the request retried with the new token
     */
    createAuthRefreshInterceptor(
        baseAxios,
        refreshAuthLogic
    );

    /*
     * This interceptor catches HTTP 400 Bad Request errors, which get thrown in cases like:
     * - When the server validation is not satisfied
     * - ... maybe there are other cases? Try to handle these as well.
     */
    baseAxios.interceptors.response.use(undefined, (error) => {
        return new Promise(() => {
            if (
                undefined !== error
                && Object.prototype.hasOwnProperty.call(error, "response")
                && undefined !== error.response
                && Object.prototype.hasOwnProperty.call(error.response, "status")
                && error.response.status === 400
            ) {
                if (
                    Object.prototype.hasOwnProperty.call(error.response, 'data')
                    && undefined !== error.response.data
                    && Object.prototype.hasOwnProperty.call(error.response.data, 'hydra:description')
                    && undefined !== error.response.data['hydra:description']
                    && error.response.data['hydra:description'].includes('id: This value is already used.')
                ) {
                    throw new ApiIdAlreadyInsertedException(error.response);
                }
                else {
                    throw new ApiBadRequestException(error.message, error.response);
                }
            }

            throw error;
        });
    });
}

function addHeaderIfNotPresent(config, header, value) {
    if (undefined === config.headers[header]) {
        config.headers[header] = value;
    }
}

function addAuthorization(config) {
    let accessToken = store.getters['security/accessToken'];

    addHeaderIfNotPresent(config, "Authorization", "Bearer " + accessToken);
}

function addUpdatedAuthorization(config, accessToken) {
    config.headers["Authorization"] = "Bearer " + accessToken;
}

export default {
    addInterceptors: addInterceptors,
}
