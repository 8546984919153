var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"whitespace-nowrap"},[_c('base-icon',{staticClass:"w-4 md:w-6 h-4 md:h-6",attrs:{"name":"cloud"}}),_c('base-icon',{staticClass:"w-4 h-4",class:'created_locally' === _vm.syncStatus
                ? 'text-liq-success'
                : (
                    'updated_locally' === _vm.syncStatus
                        ? 'text-liq-d'
                        : (
                            'deleted_locally' === _vm.syncStatus
                                ? 'text-liq-error'
                                : 'text-liq-b'
                        )
                ),attrs:{"name":'created_locally' === _vm.syncStatus
                ? 'plusCircle'
                : (
                    'updated_locally' === _vm.syncStatus
                        ? 'pencilCircle'
                        : (
                            'deleted_locally' === _vm.syncStatus
                                ? 'minusCircle'
                                : 'check'
                        )
                )}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }