<template>
    <t-input-group
        :label="label ? (label + (field.$params.required ? ' *' : '')) : null"
        :variant="{
            error: field.$dirty && field.$error,
            success: field.$dirty && !field.$error
        }"
    >
        <t-textarea
            v-model.trim.lazy="field.$model"
            :variant="{
                error: field.$dirty && field.$error,
                success: field.$dirty && !field.$error,
            }"
            :disabled="disabled"
            rows="5"
        />
        <div v-if="field.$params.maxLength" class="text-sm mt-1">
            {{ field.$model.length }}/{{ field.$params.maxLength.max }} characters
        </div>
        <template v-slot:feedback>
            <textual-validations
                :field="field"
                :label="label"
            />
        </template>
    </t-input-group>
</template>

<script>
import TextualValidations from "./validation/TextualValidations";
export default {
    name: "ValidatedTextarea",
    components: {TextualValidations},
    props: {
        label: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
