const CHECK_PLANOGRAM = {
        'key': 'planogram',
        'label': 'Planogram',
        'help_text': 'Are the products visible on the shelf according to the predetermined plan?',
    },
    CHECK_TRAYS = {
        'key': 'trays',
        'label': 'Trays placed',
        'help_text': 'Are the products presented in the correct LiQ-branded trays?',
    },
    CHECK_WOBBLER = {
        'key': 'wobbler',
        'label': 'Wobbler',
        'help_text': 'Are the correct promotional materials (wobbler) hanging on the shelves?',
    };

function all() {
    return [
        CHECK_PLANOGRAM,
        CHECK_TRAYS,
        CHECK_WOBBLER,
    ];
}

function getByKey(brandValueCheckTypeKey) {
    return this.all().find(brandValueCheckType => brandValueCheckTypeKey === brandValueCheckType.key);
}

export default { all, getByKey, CHECK_WOBBLER };
