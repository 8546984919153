import ApiException from "./apiException";

export default class AuthApiException extends ApiException {
    constructor(message, apiResponse) {
        let error = "Unknown error";
        let errorDescription = "Unknown description";

        if (null !== apiResponse && Object.prototype.hasOwnProperty.call(apiResponse, "data")) {
            if (Object.prototype.hasOwnProperty.call(apiResponse.data, "error")) {
                error = apiResponse.data.error;
            }

            if (Object.prototype.hasOwnProperty.call(apiResponse.data, "error_description")) {
                errorDescription = apiResponse.data.error_description;
            }
        }

        super(`${message} (Error: ${error} - Description: ${errorDescription})`, apiResponse);
    }
}
