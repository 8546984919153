import Roles from "../../util/roles";
import store from "../../store";

export default class FieldVisitVoter {
    static canListFieldVisits() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canViewFieldVisit() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canCreateFieldVisit() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canUpdateFieldVisit() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_SALES_MANAGER,
        ]);
    }
}
