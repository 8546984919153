<template>
    <div>
        <v-client-table
            v-model="items"
            :columns="[
                'syncStatus',
                'storeIri',
                'visitedDate',
                'salesRep',
                'storeAddress',
                'actions',
            ]"
            :options="{
                sortable: [
                    'id',
                    'storeIri',
                    'visitedDate',
                    'salesRep',
                    'storeAddress',
                ],
                columnsDisplay: {
                    'salesRep': 'min_tablet',
                    'storeAddress': 'min_tabletL',
                },
                orderBy: {
                    column: 'visitedDate',
                    ascending: false
                },
                filterAlgorithm: {
                    storeIri(row, query) {
                        if (undefined === row.getVisitingStore()) {
                            return '';
                        }

                        return row.getVisitingStore().toString().toLowerCase().includes(query.toLowerCase());
                    },
                    visitedDate(row, query) {
                        return $date(row.visitedDate).format($getConstant('DATETIME_FORMAT')).toString().toLowerCase().includes(query.toLowerCase());
                    },
                    salesRep(row, query) {
                        return row.getSalesRep().toString().toLowerCase().includes(query.toLowerCase());
                    },
                    storeAddress(row, query) {
                        if (undefined === row.getVisitingStore()) {
                            return '';
                        }

                        return row.getVisitingStore().address.toString().toLowerCase().includes(query.toLowerCase());
                    },
                },
                columnsClasses: {
                    'salesRep': 'capitalize',
                    'actions': 'whitespace-nowrap',
                },
                resizableColumns: false,
            }"
            @row-click="rowClicked"
        >
            <template v-slot:afterFilter>
                <router-link
                    v-if="canCreateFieldVisit"
                    :to="null !== forStoreId ? { name: 'field_visit_create', params: { presetStoreId: forStoreId } } : { name: 'field_visit_create' }"
                    class="ml-2 inline-flex flex-row items-center btn-liq-default"
                >
                    <base-icon name="plus" class="w-4 h-4 mr-1 -ml-1" />
                    Create
                </router-link>
            </template>
            <template v-slot:syncStatus="{row}">
                <entity-sync-status :sync-status="row.syncStatus" />
            </template>
            <template v-slot:storeIri="{row}">
                {{ undefined === row.getVisitingStore() ? '' : row.getVisitingStore().toString() }}
            </template>
            <template v-slot:visitedDate="{row}">
                {{ $date(row.visitedDate).format($getConstant('DATETIME_FORMAT')) }}
            </template>
            <template v-slot:salesRep="{row}">
                {{ row.getSalesRep() }}
            </template>
            <template v-slot:storeAddress="{row}">
                {{ undefined === row.getVisitingStore() ? '' : row.getVisitingStore().address.toString() }}
            </template>
            <template v-slot:h__syncStatus>
                &#8203;
            </template>
            <template v-slot:h__storeIri>
                Store
            </template>
            <template v-slot:h__visitedDate>
                Visit date
            </template>
            <template v-slot:h__salesRep>
                Sales representative
            </template>
            <template v-slot:h__storeAddress>
                Address
            </template>
            <template v-slot:actions="{row}">
                <router-link
                    v-if="canUpdateFieldVisit"
                    :to="{ name: 'field_visit_edit', params: { fieldVisitId: row.id }}"
                    class="inline-block rounded-full transition-colors duration-200 ease-out hover:bg-liq-b hover:text-liq-c"
                >
                    <base-icon name="pencil" class="w-4 h-4 m-2" />
                </router-link>
            </template>
            <template v-slot:afterTable>
                <table-loader :display-loader="displayLoader" />
            </template>
        </v-client-table>

        <data-export-button :table-export-mapping="excelExportMapping" :items-to-export="items" data-subject="FieldVisit" />
    </div>
</template>

<script>
import BaseIcon from "../../components/BaseIcon";
import EntitySyncStatus from "../../components/EntitySyncStatus";
import FieldVisitVoter from "../../security/voter/fieldVisitVoter";
import DataExportButton from "../../components/DataExportButton";
import FieldVisit from "../../model/fieldVisit";
import TableLoader from "../../components/TableLoader";

export default {
    name: "FieldVisitTable",
    components: {
        TableLoader,
        DataExportButton,
        EntitySyncStatus,
        BaseIcon
    },
    props: {
        items: {
            type: Array,
            default: null,
        },
        forStoreId: {
            type: String,
            default: null,
        },
        displayLoader: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        canCreateFieldVisit() {
            return FieldVisitVoter.canCreateFieldVisit();
        },
        canUpdateFieldVisit() {
            return FieldVisitVoter.canUpdateFieldVisit();
        },
    },
    methods: {
        rowClicked(row) {
            this.$router.push({name: 'field_visit_detail', params: { fieldVisitId: row.row.id }});
        },
        excelExportMapping: FieldVisit.excelExportMapping,
    }
}
</script>
