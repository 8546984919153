<template>
    <span class="whitespace-nowrap">
        <base-icon
            name="cloud"
            class="w-4 md:w-6 h-4 md:h-6"
        />
        <base-icon
            :name="
                'created_locally' === syncStatus
                    ? 'plusCircle'
                    : (
                        'updated_locally' === syncStatus
                            ? 'pencilCircle'
                            : (
                                'deleted_locally' === syncStatus
                                    ? 'minusCircle'
                                    : 'check'
                            )
                    )
            "
            class="w-4 h-4"
            :class="
                'created_locally' === syncStatus
                    ? 'text-liq-success'
                    : (
                        'updated_locally' === syncStatus
                            ? 'text-liq-d'
                            : (
                                'deleted_locally' === syncStatus
                                    ? 'text-liq-error'
                                    : 'text-liq-b'
                            )
                    )
            "
        />
    </span>
</template>

<script>
import BaseIcon from "./BaseIcon";
export default {
    name: "EntitySyncStatus",
    components: {BaseIcon},
    props: {
        syncStatus: {
            type: String,
            required: true,
        }
    },
};
</script>
