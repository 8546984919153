import Storage from "../persistentStorage";
import LanguageModel from "../../../model/language";

const KEY_LANGUAGES = "liq_languages";

export default {
    async findAll() {
        let languages = await Storage.getItem(KEY_LANGUAGES);

        if (null === languages) {
            return [];
        }

        let languageObjects = [];
        const languageEntries = Object.entries(languages);
        for (const [languageIri, languageData] of languageEntries) {
            languageObjects[languageIri] = LanguageModel.transformFromLocalCache(languageData);
        }

        return languageObjects;
    },
    async overwriteAll(languages) {
        await Storage.setItem(KEY_LANGUAGES, languages);
    }
};
