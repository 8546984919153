import Roles from "../../util/roles";
import store from "../../store";

export default class RetailChainVoter {
    static canListRetailChains() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canViewRetailChain() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canViewSensitiveRetailChainFields() {
        return store.getters["security/hasRole"](Roles.ROLE_ADMINISTRATOR);
    }

    static canCreateRetailChain() {
        return store.getters["security/hasRole"](Roles.ROLE_ADMINISTRATOR);
    }

    static canUpdateRetailChain() {
        return store.getters["security/hasRole"](Roles.ROLE_ADMINISTRATOR);
    }
}
