import qs from "querystring";
import AuthApiException from "../exception/authApiException";
import baseAxios from "./base/baseAxios";

export default {
    getUserInfo(userInfoUrl) {
        return baseAxios
            .get(
                userInfoUrl,
                {
                    addAuthorizationHeader: true,
                }
            )
            .catch((exception) => {
                return Promise.reject(
                    new AuthApiException(exception.message, exception.response)
                );
            })
            .then((userInfoResponse) => {
                if (!Object.prototype.hasOwnProperty.call(userInfoResponse, "data") || !userInfoResponse.data)
                {
                    return Promise.reject(
                        new AuthApiException("No data found in user info response", userInfoResponse)
                    );
                }

                return userInfoResponse.data;
            })
        ;
    },
    performTokenRequest(tokenUrl, clientId, redirectUri, authorizationCode, codeVerifier) {
        return baseAxios
            .post(
                tokenUrl,
                qs.stringify({
                    grant_type: 'authorization_code',
                    // scope: '' // not necessary. Scopes are per-client, not per-user, so it's not suitable for user roles
                    client_id: clientId,
                    code: authorizationCode,
                    redirect_uri: redirectUri,
                    code_verifier: codeVerifier,
                }),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    /* While your refresh logic is running, the interceptor will be triggered for every request which
                     * returns one of the options.statusCodes specified (HTTP 401 by default).
                     *
                     * In order to prevent the interceptors loop (when your refresh logic fails with any of the status
                     * codes specified in options.statusCodes) you need to use a skipAuthRefresh flag on your refreshing
                     * call inside the refreshAuthLogic function.
                     */
                    skipAuthRefresh: true,
                },
            )
            .catch((exception) => {
                return Promise.reject(
                    new AuthApiException(exception.message, exception.response)
                );
            })
            .then((tokenResponse) => {
                if (!Object.prototype.hasOwnProperty.call(tokenResponse, "data") || !tokenResponse.data)
                {
                    return Promise.reject(
                        new AuthApiException("No data found in token request response", tokenResponse)
                    );
                }

                return tokenResponse.data;
            })
        ;
    },
    performTokenRefresh(tokenUrl, clientId, refreshToken) {
        return baseAxios
            .post(
                tokenUrl,
                qs.stringify({
                    grant_type: 'refresh_token',
                    // scope: '' // not necessary. Scopes are per-client, not per-user, so it's not suitable for user roles
                    client_id: clientId,
                    refresh_token: refreshToken,
                }),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    /* While your refresh logic is running, the interceptor will be triggered for every request which
                     * returns one of the options.statusCodes specified (HTTP 401 by default).
                     *
                     * In order to prevent the interceptors loop (when your refresh logic fails with any of the status
                     * codes specified in options.statusCodes) you need to use a skipAuthRefresh flag on your refreshing
                     * call inside the refreshAuthLogic function.
                     */
                    skipAuthRefresh: true,
                },
            )
            .catch((exception) => {
                return Promise.reject(
                    new AuthApiException(exception.message, exception.response)
                );
            })
            .then((tokenResponse) => {
                if (!Object.prototype.hasOwnProperty.call(tokenResponse, "data") || !tokenResponse.data)
                {
                    return Promise.reject(
                        new AuthApiException("No data found in token refresh response", tokenResponse)
                    );
                }

                return tokenResponse.data;
            })
        ;
    }
}
