import Roles from "../../util/roles";
import store from "../../store";

export default class UserVoter {
    static canListUsers() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canViewUser() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canCreateUser() {
        return store.getters["security/hasRole"](Roles.ROLE_ADMINISTRATOR);
    }

    static canUpdateUser(userId) {
        if (store.getters["security/hasRole"](Roles.ROLE_ADMINISTRATOR)) {
            return true;
        }

        const appUserId = store.getters['security/appUserId'];

        return appUserId === userId;
    }
}
