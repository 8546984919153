import {uuid} from "vue-uuid";
import SyncStatusEnum from "../store/sync/syncStatusEnum";
import dayjs from "dayjs";
import ChangeSetComputer from "../util/changeSetComputer";
import AddressModel from "./address";

export default class RetailChain {
    constructor(
        iri,
        id,
        name,
        address,
        vatNumber,
        buyerName,
        buyerEmail,
        createdAt,
        updatedAt,
        syncedAt,
        syncStatus,
        modifiedFields
    ) {
        this.iri = iri;
        this.id = id;
        this.name = name;
        this.address = address;
        this.vatNumber = vatNumber;
        this.buyerName = buyerName;
        this.buyerEmail = buyerEmail;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.syncedAt = syncedAt;

        this.syncStatus = syncStatus;
        this.modifiedFields = modifiedFields;
    }

    static transformFromAPI(retailChainData) {
        return new this(
            retailChainData['@id'],
            retailChainData.id,
            retailChainData.name,
            AddressModel.transformFromAPINested(retailChainData.address),
            retailChainData.vatNumber,
            retailChainData.buyerName,
            retailChainData.buyerEmail,
            retailChainData.createdAt,
            retailChainData.updatedAt,
            retailChainData.syncedAt,
            SyncStatusEnum.IN_SYNC,
            [],
        );
    }

    transformToApi(isNewItem) {
        const fieldTransformations = {
            id: isNewItem ? {
                outputKey: 'id',
                outputValue: this.id
            } : null,
            name: {
                outputKey: 'name',
                outputValue: this.name,
                options: {
                    filterIfNotModified: true,
                },
            },
            address: {
                outputKey: 'address',
                outputValue: {
                    iri: isNewItem ? null : {
                        outputKey: '@id', // adding @id makes API platform edit the original record instead of creating a new one
                        outputValue: this.address.iri,
                    },
                    city: {
                        outputKey: 'city',
                        outputValue: this.address.city,
                        options: {
                            filterIfNotModified: true,
                        },
                    },
                    number: {
                        outputKey: 'number',
                        outputValue: this.address.number,
                        options: {
                            filterIfNotModified: true,
                        },
                    },
                    postalCode: {
                        outputKey: 'postalCode',
                        outputValue: this.address.postalCode,
                        options: {
                            filterIfNotModified: true,
                        },
                    },
                    street: {
                        outputKey: 'street',
                        outputValue: this.address.street,
                        options: {
                            filterIfNotModified: true,
                        },
                    },
                    countryIri: {
                        outputKey: 'country',
                        outputValue: this.address.countryIri,
                        options: {
                            filterIfNotModified: true,
                        },
                    },
                },
            },
            vatNumber: {
                outputKey: 'vatNumber',
                outputValue: this.vatNumber,
                options: {
                    filterIfNotModified: true
                },
            },
            buyerName: {
                outputKey: 'buyerName',
                outputValue: this.buyerName,
                options: {
                    filterIfNotModified: true
                },
            },
            buyerEmail: {
                outputKey: 'buyerEmail',
                outputValue: this.buyerEmail,
                options: {
                    filterIfNotModified: true
                },
            },

            createdAt: isNewItem ? {
                outputKey: 'createdAt',
                outputValue: dayjs(this.createdAt).toISOString()
            } : null,
            updatedAt: {
                outputKey: 'updatedAt',
                outputValue: dayjs(this.updatedAt).toISOString()
            },
        };

        return ChangeSetComputer.filterFieldsByChangeSet(
            fieldTransformations,
            this.modifiedFields,
            isNewItem
        );
    }

    static transformFromLocalCache(retailChainData) {
        return new this(
            retailChainData.iri,
            retailChainData.id,
            retailChainData.name,
            AddressModel.transformFromLocalCacheNested(retailChainData.address),
            retailChainData.vatNumber,
            retailChainData.buyerName,
            retailChainData.buyerEmail,
            retailChainData.createdAt,
            retailChainData.updatedAt,
            retailChainData.syncedAt,
            retailChainData.syncStatus,
            retailChainData.modifiedFields,
        );
    }

    static createNew(formData) {
        const id = uuid.v4();
        var now = dayjs();

        return new this(
            this.buildIriFromId(id),
            id,
            formData.name,
            AddressModel.createNewNested(formData.address),
            formData.vatNumber,
            formData.buyerName,
            formData.buyerEmail,
            now.format(),
            now.format(),
            null,
            SyncStatusEnum.CREATED_LOCALLY,
            [],
        );
    }

    commitUpdate(formData) {
        ChangeSetComputer.computeChangeSet(this, formData);

        this.name = formData.name;
        this.address.commitUpdateNested(formData.address);
        this.vatNumber = formData.vatNumber;
        this.buyerName = formData.buyerName;
        this.buyerEmail = formData.buyerEmail;

        var now = dayjs();

        if (null === this.createdAt) {
            this.createdAt = now.format();
        }
        this.updatedAt = now.format();
        if (SyncStatusEnum.CREATED_LOCALLY !== this.syncStatus) {
            this.syncStatus = SyncStatusEnum.UPDATED_LOCALLY;
        }
    }

    toString() {
        return `${this.name}`;
    }

    static buildIriFromId(id) {
        return `/retail_chains/${id}`
    }

    static excelExportMapping(record) {
        return {
            'name': record.name,
            'city': record.address.city,
            'number': record.address.number,
            'postalCode': record.address.postalCode,
            'street': record.address.street,
            'country': record.address.getCountry() ? record.address.getCountry().name : '',
            'vatNumber': record.vatNumber,
            'buyerName': record.buyerName,
            'buyerEmail': record.buyerEmail,
        };
    }
}
