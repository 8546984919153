const FUNCTION_FOUNDER = {
        'key': 'founder',
        'label': 'Founder',
    },
    FUNCTION_JUNIOR_ACCOUNT_MANAGER = {
        'key': 'junior_account_manager',
        'label': 'Junior Account Manager',
    },
    FUNCTION_ACCOUNT_MANAGER = {
        'key': 'account_manager',
        'label': 'Account Manager',
    },
    FUNCTION_SENIOR_ACCOUNT_MANAGER = {
        'key': 'senior_account_manager',
        'label': 'Senior Account Manager',
    },
    FUNCTION_KEY_ACCOUNT_MANAGER_COUNTRY = {
        'key': 'key_account_manager_country',
        'label': 'Key Account Manager Country',
    },
    FUNCTION_SALES_MANAGER = {
        'key': 'sales_manager',
        'label': 'Sales Manager',
    };

function all() {
    return [
        FUNCTION_FOUNDER,
        FUNCTION_JUNIOR_ACCOUNT_MANAGER,
        FUNCTION_ACCOUNT_MANAGER,
        FUNCTION_SENIOR_ACCOUNT_MANAGER,
        FUNCTION_KEY_ACCOUNT_MANAGER_COUNTRY,
        FUNCTION_SALES_MANAGER,
    ];
}

function defaultFunction() {
    return FUNCTION_JUNIOR_ACCOUNT_MANAGER;
}

function getLabelForKey(userFunctionKey) {
    const userFunction = this.all().find(userFunction => userFunctionKey === userFunction.key);

    return userFunction.label;
}

export default { all, defaultFunction, getLabelForKey };
