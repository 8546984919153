<template>
    <div>
        <breadcrumbs />

        <h1>Retail chains</h1>

        <retail-chain-table :items="retailChains" :display-loader="isLoading" />
    </div>
</template>

<script>

import RetailChainTable from "./RetailChainTable";
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    name: "RetailChainOverview",
    components: {
        Breadcrumbs,
        RetailChainTable,
    },
    computed: {
        isLoading() {
            return this.$store.getters["retailChain/isLoading"];
        },
        retailChains() {
            return this.$store.getters["retailChain/retailChains"];
        },
    },
};
</script>