import Storage from "../persistentStorage";

const KEY_LAST_SYNCED_AT = "liq_last_synced_at";

export default {
    async findLastSyncedAt() {
        return await Storage.getItem(KEY_LAST_SYNCED_AT);
    },
    async saveLastSyncedAt(lastSyncedAt) {
        await Storage.setItem(KEY_LAST_SYNCED_AT, lastSyncedAt);
    },
};
