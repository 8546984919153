<template>
    <tr class="border-solid border-b border-edge-primary">
        <th v-if="props.childRowTogglerFirst" />
        <vt-table-heading v-for="(column, i) in props.columns" :key="i" :column="column" />
        <th v-if="props.childRowTogglerLast" />
    </tr>
</template>

<script>
import VtTableHeading from 'vue-tables-2/compiled/components/VtTableHeading'

export default {
    name: "MyHeadingsRow",
    components: {
        VtTableHeading
    },
    props: {
        props: {
            type: Object,
            default: null,
        },
    },
}
</script>
