import ApiException from "./apiException";

export default class ApiBadRequestException extends ApiException {
    constructor(message, apiResponse) {
        if (null == apiResponse || !Object.prototype.hasOwnProperty.call(apiResponse, "data")) {
            super(`${message} (Error: Unknown error)`, apiResponse);
            return;
        }

        if (
            Object.prototype.hasOwnProperty.call(apiResponse.data, "@type")
            && Object.prototype.hasOwnProperty.call(apiResponse.data, "hydra:description")
            && 'hydra:Error' === apiResponse.data['@type']
        ) {
            super(`${message} (Error: "${apiResponse.data['hydra:description']}")`, apiResponse);
            return;
        }

        if (!Object.prototype.hasOwnProperty.call(apiResponse.data, "violations")) {
            super(`${message} (Error: Unknown error)`, apiResponse);
            return;
        }

        const violations = apiResponse.data.violations;
        if (!violations) {
            super(`${message} (Error: Unknown error)`, apiResponse);
            return;
        }

        let violationMessages = "";
        for (const violation of violations) {
            violationMessages += `<li><b>${Object.prototype.hasOwnProperty.call(violation, "propertyPath") ? violation.propertyPath : 'UnknownPropertyPath'}:</b> ${Object.prototype.hasOwnProperty.call(violation, "message") ? violation.message : "UnknownViolationMessage"}</li>`;
        }

        super(`${message}<br><br>Server-side validation not satisfied:<br><br><ul class="list-disc list-inside">${violationMessages}</ul>`, apiResponse);
    }
}
