import LanguageModel from "../model/language";
import baseApi from "./base/baseApi";

export default {
    async findAll() {
        return await baseApi.fetchCollectionPage(
            "/languages",
            1,
            null,
            (itemData) => {
                return LanguageModel.transformFromAPI(itemData)
            },
        );
    },
};
