import Roles from "../../util/roles";
import store from "../../store";

export default class StoreVoter {
    static canListStores() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canViewStore() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canCreateStore() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canUpdateStore() {
        return store.getters["security/isMemberOfRoles"]([
            Roles.ROLE_ADMINISTRATOR,
            Roles.ROLE_ACCOUNT_MANAGER,
            Roles.ROLE_SALES_MANAGER,
            Roles.ROLE_BRAND_MANAGER,
        ]);
    }

    static canDeleteStore() {
        return store.getters["security/hasRole"](Roles.ROLE_ADMINISTRATOR);
    }
}
