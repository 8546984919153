<template>
    <!-- eslint-disable vue/no-template-shadow -->
    <th
        slot-scope="props"
        class="text-left px-3 py-4 font-medium text-bold-text focus:outline-none"
        :class="{
            'cursor-pointer transition-colors duration-200 ease-out hover:text-liq-b hover:bg-liq-c': sortable(currentColumn),
            'bg-active-menu': orderBy().column === currentColumn
        }"
        v-bind="props.thAttrs"
        v-on="props.thEvents"
    >
        <div class="flex flex-row items-center">
            <span class="VueTables__heading" :title="props.title">
                <vnodes :vnodes="props.heading" />
            </span>
            <vt-sort-control />
        </div>
    </th>
</template>

<script>
import VtSortControl from "vue-tables-2/compiled/components/VtSortControl";

export default {
    name: "VtTableHeading",
    inject: ['column', 'sortable', 'orderBy'],
    components: {
        VtSortControl,
        vnodes: {
            functional: true,
            render: (h, ctx) => typeof ctx.props.vnodes === 'object' ? ctx.props.vnodes : [ctx.props.vnodes]
        }
    },
    props: {
        props: {
            type: Object,
            default: null,
        },
    },
    computed: {
        currentColumn() {
            return this.column();
        }
    },
}
</script>