import Storage from "../persistentStorage";
import StoreModel from "../../../model/store";
import SyncStatusEnum from "../../sync/syncStatusEnum";

const KEY_STORES = "liq_stores";

export default {
    async findAll() {
        let stores = await Storage.getItem(KEY_STORES);

        if (null === stores) {
            return [];
        }

        let storesObjects = [];
        const storeEntries = Object.entries(stores);
        for (const [storeIri, storeData] of storeEntries) {
            storesObjects[storeIri] = StoreModel.transformFromLocalCache(storeData);
        }

        return storesObjects;
    },
    async saveNewStore(store) {
        let currentStores = await this.findAll();

        currentStores[store.iri] = store;
        await this.overwriteAll(currentStores);

        return currentStores;
    },
    async saveExistingStore(updatedStore) {
        let currentStores = await this.findAll();

        currentStores[updatedStore.iri] = updatedStore;

        await this.overwriteAll(currentStores);

        return currentStores;
    },
    async deleteStore(storeToRemove) {
        let currentStores = await this.findAll();

        if (null === storeToRemove) {
            return currentStores;
        }

        if (SyncStatusEnum.CREATED_LOCALLY === storeToRemove.syncStatus) {
            // it hasn't been pushed yet, so just delete it from the cache
            delete currentStores[storeToRemove.iri];

            await this.overwriteAll(currentStores);

            return currentStores;
        }

        storeToRemove.syncStatus = SyncStatusEnum.DELETED_LOCALLY;

        return this.saveExistingStore(storeToRemove);
    },
    async overwriteAll(stores) {
        await Storage.setItem(KEY_STORES, stores);
    },
    async appendStores(newOrUpdatedStores) {
        let currentStores = await this.findAll();

        const newOrUpdatedStoreEntries = Object.entries(newOrUpdatedStores);
        for (const [newOrUpdatedStoreIri, newOrUpdatedStore] of newOrUpdatedStoreEntries) {
            currentStores[newOrUpdatedStoreIri] = newOrUpdatedStore;
        }

        await Storage.setItem(KEY_STORES, currentStores);

        return currentStores;
    },
};
