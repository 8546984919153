import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home";
import store from "../store";
import RetailChainForm from "../views/RetailChain/RetailChainForm";
import RetailChainOverview from "../views/RetailChain/RetailChainOverview";
import RetailChainDetail from "../views/RetailChain/RetailChainDetail";
import StoreForm from "../views/Store/StoreForm";
import StoreOverview from "../views/Store/StoreOverview";
import StoreDetail from "../views/Store/StoreDetail";
import FieldVisitForm from "../views/FieldVisit/FieldVisitForm";
import FieldVisitOverview from "../views/FieldVisit/FieldVisitOverview";
import FieldVisitDetail from "../views/FieldVisit/FieldVisitDetail";
import AuthorizationCallback from "../views/AuthorizationCallback";
import UserForm from "../views/User/UserForm";
import UserOverview from "../views/User/UserOverview";
import UserDetail from "../views/User/UserDetail";
import StoreVoter from "../security/voter/storeVoter";
import UserVoter from "../security/voter/userVoter";
import RetailChainVoter from "../security/voter/retailChainVoter";
import FieldVisitVoter from "../security/voter/fieldVisitVoter";

Vue.use(VueRouter);

let router = new VueRouter({
    mode: "history", // with mode: 'history', our URLs will look normal (e.g. http://app.localhost/home) instead of the default mode which uses the URL hash to simulate a full URL to prevent page reloading on URL changes
    routes: [
        {
            path: "/home", name: "home", component: Home,
            meta: {
                breadcrumbName: 'Home',
            },
        },
        {
            path: "/authorization_callback", name: "authorization_callback", component: AuthorizationCallback,
        },
        {
            path: "/users", name: "user_overview", component: UserOverview,
            meta: {
                voter: UserVoter.canListUsers,
                parentRoute: 'home',
                breadcrumbName: 'Users',
            },
        },
        {
            path: "/users/create", name: "user_create", component: UserForm,
            meta: {
                voter: UserVoter.canCreateUser,
                parentRoute: 'user_overview',
                breadcrumbName: 'Create user',
            },
        },
        {
            path: "/users/:userId/edit", name: "user_edit", component: UserForm, props: true,
            meta: {
                voter: UserVoter.canUpdateUser,
                voterArgs: ['userId'],
                parentRoute: 'user_overview',
                breadcrumbName: 'Edit user',
            },
        },
        {
            path: "/users/:userId", name: "user_detail", component: UserDetail, props: true,
            meta: {
                voter: UserVoter.canViewUser,
                parentRoute: 'user_overview',
                breadcrumbName: 'View user',
            },
        },
        {
            path: "/retail_chains", name: "retail_chain_overview", component: RetailChainOverview,
            meta: {
                voter: RetailChainVoter.canListRetailChains,
                parentRoute: 'home',
                breadcrumbName: 'Retail chains',
            },
        },
        {
            path: "/retail_chains/create", name: "retail_chain_create", component: RetailChainForm,
            meta: {
                voter: RetailChainVoter.canCreateRetailChain,
                parentRoute: 'retail_chain_overview',
                breadcrumbName: 'Create retail chain',
            },
        },
        {
            path: "/retail_chains/:retailChainId/edit", name: "retail_chain_edit", component: RetailChainForm, props: true,
            meta: {
                voter: RetailChainVoter.canUpdateRetailChain,
                parentRoute: 'retail_chain_overview',
                breadcrumbName: 'Edit retail chain',
            },
        },
        {
            path: "/retail_chains/:retailChainId", name: "retail_chain_detail", component: RetailChainDetail, props: true,
            meta: {
                voter: RetailChainVoter.canViewRetailChain,
                parentRoute: 'retail_chain_overview',
                breadcrumbName: 'View retail chain',
            },
        },
        {
            path: "/stores", name: "store_overview", component: StoreOverview,
            meta: {
                voter: StoreVoter.canListStores,
                parentRoute: 'home',
                breadcrumbName: 'Stores',
            },
        },
        {
            path: "/stores/create/:presetRetailChainId?", name: "store_create", component: StoreForm, props: true,
            meta: {
                voter: StoreVoter.canCreateStore,
                parentRoute: 'store_overview',
                breadcrumbName: 'Create store',
            },
        },
        {
            path: "/stores/:storeId/edit", name: "store_edit", component: StoreForm, props: true,
            meta: {
                voter: StoreVoter.canUpdateStore,
                parentRoute: 'store_overview',
                breadcrumbName: 'Edit store',
            },
        },
        {
            path: "/stores/:storeId", name: "store_detail", component: StoreDetail, props: true,
            meta: {
                voter: StoreVoter.canViewStore,
                parentRoute: 'store_overview',
                breadcrumbName: 'View store',
            },
        },
        {
            path: "/field_visits", name: "field_visit_overview", component: FieldVisitOverview,
            meta: {
                voter: FieldVisitVoter.canListFieldVisits,
                parentRoute: 'home',
                breadcrumbName: 'Field visits',
            },
        },
        {
            path: "/field_visits/create/:presetStoreId?", name: "field_visit_create", component: FieldVisitForm, props: true,
            meta: {
                voter: FieldVisitVoter.canCreateFieldVisit,
                parentRoute: 'field_visit_overview',
                breadcrumbName: 'Create field visit',
            },
        },
        {
            path: "/field_visits/:fieldVisitId/edit", name: "field_visit_edit", component: FieldVisitForm, props: true,
            meta: {
                voter: FieldVisitVoter.canUpdateFieldVisit,
                parentRoute: 'field_visit_overview',
                breadcrumbName: 'Edit field visit',
            },
        },
        {
            path: "/field_visits/:fieldVisitId", name: "field_visit_detail", component: FieldVisitDetail, props: true,
            meta: {
                voter: FieldVisitVoter.canViewFieldVisit,
                parentRoute: 'field_visit_overview',
                breadcrumbName: 'View field visit',
            },
        },
        { // all URLs which are not listed in our routes will make the Vue.js application redirect the user to the homepage
            path: "*", redirect: "/home",
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    if (to.meta.voter) {
        let isAuthenticated = store.getters["security/isAuthenticated"];
        if (false === isAuthenticated) {
            // Not authenticated, App.vue will take care of redirecting the user to the login form later
            next();
            return;
        }

        const voterArgs = [];
        if (to.meta.voterArgs) {
            for (const voterArg of to.meta.voterArgs) {
                voterArgs.push(to.params[voterArg]);
            }
        }

        if (false === to.meta.voter(...voterArgs)) { // pass the route parameters to the voter
            console.log("Access denied!")
            next('home');
            return;
        }
    }

    next(); // make sure to always call next()!
});

export default router;
