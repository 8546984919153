<template>
    <div>
        <breadcrumbs
            v-if="fieldVisit && fieldVisit.getVisitingStore()"
            :override-route-name="'Field visit for ' + fieldVisit.getVisitingStore().toString()"
        />

        <router-link
            :to="{ name: 'field_visit_edit', params: { fieldVisitId: fieldVisitId }}"
            class="btn-liq-default inline-block float-right"
        >
            Edit
        </router-link>

        <h1>
            View field visit detail
            <entity-sync-status v-if="fieldVisit" :sync-status="fieldVisit.syncStatus" />
        </h1>

        <div v-if="fieldVisit">
            <group-label>General</group-label>

            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <detail-item label="Store">
                        {{ fieldVisit.getVisitingStore() ? fieldVisit.getVisitingStore().toString() : '' }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Sales representative">
                        <span class="capitalize">
                            {{ fieldVisit.getSalesRep().toString() }}
                        </span>
                    </detail-item>
                </div>
            </div>

            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <detail-item label="Visit date">
                        {{ $date(fieldVisit.visitedDate).format($getConstant('DATETIME_FORMAT')) }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="New customer status">
                        {{ customerStatusLabelByKey(fieldVisit.newCustomerStatus) }}
                    </detail-item>
                </div>
            </div>

            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <group-label>
                        Actual SKU amounts
                        <help-popover>
                            Current amount of SKU's in shelves
                        </help-popover>
                    </group-label>
                    <div v-for="(v, index) in fieldVisit.skuSurveys" :key="'sku_survey_sku_amount_' + index">
                        <detail-item :label="skuTypeLabelByKey(v.type)">
                            {{ v.skuAmount }}
                        </detail-item>
                    </div>
                </div>
                <div>
                    <group-label>
                        Actuals/targets
                        <help-popover>
                            This percentage is calculated by the division of the actual SKU amount by the store's target SKU
                        </help-popover>
                    </group-label>
                    <div v-for="(v, index) in fieldVisit.skuSurveys" :key="'sku_survey_sku_percentage_' + index">
                        <detail-item :label="skuTypeLabelByKey(v.type)">
                            {{ calculateSkuPercentage(v) }}
                        </detail-item>
                    </div>
                </div>
                <div>
                    <group-label>
                        New SKU
                        <help-popover>
                            Amount of new SKU's negotiated
                        </help-popover>
                    </group-label>
                    <div v-for="(v, index) in fieldVisit.skuSurveys" :key="'sku_survey_new_sku_amount_' + index">
                        <detail-item :label="skuTypeLabelByKey(v.type)">
                            {{ v.newSkuAmount }}
                        </detail-item>
                    </div>
                </div>
                <div>
                    <group-label>
                        Orders
                        <help-popover>
                            Amount of colli's ordered
                        </help-popover>
                    </group-label>
                    <div v-for="(v, index) in fieldVisit.skuSurveys" :key="'sku_survey_colli_ordered_' + index">
                        <detail-item :label="'Colli ' + skuTypeLabelByKey(v.type).toLowerCase()">
                            {{ v.colliOrdered }}
                        </detail-item>
                    </div>
                </div>
            </div>

            <group-label>Brand value checks</group-label>
            <table class="table-fixed border-collapse w-full">
                <thead class="hidden sm:table-header-group">
                    <tr class="bg-backdrop-primary">
                        <th />
                        <th class="text-center">
                            OK on arrival
                        </th>
                        <th class="text-center">
                            Agreement made and resolved during visit
                        </th>
                        <th class="text-center">
                            Comment
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(v, index) in fieldVisit.brandValueChecks"
                        :key="'brand_value_check_' + index"
                        :set="brandValueCheck = brandValueCheckByKey(v.type)"
                        class="block sm:table-row mb-4 sm:mb-0"
                    >
                        <td class="text-left sm:text-center block sm:table-cell">
                            {{ brandValueCheck.label }}
                            <help-popover>
                                {{ brandValueCheck.help_text }}
                            </help-popover>
                        </td>
                        <td class="text-left sm:text-center block sm:table-cell">
                            <span class="inline sm:hidden">OK on arrival: </span>
                            <detail-checkbox :value="v.okOnArrival" />
                        </td>
                        <td class="text-left sm:text-center block sm:table-cell">
                            <span class="inline sm:hidden">Agreement made and resolved during visit: </span>
                            <detail-checkbox :value="v.fixedDuringVisit" />
                        </td>
                        <td class="text-left sm:text-center block sm:table-cell">
                            <span class="inline sm:hidden">Comment: </span>
                            {{ v.fixedDuringVisitComment }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <detail-item label="Comments">
                {{ fieldVisit.comment }}
            </detail-item>

            <group-label>Attach media</group-label>
            <div>
                <t-card>
                    <h2 class="border-b border-edge-primary pb-4 mb-4">
                        Photo(s) of the LiQ shelf on arrival
                    </h2>
                    <template v-if="fieldVisit">
                        <viewer :images="fieldVisitPhotosBeforeVisit" class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-4 gap-4">
                            <div v-for="(photo, index) in fieldVisitPhotosBeforeVisit" :key="'field_visit_photo_' + index" class="relative">
                                <div
                                    v-if="!photo.uploaded"
                                    class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center text-liq-a bg-backdrop-primary bg-opacity-75 pointer-events-none"
                                >
                                    <base-icon name="cloudUpload" class="w-16 h-16" />
                                </div>
                                <img :src="photo.src" class="w-full cursor-pointer">
                            </div>
                        </viewer>
                    </template>
                </t-card>
            </div>
            <div>
                <t-card>
                    <h2 class="border-b border-edge-primary pb-4 mb-4">
                        Photo(s) of the LiQ shelf when leaving
                    </h2>
                    <template v-if="fieldVisit">
                        <viewer :images="fieldVisitPhotosAfterVisit" class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-4 gap-4">
                            <div v-for="(photo, index) in fieldVisitPhotosAfterVisit" :key="'field_visit_photo_' + index" class="relative">
                                <div
                                    v-if="!photo.uploaded"
                                    class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center text-liq-a bg-backdrop-primary bg-opacity-75 pointer-events-none"
                                >
                                    <base-icon name="cloudUpload" class="w-16 h-16" />
                                </div>
                                <img :src="photo.src" class="w-full cursor-pointer">
                            </div>
                        </viewer>
                    </template>
                </t-card>
            </div>

            <group-label>Store manager/category manager</group-label>
            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <detail-item label="Name">
                        {{ fieldVisit.managerName }}
                    </detail-item>
                </div>
            </div>
            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <detail-item label="Motivation">
                        {{ fieldVisit.freezerManagerMotivation }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Hastily person?">
                        <detail-checkbox :value="fieldVisit.freezerManagerIsHastily" />
                    </detail-item>
                </div>
            </div>

            <router-link
                :to="{ name: 'field_visit_overview' }"
                class="btn-liq-default inline-block mt-4"
            >
                Back
            </router-link>
        </div>
        <div v-else>
            Loading data...
        </div>
    </div>
</template>

<script>

import NewCustomerStatus from "../../util/newCustomerStatus";
import SkuType from "../../util/skuType";
import BrandValueCheckType from "../../util/brandValueCheckType";
import EntitySyncStatus from "../../components/EntitySyncStatus";
import GroupLabel from "../../components/ui-components/form-elements/GroupLabel";
import DetailItem from "../../components/ui-components/form-elements/DetailItem";
import HelpPopover from "../../components/ui-components/form-elements/HelpPopover";
import BaseIcon from "../../components/BaseIcon";
import DetailCheckbox from "../../components/ui-components/form-elements/DetailCheckbox";
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    name: "FieldVisitDetail",
    components: {
        Breadcrumbs,
        DetailCheckbox,
        BaseIcon,
        HelpPopover,
        DetailItem,
        GroupLabel,
        EntitySyncStatus
    },
    props: {
        fieldVisitId: {
            type: [String],
            default: function () {
                return null;
            }
        }
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
        };
    },
    computed: {
        fieldVisit() {
            return this.$store.getters["fieldVisit/fieldVisitById"](this.fieldVisitId);
        },
        fieldVisitPhotosBeforeVisit() {
            const photos = this.fieldVisit.queuedMediaUploadsBeforeVisit.map(queuedMediaUpload => {
                return {
                    src: queuedMediaUpload.fileBase64,
                    uploaded: false,
                };
            });

            return photos.concat(
                this.fieldVisit.photosBeforeVisit.map(uploadedPhoto => {
                    return {
                        src: this.apiUrl + uploadedPhoto.contentUrl,
                        uploaded: true,
                    };
                })
            );
        },
        fieldVisitPhotosAfterVisit() {
            const photos = this.fieldVisit.queuedMediaUploadsAfterVisit.map(queuedMediaUpload => {
                return {
                    src: queuedMediaUpload.fileBase64,
                    uploaded: false,
                };
            });

            return photos.concat(
                this.fieldVisit.photosAfterVisit.map(uploadedPhoto => {
                    return {
                        src: this.apiUrl + uploadedPhoto.contentUrl,
                        uploaded: true,
                    };
                })
            );
        },
    },
    methods: {
        customerStatusLabelByKey(customerStatusKey) {
            return NewCustomerStatus.getLabelForKey(customerStatusKey);
        },
        skuTypeLabelByKey(skuTypeKey) {
            return SkuType.getLabelForKey(skuTypeKey);
        },
        brandValueCheckByKey(brandValueCheckTypeKey) {
            return BrandValueCheckType.getByKey(brandValueCheckTypeKey);
        },
        calculateSkuPercentage(skuSurvey) {
            const visitingStore = this.fieldVisit.getVisitingStore();

            if (!visitingStore) {
                return 'No store selected';
            }

            const storeTarget = visitingStore.skuTargets.find(skuTarget => skuTarget.type === skuSurvey.type);

            if (!storeTarget) {
                return 'No store target set';
            }

            const skuAmount = skuSurvey.skuAmount;
            const skuTarget = storeTarget.skuTarget;

            if (!skuAmount) {
                return 'No SKU amount';
            }

            if (skuTarget === 0) {
                return 'SKU target is 0';
            }

            const skuPercentage = Math.round(100 * skuAmount / skuTarget);

            return `${skuAmount}/${skuTarget} (${skuPercentage} %)`;
        },
    },
};
</script>
