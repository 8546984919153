<template>
    <!-- eslint-disable vue/no-template-shadow -->
    <div
        slot-scope="props"
        :class="`VueTables VueTables--${props.source}`"
    >
        <div :class="props.theme.row" class="inline">
            <div :class="props.theme.column" class="inline">
                <div
                    v-if="!props.opts.filterByColumn && props.opts.filterable"
                    :class="`${props.theme.field} VueTables__search`"
                    class="text-right block"
                >
                    <vnodes :vnodes="props.slots.beforeFilter" />
                    <vt-generic-filter ref="genericFilter" />
                    <vnodes :vnodes="props.slots.afterFilter" />
                </div>
                <vnodes :vnodes="props.slots.afterFilterWrapper" />

                <div v-if="props.opts.pagination.dropdown && props.totalPages > 1" class="VueTables__pagination-wrapper">
                    <div
                        :class="`${props.theme.field} ${props.theme.inline} ${props.theme.right} VueTables__dropdown-pagination`"
                    >
                        <vt-dropdown-pagination />
                    </div>
                </div>

                <div
                    v-if="props.opts.columnsDropdown"
                    :class="`VueTables__columns-dropdown-wrapper ${props.theme.right} ${props.theme.dropdown.container}`"
                >
                    <vt-columns-dropdown />
                </div>
            </div>
        </div>

        <div class="relative">
            <vnodes :vnodes="props.slots.beforeTable" />
            <div class="block overflow-x-auto w-full mt-4 mb-4">
                <vt-table ref="vt_table" />
            </div>
            <vnodes :vnodes="props.slots.afterTable" />
        </div>

        <vt-pagination />
    </div>
</template>

<script>
import VtColumnsDropdown from 'vue-tables-2/compiled/components/VtColumnsDropdown'
import VtDropdownPagination from 'vue-tables-2/compiled/components/VtDropdownPagination'
import VtGenericFilter from 'vue-tables-2/compiled/components/VtGenericFilter'
//import VtPerPageSelector from 'vue-tables-2/compiled/components/VtPerPageSelector';
import VtPagination from 'vue-tables-2/compiled/components/VtPagination'
import VtTable from 'vue-tables-2/compiled/components/VtTable';

export default {
    name: "MyDataTable",
    components: {
        VtGenericFilter,
        //VtPerPageSelector, // moved to VtPagination
        VtColumnsDropdown,
        VtDropdownPagination,
        VtTable,
        VtPagination,
        vnodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes
        }
    },
    props: {
        props: {
            type: Object,
            default: null,
        },
    },
}
</script>