import Vue from "vue";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import router from "./router";
import App from "./App";
import store from "./store";
import './registerServiceWorker';

import '../assets/css/app.scss';

if ('production' === process.env.NODE_ENV) {
    Bugsnag.start({
        apiKey: '9cc36827b609a18bc9f368d6afd17df5',
        plugins: [new BugsnagPluginVue()],
        metadata: {
            appVersion: process.env.PACKAGE_VERSION,
        },
        onError: function (event) {
            const appUser = store.getters['security/appUser'];

            if (appUser) {
                event.setUser(appUser.id, appUser.username, appUser.username);
            }
        },
    });

    Bugsnag
        .getPlugin('vue')
        .installVueErrorHandler(Vue)
    ;
}

store.dispatch("security/fetchInitialState").then(() => {
    new Vue({
        components: {App},
        template: "<App/>",
        router: router,
        store: store
    }).$mount("#app");
});
