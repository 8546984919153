<template>
    <t-input-group
        :label="label ? label : null"
    >
        <t-input
            v-model="value"
            :disabled="true"
            :type="type"
        />
    </t-input-group>
</template>

<script>
export default {
    name: "CalculatedInput",
    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
    },
}
</script>
