var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-client-table',{attrs:{"columns":[
            'syncStatus',
            'username',
            'roles',
            'userFunction',
            'actions' ],"options":{
            sortable: [
                'username',
                'roles',
                'userFunction' ],
            columnsDisplay: {
                'roles': 'min_tablet',
                'userFunction': 'min_tabletL',
            },
            filterAlgorithm: {
                roles: function roles(row, query) {
                    return row.roles.some(function (role) { return _vm.roleLabelByKey(role).toLowerCase().includes(query.toLowerCase()); });
                },
                userFunction: function userFunction(row, query) {
                    return _vm.userFunctionLabelByKey(row.userFunction).toLowerCase().includes(query.toLowerCase());
                },
            },
            columnsClasses: {
                'actions': 'whitespace-nowrap',
            },
            resizableColumns: false,
        }},on:{"row-click":_vm.rowClicked},scopedSlots:_vm._u([{key:"afterFilter",fn:function(){return [(_vm.canCreateUser)?_c('router-link',{staticClass:"ml-2 inline-flex flex-row items-center btn-liq-default",attrs:{"to":{ name: 'user_create' }}},[_c('base-icon',{staticClass:"w-4 h-4 mr-1 -ml-1",attrs:{"name":"plus"}}),_vm._v(" Create ")],1):_vm._e()]},proxy:true},{key:"syncStatus",fn:function(ref){
        var row = ref.row;
return [_c('entity-sync-status',{attrs:{"sync-status":row.syncStatus}})]}},{key:"roles",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.roles.map(function (role) { return _vm.roleLabelByKey(role); }).join(','))+" ")]}},{key:"userFunction",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.userFunctionLabelByKey(row.userFunction))+" ")]}},{key:"actions",fn:function(ref){
        var row = ref.row;
return [(_vm.canUpdateUser(row.id))?_c('router-link',{staticClass:"inline-block rounded-full transition-colors duration-200 ease-out hover:bg-liq-b hover:text-liq-c",attrs:{"to":{ name: 'user_edit', params: { userId: row.id }}}},[_c('base-icon',{staticClass:"w-4 h-4 m-2",attrs:{"name":"pencil"}})],1):_vm._e()]}},{key:"h__syncStatus",fn:function(){return [_vm._v(" ​ ")]},proxy:true},{key:"h__roles",fn:function(){return [_vm._v(" Role ")]},proxy:true},{key:"h__userFunction",fn:function(){return [_vm._v(" Function title ")]},proxy:true},{key:"afterTable",fn:function(){return [_c('table-loader',{attrs:{"display-loader":_vm.displayLoader}})]},proxy:true}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}}),_c('data-export-button',{attrs:{"table-export-mapping":_vm.excelExportMapping,"items-to-export":_vm.items,"data-subject":"User"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }