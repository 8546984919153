import Storage from "../persistentStorage";
import UserModel from "../../../model/user";

const KEY_USERS = "liq_users";

export default {
    async findAll() {
        let users = await Storage.getItem(KEY_USERS);

        if (null === users) {
            return [];
        }

        let usersObjects = [];
        const userEntries = Object.entries(users);
        for (const [userIri, userData] of userEntries) {
            usersObjects[userIri] = UserModel.transformFromLocalCache(userData);
        }

        return usersObjects;
    },
    async saveNewUser(user) {
        let currentUsers = await this.findAll();

        currentUsers[user.iri] = user;
        await this.overwriteAll(currentUsers);

        return currentUsers;
    },
    async saveExistingUser(updatedUser) {
        let currentUsers = await this.findAll();

        currentUsers[updatedUser.iri] = updatedUser;

        await this.overwriteAll(currentUsers);

        return currentUsers;
    },
    async deleteUser(userToRemove) {
        let currentUsers = await this.findAll();

        if (null === userToRemove) {
            return currentUsers;
        }

        delete currentUsers[userToRemove.iri];

        await this.overwriteAll(currentUsers);

        return currentUsers;
    },
    async overwriteAll(users) {
        await Storage.setItem(KEY_USERS, users);
    },
    async appendUsers(newOrUpdatedUsers) {
        let currentUsers = await this.findAll();

        const newOrUpdatedUserEntries = Object.entries(newOrUpdatedUsers);
        for (const [newOrUpdatedUserIri, newOrUpdatedUser] of newOrUpdatedUserEntries) {
            currentUsers[newOrUpdatedUserIri] = newOrUpdatedUser;
        }

        await Storage.setItem(KEY_USERS, currentUsers);

        return currentUsers;
    },
};
