<template>
    <button
        class="btn-liq-primary mt-4"
        @click="exportTable()"
    >
        <base-icon name="download" class="w-4 md:w-6 h-4 md:h-6" />
        Export data
    </button>
</template>

<script>
import BaseIcon from "./BaseIcon";
import XLSX from "xlsx/xlsx.mini";
export default {
    name: "DataExportButton",
    components: {BaseIcon},
    props: {
        itemsToExport: {
            type: Array,
            default: null,
        },
        tableExportMapping: {
            type: Function,
            default: null,
        },
        dataSubject: {
            type: String,
            default: '',
        },
    },
    methods: {
        exportTable() {
            if (null === this.tableExportMapping) {
                return;
            }

            let headerRow = null;
            const itemRows = [];
            for (const item of this.itemsToExport) {
                const mapping = this.tableExportMapping(item);

                if (null === headerRow) {
                    headerRow = Object.keys(mapping);
                }

                itemRows.push(Object.values(mapping));
            }

            const filename = `Export_${ this.dataSubject }s.xlsx`;
            const worksheetName = `${ this.dataSubject }s`;

            const rows = [headerRow].concat(itemRows);

            const workbook = XLSX.utils.book_new()
            const worksheet = XLSX.utils.aoa_to_sheet(rows);
            XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
            XLSX.writeFile(workbook, filename);
        },
    },
}
</script>
