<template>
    <component
        :is="iconComponent"
        role="img"
        class="inline-block"
    />
</template>

<script>
import Check from "./icons/Check";
import PlusCircle from "./icons/PlusCircle";
import Plus from "./icons/Plus";
import MinusCircle from "./icons/MinusCircle";
import PencilCircle from "./icons/PencilCircle";
import Cloud from "./icons/Cloud";
import CloudUpload from "./icons/CloudUpload";
import ExclamationCircle from "./icons/ExclamationCircle";
import RefreshAnimation from "./icons/RefreshAnimation";
import Menu from "./icons/Menu";
import Home from "./icons/Home";
import ShoppingCart from "./icons/ShoppingCart";
import PresentationChartLine from "./icons/PresentationChartLine";
import UserGroup from "./icons/UserGroup";
import Download from "./icons/Download";
import BadgeCheck from "./icons/BadgeCheck";
import Pencil from "./icons/Pencil";
import Trash from "./icons/Trash";
import Search from "./icons/Search";
import QuestionMarkCircle from "./icons/QuestionMarkCircle";
import ViewList from "./icons/ViewList";
import User from "./icons/User";

const icons = {
    // From https://heroicons.com/
    plusCircle: PlusCircle,
    plus: Plus,
    minusCircle: MinusCircle,
    pencilCircle: PencilCircle,
    cloud: Cloud,
    cloudUpload: CloudUpload,
    check: Check,
    exclamationCircle: ExclamationCircle,
    refreshAnimation: RefreshAnimation,
    menu: Menu,
    home: Home,
    shoppingCart: ShoppingCart,
    presentationChartLine: PresentationChartLine,
    userGroup: UserGroup,
    download: Download,
    badgeCheck: BadgeCheck,
    pencil: Pencil,
    trash: Trash,
    search: Search,
    questionMarkCircle: QuestionMarkCircle,
    viewList: ViewList,
    user: User,
}

export default {
    props: {
        name: {
            type: String,
            required: true,
            validator(value) {
                return Object.prototype.hasOwnProperty.call(icons, value)
            }
        }
    },

    computed: {
        iconComponent() {
            return icons[this.name];
        }
    }
}
</script>
