<template>
    <nav
        class="flex-shrink-0 bg-backdrop-secondary transition-width duration-500 ease-out flex flex-col overflow-hidden text-base"
        :class="{'w-48 md:w-64': expandedSidebar, 'w-12 md:w-16': !expandedSidebar}"
    >
        <div class="h-12 md:h-16 bg-liq-b">
            <button
                class="w-12 md:w-16 h-12 md:h-16 focus:outline-none hover:bg-liq-b-alt"
                @click="toggleExpandedSidebar()"
            >
                <base-icon
                    name="menu"
                    class="text-white w-4 md:w-6 h-4 md:h-6"
                    @click="toggleExpandedSidebar()"
                />
            </button>
        </div>

        <ul class="flex-1 flex flex-col">
            <li v-for="menuItem in menuItems" :key="menuItem.label">
                <template v-if="menuItem.route">
                    <div @click="collapseSidebarIfTemporarilyExpanded(); collapseExpandedSidebarItem();">
                        <router-link
                            :to="menuItem.route"
                            active-class="bg-active-menu custom_active-menu-item text-active-menu-accent"
                            class="block min-h-12 md:min-h-16 flex flex-row items-center flex-nowrap border-solid border-b border-edge-primary whitespace-nowrap hover:bg-hover-menu hover:text-hover-menu-text"
                        >
                            <base-icon :name="menuItem.icon" class="w-5 md:w-6 h-5 md:h-6 mx-4 md:mx-5 flex-none" />
                            <span class="text-sm md:text-base transition-opacity duration-300" :class="{'opacity-0': !expandedSidebar}">{{ menuItem.label }}</span>
                        </router-link>
                    </div>
                </template>
                <template v-else>
                    <div
                        class="min-h-12 md:min-h-16 flex flex-row items-center flex-nowrap cursor-pointer border-solid border-b border-edge-primary whitespace-nowrap hover:bg-hover-menu hover:text-hover-menu-text"
                        :class="{'bg-active-menu text-active-menu-accent': isChildItemActiveRoute(menuItem)}"
                        @click="setExpandedSidebarItem(menuItem.label)"
                    >
                        <base-icon :name="menuItem.icon" class="w-5 md:w-6 h-5 md:h-6 mx-4 md:mx-5 flex-none" />
                        <span class="text-sm md:text-base transition-opacity duration-300" :class="{'opacity-0': !expandedSidebar}">{{ menuItem.label }}</span>
                    </div>

                    <transition
                        enter-active-class="transition-max-height-opacity duration-300 ease-in-out"
                        leave-active-class="transition-max-height-opacity duration-300 ease-in-out"
                        enter-class="max-h-0 opacity-0"
                        :enter-to-class="'opacity-100 ' + maxHeightClassMappingByChildCount[menuItem.children.length]"
                        :leave-class="'opacity-100 ' + maxHeightClassMappingByChildCount[menuItem.children.length]"
                        leave-to-class="max-h-0 opacity-0"
                    >
                        <ul
                            v-if="expandedSidebarItem === menuItem.label && expandedSidebar"
                            class="overflow-hidden whitespace-nowrap text-xs md:text-sm"
                        >
                            <li v-for="subMenuItem in menuItem.children" :key="subMenuItem.label">
                                <div @click="collapseSidebarIfTemporarilyExpanded()">
                                    <router-link
                                        :to="subMenuItem.route"
                                        class="block pl-13 md:pl-16 py-3 md:py-4 transition-opacity duration-300 hover:bg-hover-menu hover:text-hover-menu-text"
                                        active-class="bg-active-menu custom_active-menu-item text-active-menu-accent"
                                        :class="{'opacity-0': !expandedSidebar}"
                                    >
                                        {{ subMenuItem.label }}
                                    </router-link>
                                </div>
                            </li>
                        </ul>
                    </transition>
                </template>
            </li>

            <li class="mt-auto">
                <div
                    class="block min-h-12 md:min-h-16 flex flex-row items-center flex-nowrap border-solid border-t border-edge-primary overflow-hidden whitespace-nowrap"
                    :class="{'hover:bg-hover-menu hover:text-hover-menu-text cursor-pointer': !appIsInstalled && appIsInstallable, 'text-disabled-text': appIsInstalled || !appIsInstallable}"
                    @click="(!appIsInstalled && appIsInstallable) ? showInstallAppPrompt() : null"
                >
                    <base-icon :name="!appIsInstallable ? 'download' : (appIsInstalled ? 'badgeCheck' : 'download')" class="w-5 md:w-6 h-5 md:h-6 mx-4 md:mx-5 flex-none" />
                    <div class="text-sm md:text-base transition-opacity duration-300" :class="{'opacity-0': !expandedSidebar}">
                        <span v-if="!appIsInstallable">
                            App not installable
                        </span>
                        <span v-else-if="!appIsInstalled">
                            Install this app!
                        </span>
                        <span v-else>
                            App is installed
                        </span>
                        <span class="block text-xs">
                            Version {{ appVersion }}
                        </span>
                    </div>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>

import BaseIcon from "./../BaseIcon";

export default {
    name: "Sidebar",
    components: {
        BaseIcon
    },
    data() {
        return {
            appIsInstalled: true, // if the beforeinstallprompt event is NOT triggered, we assume the app has been installed
            appIsInstallable: process.env.NODE_ENV === "production",
            showInstallAppPrompt: undefined,

            appVersion: process.env.PACKAGE_VERSION,

            expandedSidebar: true,
            sidebarTemporarilyExpanded: false,
            expandedSidebarItem: null,

            menuItems: [
                {
                    label: 'Home',
                    icon: 'home',
                    route: '/home',
                },
                {
                    label: 'Retail',
                    icon: 'shoppingCart',
                    children: [
                        {
                            label: 'Retail chains',
                            route: '/retail_chains',
                        },
                        {
                            label: 'Stores',
                            route: '/stores',
                        },
                        {
                            label: 'Field visits',
                            route: '/field_visits',
                        },
                    ],
                },
                /*{
                    label: 'Reports',
                    icon: 'presentationChartLine',
                    children: [],
                },
                */{
                    label: 'Management',
                    icon: 'userGroup',
                    children: [
                        {
                            label: 'Users',
                            route: '/users',
                        },
                    ],
                },
            ],

            // An exact max height is required for a smooth height transition. This max height is based on the number of elements in the list.
            // To make PurgeCSS not purge these classes, we need to write them explicitly in our code.
            // Something dynamic like 'max-h-' + numberOfElements * 16 is not possible, the classes are not written explicitly in the code so they would be removed in production mode.
            maxHeightClassMappingByChildCount: {
                0: 'max-h-0',
                1: 'max-h-12 md:max-h-16',
                2: 'max-h-24 md:max-h-32',
                3: 'max-h-36 md:max-h-48',
            },
        };
    },
    created() {
        // We have to use a callback since at this time the router reports that the route is always '/'
        this.$router.onReady(() => {
            this.expandCurrentSidebarItem();
        });

        const sidebarExpandedCookie = this.$cookies.get("liq_sidebar_expanded");
        if (null !== sidebarExpandedCookie) {
            this.expandedSidebar = sidebarExpandedCookie === 'true';
            this.$emit('toggled-expanded-state', this.expandedSidebar);
        }

        let installPrompt;

        // If the application can be installed locally, the browser raises the beforeinstallprompt event on the window object
        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();
            installPrompt = e;
            this.appIsInstalled = false;
        });

        this.showInstallAppPrompt = () => {
            installPrompt.prompt();
            installPrompt.userChoice.then(result => {
                if (result.outcome === "accepted") {
                    this.appIsInstalled = true;
                    console.log("User accepted app install");
                } else {
                    console.log("User denied app install");
                }
                installPrompt = null;
            });
        };
    },
    methods: {
        toggleExpandedSidebar() {
            this.expandedSidebar = !this.expandedSidebar;
            this.$emit('toggled-expanded-state', this.expandedSidebar);

            this.$cookies.set("liq_sidebar_expanded", this.expandedSidebar);
        },
        collapseSidebarIfTemporarilyExpanded() {
            if (this.sidebarTemporarilyExpanded) {
                this.expandedSidebar = false;
                this.$emit('toggled-expanded-state', this.expandedSidebar);
                this.sidebarTemporarilyExpanded = false;
                document.removeEventListener('click', this.clickedAwayFromSidebar);
            }
        },
        setExpandedSidebarItem(itemLabel) {
            if (!this.expandedSidebar) { // The sidebar was collapsed, so just open the sidebar (temporarily!) and return. Another click event will perform the actions below.
                this.expandedSidebar = true;
                this.$emit('toggled-expanded-state', this.expandedSidebar);
                this.sidebarTemporarilyExpanded = true;
                this.expandedSidebarItem = itemLabel;
                document.addEventListener('click', this.clickedAwayFromSidebar);
                return;
            }

            if (itemLabel === this.expandedSidebarItem) { // The currently expanded item is clicked again, so close it
                this.collapseExpandedSidebarItem();
                return;
            }

            this.expandedSidebarItem = itemLabel;
        },
        clickedAwayFromSidebar(event) {
            if (!this.$el.contains(event.target)) {
                this.collapseSidebarIfTemporarilyExpanded();
            }
        },
        collapseExpandedSidebarItem() {
            this.expandedSidebarItem = null;
        },
        isChildItemActiveRoute(menuItem) {
            if (!menuItem.children) {
                return false;
            }

            return menuItem.children.some(childItem => {
                return this.$route.path.indexOf(childItem.route) === 0;
            });
        },
        expandCurrentSidebarItem() {
            const currentRouteSidebarItem = this.menuItems.find(parentItem => {
                return this.isChildItemActiveRoute(parentItem);
            });

            if (!currentRouteSidebarItem) {
                return;
            }

            this.expandedSidebarItem = currentRouteSidebarItem.label;
        },
    },
}
</script>
