export default class SkuTarget {
    constructor(
        iri,
        type,
        skuTarget,
    ) {
        this.iri = iri;
        this.type = type;
        this.skuTarget = skuTarget;
    }

    static transformFromAPINested(skuTargetData) {
        return new this(
            skuTargetData['@id'],
            skuTargetData.type,
            skuTargetData.skuTarget,
        );
    }

    static transformFromLocalCacheNested(skuTargetData) {
        return new this(
            skuTargetData.iri,
            skuTargetData.type,
            skuTargetData.skuTarget,
        );
    }

    static createNewNested(formData) {
        return new this(
            null,
            formData.type,
            formData.skuTarget,
        );
    }

    commitUpdateNested(formData) {
        this.skuTarget = formData.skuTarget;
    }
}
