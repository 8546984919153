const STATUS_REMAINS_UNCHANGED = {
        'key': 'remains_unchanged',
        'label': 'Remains unchanged',
    },
    STATUS_BECAME_CUSTOMER = {
        'key': 'became_customer',
        'label': 'Became a customer',
    },
    STATUS_NO_LONGER_CUSTOMER = {
        'key': 'no_longer_customer',
        'label': 'No longer a customer',
    };

function all() {
    return [
        STATUS_REMAINS_UNCHANGED,
        STATUS_BECAME_CUSTOMER,
        STATUS_NO_LONGER_CUSTOMER,
    ];
}

function defaultStatus() {
    return STATUS_REMAINS_UNCHANGED;
}

function getLabelForKey(customerStatusKey) {
    const customerStatus = this.all().find(customerStatus => customerStatusKey === customerStatus.key);

    return customerStatus.label;
}

export default { all, defaultStatus, getLabelForKey };
