<template>
    <div>
        <template
            v-if="
                field.$dirty && (
                    'required' in field && !field.required ||
                    'requiredIf' in field && !field.requiredIf
                )
            "
        >
            This field is required
        </template>
    </div>
</template>

<script>
export default {
    name: "RequiredValidation",
    props: {
        label: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
    },
}
</script>
