<template>
    <div>
        <v-client-table
            v-model="items"
            :columns="[
                'syncStatus',
                'retailChainIri',
                'name',
                'address',
                'actions',
            ]"
            :options="{
                sortable: [
                    'retailChainIri',
                    'name',
                ],
                columnsDisplay: {
                    'retailChainIri': 'min_tablet',
                    'address': 'min_tabletL',
                },
                filterAlgorithm: {
                    retailChainIri(row, query) {
                        const retailChain = row.getRetailChain();

                        if (!retailChain) {
                            return false;
                        }

                        return retailChain.toString().toLowerCase().includes(query.toLowerCase());
                    },
                    address(row, query) {
                        return row.address.toString().toLowerCase().includes(query.toLowerCase());
                    },
                },
                columnsClasses: {
                    'actions': 'whitespace-nowrap',
                },
                resizableColumns: false,
            }"
            @row-click="rowClicked"
        >
            <template v-slot:afterFilter>
                <router-link
                    v-if="canCreateStore"
                    :to="null !== forRetailChainId ? { name: 'store_create', params: { presetRetailChainId: forRetailChainId } } : { name: 'store_create' }"
                    class="ml-2 inline-flex flex-row items-center btn-liq-default"
                >
                    <base-icon name="plus" class="w-4 h-4 mr-1 -ml-1" />
                    Create
                </router-link>
            </template>
            <template v-slot:syncStatus="{row}">
                <entity-sync-status :sync-status="row.syncStatus" />
            </template>
            <template v-slot:retailChainIri="{row}">
                {{ row.getRetailChain() ? row.getRetailChain().toString() : '' }}
            </template>
            <template v-slot:address="{row}">
                {{ row.address.toString() }}
            </template>
            <template v-slot:actions="{row}">
                <router-link
                    v-if="canUpdateStore"
                    :to="{ name: 'store_edit', params: { storeId: row.id }}"
                    class="inline-block rounded-full transition-colors duration-200 ease-out hover:bg-liq-b hover:text-liq-c"
                >
                    <base-icon name="pencil" class="w-4 h-4 m-2" />
                </router-link>
                <button
                    v-if="canDeleteStore"
                    class="inline-block rounded-full transition-colors duration-200 ease-out hover:bg-liq-b hover:text-liq-c"
                    @click.stop.prevent="deleteItem(row)"
                >
                    <!-- use the .stop.prevent modifiers on the click handler to prevent the row click event from being triggered -->
                    <base-icon name="trash" class="w-4 h-4 m-2" />
                </button>
            </template>
            <template v-slot:h__syncStatus>
                &#8203;
            </template>
            <template v-slot:h__retailChainIri>
                Retail chain
            </template>
            <template v-slot:afterTable>
                <table-loader :display-loader="displayLoader" />
            </template>
        </v-client-table>

        <data-export-button :table-export-mapping="excelExportMapping" :items-to-export="items" data-subject="Store" />
    </div>
</template>

<script>
import BaseIcon from "../../components/BaseIcon";
import EntitySyncStatus from "../../components/EntitySyncStatus";
import StoreVoter from "../../security/voter/storeVoter";
import Store from "../../model/store";
import DataExportButton from "../../components/DataExportButton";
import TableLoader from "../../components/TableLoader";

export default {
    name: "StoreTable",
    components: {
        TableLoader,
        DataExportButton,
        EntitySyncStatus,
        BaseIcon
    },
    props: {
        items: {
            type: Array,
            default: null,
        },
        forRetailChainId: {
            type: String,
            default: null,
        },
        displayLoader: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        canCreateStore() {
            return StoreVoter.canCreateStore();
        },
        canUpdateStore() {
            return StoreVoter.canUpdateStore();
        },
        canDeleteStore() {
            return StoreVoter.canDeleteStore();
        },
    },
    methods: {
        rowClicked(row) {
            this.$router.push({name: 'store_detail', params: { storeId: row.row.id }});
        },
        deleteItem(item) {
            this.$dialog
                .confirm({
                    title: 'Delete store?',
                    text: `Are you sure that you want to delete the store "${item.name}" and all of its associated field visits?`,
                    icon: 'info',
                    cancelButtonText: 'Cancel',
                    okButtonText: 'Yes',
                })
                .then(async (result) => {
                    if (!result || !result.isOk) {
                        return;
                    }

                    await this.$store.dispatch(
                        "store/delete",
                        {
                            id: item.id,
                        }
                    );

                    this.showSuccessNotification("Success", `Store "${item.name}" has been deleted!`);

                    await this.$store.dispatch("sync/syncData", {
                        silenceNotifications: true,
                    });
                })
            ;
        },
        excelExportMapping: Store.excelExportMapping,
    },
}
</script>
