import RetailChainModel from "../model/retailChain";
import baseApi from "./base/baseApi";
import baseAxios from "./base/baseAxios";

export default {
    async create(retailChain) {
        const payload = retailChain.transformToApi(true);

        return await baseAxios.post(
            "/retail_chains",
            payload,
            {
                addAuthorizationHeader: true,
            }
        );
    },
    async partialUpdate(retailChainId, retailChain) {
        const payload = retailChain.transformToApi(false);

        return await baseAxios.put(
            `/retail_chains/${retailChainId}`,
            payload,
            {
                addAuthorizationHeader: true,
            }
        );
    },
    async findAllModifiedSince(lastSyncedAt) {
        return await baseApi.fetchCollectionPage(
            "/retail_chains",
            1,
            lastSyncedAt,
            (itemData) => {
                return RetailChainModel.transformFromAPI(itemData)
            },
        );
    },
};
