<template>
    <transition
        enter-active-class="transition-opacity duration-300 ease-in-out"
        leave-active-class="transition-opacity duration-300 ease-in-out"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-if="displayLoader"
            class="absolute inset-0 bg-backdrop-primary bg-opacity-70 flex flex-col items-center justify-center"
        >
            <base-icon name="refreshAnimation" class="w-8 h-8 text-liq-b-alt" />

            <h1 class="mt-2">
                Loading...
            </h1>
        </div>
    </transition>
</template>

<script>
import BaseIcon from "./BaseIcon";
export default {
    name: "TableLoader",
    components: {BaseIcon},
    props: {
        displayLoader: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
