<template>
    <h2>
        <slot />
    </h2>
</template>

<script>
export default {
    name: "GroupLabel",
    props: {
        label: {
            type: String,
            default: null,
        },
    },
}
</script>
