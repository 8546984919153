var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-client-table',{attrs:{"columns":[
            'syncStatus',
            'storeIri',
            'visitedDate',
            'salesRep',
            'storeAddress',
            'actions' ],"options":{
            sortable: [
                'id',
                'storeIri',
                'visitedDate',
                'salesRep',
                'storeAddress' ],
            columnsDisplay: {
                'salesRep': 'min_tablet',
                'storeAddress': 'min_tabletL',
            },
            orderBy: {
                column: 'visitedDate',
                ascending: false
            },
            filterAlgorithm: {
                storeIri: function storeIri(row, query) {
                    if (undefined === row.getVisitingStore()) {
                        return '';
                    }

                    return row.getVisitingStore().toString().toLowerCase().includes(query.toLowerCase());
                },
                visitedDate: function visitedDate(row, query) {
                    return _vm.$date(row.visitedDate).format(_vm.$getConstant('DATETIME_FORMAT')).toString().toLowerCase().includes(query.toLowerCase());
                },
                salesRep: function salesRep(row, query) {
                    return row.getSalesRep().toString().toLowerCase().includes(query.toLowerCase());
                },
                storeAddress: function storeAddress(row, query) {
                    if (undefined === row.getVisitingStore()) {
                        return '';
                    }

                    return row.getVisitingStore().address.toString().toLowerCase().includes(query.toLowerCase());
                },
            },
            columnsClasses: {
                'salesRep': 'capitalize',
                'actions': 'whitespace-nowrap',
            },
            resizableColumns: false,
        }},on:{"row-click":_vm.rowClicked},scopedSlots:_vm._u([{key:"afterFilter",fn:function(){return [(_vm.canCreateFieldVisit)?_c('router-link',{staticClass:"ml-2 inline-flex flex-row items-center btn-liq-default",attrs:{"to":null !== _vm.forStoreId ? { name: 'field_visit_create', params: { presetStoreId: _vm.forStoreId } } : { name: 'field_visit_create' }}},[_c('base-icon',{staticClass:"w-4 h-4 mr-1 -ml-1",attrs:{"name":"plus"}}),_vm._v(" Create ")],1):_vm._e()]},proxy:true},{key:"syncStatus",fn:function(ref){
        var row = ref.row;
return [_c('entity-sync-status',{attrs:{"sync-status":row.syncStatus}})]}},{key:"storeIri",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(undefined === row.getVisitingStore() ? '' : row.getVisitingStore().toString())+" ")]}},{key:"visitedDate",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$date(row.visitedDate).format(_vm.$getConstant('DATETIME_FORMAT')))+" ")]}},{key:"salesRep",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(row.getSalesRep())+" ")]}},{key:"storeAddress",fn:function(ref){
        var row = ref.row;
return [_vm._v(" "+_vm._s(undefined === row.getVisitingStore() ? '' : row.getVisitingStore().address.toString())+" ")]}},{key:"h__syncStatus",fn:function(){return [_vm._v(" ​ ")]},proxy:true},{key:"h__storeIri",fn:function(){return [_vm._v(" Store ")]},proxy:true},{key:"h__visitedDate",fn:function(){return [_vm._v(" Visit date ")]},proxy:true},{key:"h__salesRep",fn:function(){return [_vm._v(" Sales representative ")]},proxy:true},{key:"h__storeAddress",fn:function(){return [_vm._v(" Address ")]},proxy:true},{key:"actions",fn:function(ref){
        var row = ref.row;
return [(_vm.canUpdateFieldVisit)?_c('router-link',{staticClass:"inline-block rounded-full transition-colors duration-200 ease-out hover:bg-liq-b hover:text-liq-c",attrs:{"to":{ name: 'field_visit_edit', params: { fieldVisitId: row.id }}}},[_c('base-icon',{staticClass:"w-4 h-4 m-2",attrs:{"name":"pencil"}})],1):_vm._e()]}},{key:"afterTable",fn:function(){return [_c('table-loader',{attrs:{"display-loader":_vm.displayLoader}})]},proxy:true}]),model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}}),_c('data-export-button',{attrs:{"table-export-mapping":_vm.excelExportMapping,"items-to-export":_vm.items,"data-subject":"FieldVisit"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }