<template>
    <t-input-group
        :label="label ? (label + (field.$params.sameAs || field.$params.sameAsIf ? ' *' : '')) : null"
        :variant="{
            error: field.$dirty && field.$error,
        }"
    >
        <t-checkbox
            v-model.trim.lazy="field.$model"
            :disabled="disabled"
            :variant="{
                error: field.$dirty && field.$error
            }"
        />
        <template v-slot:feedback>
            <template
                v-if="
                    field.$dirty && (
                        'sameAs' in field && !field.sameAs ||
                        'sameAsIf' in field && !field.sameAsIf
                    )
                "
            >
                This field must be checked
            </template>
        </template>
    </t-input-group>
</template>

<script>
export default {
    name: "ValidatedCheckbox",
    props: {
        label: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
