export default class BrandValueCheck {
    constructor(
        iri,
        type,
        okOnArrival,
        fixedDuringVisit,
        fixedDuringVisitComment,
    ) {
        this.iri = iri;
        this.type = type;
        this.okOnArrival = okOnArrival;
        this.fixedDuringVisit = fixedDuringVisit;
        this.fixedDuringVisitComment = fixedDuringVisitComment;
    }

    static transformFromAPINested(brandValueCheckData) {
        return new this(
            brandValueCheckData['@id'],
            brandValueCheckData.type,
            brandValueCheckData.okOnArrival,
            brandValueCheckData.fixedDuringVisit,
            brandValueCheckData.fixedDuringVisitComment,
        );
    }

    static transformFromLocalCacheNested(brandValueCheckData) {
        return new this(
            brandValueCheckData.iri,
            brandValueCheckData.type,
            brandValueCheckData.okOnArrival,
            brandValueCheckData.fixedDuringVisit,
            brandValueCheckData.fixedDuringVisitComment,
        );
    }

    static createNewNested(formData) {
        return new this(
            null,
            formData.type,
            formData.okOnArrival,
            formData.fixedDuringVisit,
            formData.fixedDuringVisitComment,
        );
    }

    commitUpdateNested(formData) {
        this.okOnArrival = formData.okOnArrival;
        this.fixedDuringVisit = formData.fixedDuringVisit;
        this.fixedDuringVisitComment = formData.fixedDuringVisitComment;
    }
}
