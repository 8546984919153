"use strict";

module.exports = function () {
    return {
        framework: 'tailwind',
        td: 'px-3 py-4 focus:outline-none',
        table: 'w-full max-w-full table-auto bg-white',
        row: '',
        column: '',
        label: '',
        input: 'rounded border-solid border border-edge-primary py-1 md:py-2 px-2 md:px-3 max-w-full text-sm md:text-base focus:outline-none bg-white',
        select: 'rounded border-solid border border-edge-primary py-1 pl-2 pr-8 text-base focus:outline-none bg-white',
        field: '', // class for search field + label wrapper, for example 'form-group'
        inline: 'inline',
        right: '',
        left: '',
        center: '',//'text-center', // strangely also used for the <nav> element in the pagination
        contentCenter: 'justify-center',
        nomargin: 'm-0',
        groupTr: '',//'table-info',
        small: '',
        button: 'btn-liq-primary',
        dropdown: {
            container: '',
            trigger: '',
            menu: '',
            content: '',
            item: '',
            caret: '',
        },
        pagination: { // Unused! We use VueTailwind pagination component
            nav: '',
            count: '',
            wrapper: '',
            list: '',
            item: '',
            link: '',
            prev: '',
            next: '',
            active: '',
            disabled: '',
            icon: '',
        },
    };
};