import SyncStatusEnum from "../store/sync/syncStatusEnum";
import ChangeSetComputer from "../util/changeSetComputer";
import dayjs from "dayjs";
import {uuid} from "vue-uuid";

export default class User {
    constructor(
        iri,
        id,
        username,
        newPassword,
        roles,
        userFunction,
        createdAt,
        updatedAt,
        syncedAt,
        syncStatus,
        modifiedFields
    ) {
        this.iri = iri;
        this.id = id;
        this.username = username;
        this.newPassword = newPassword;
        this.roles = roles;
        this.userFunction = userFunction;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.syncedAt = syncedAt;

        this.syncStatus = syncStatus;
        this.modifiedFields = modifiedFields;
    }

    static transformFromAPI(userData) {
        return new this(
            userData['@id'],
            userData.id,
            userData.username,
            null,
            userData.roles,
            userData.userFunction,
            userData.createdAt,
            userData.updatedAt,
            userData.syncedAt,
            SyncStatusEnum.IN_SYNC,
            [],
        );
    }

    transformToApi(isNewItem) {
        const fieldTransformations = {
            id: isNewItem ? {
                outputKey: 'id',
                outputValue: this.id
            } : null,
            username: isNewItem ? { // an existing user's username cannot be renamed
                outputKey: 'username',
                outputValue: this.username,
                options: {
                    filterIfNotModified: true,
                },
            } : null,
            newPassword: {
                outputKey: 'plainPassword',
                outputValue: this.newPassword,
                options: {
                    filterIfNotModified: true,
                },
            },
            roles: {
                outputKey: 'roles',
                outputValue: this.roles,
                options: {
                    filterIfNotModified: true,
                    plainArray: true,
                },
            },
            userFunction: {
                outputKey: 'userFunction',
                outputValue: this.userFunction,
                options: {
                    filterIfNotModified: true,
                },
            },

            createdAt: isNewItem ? {
                outputKey: 'createdAt',
                outputValue: dayjs(this.createdAt).toISOString()
            } : null,
            updatedAt: {
                outputKey: 'updatedAt',
                outputValue: dayjs(this.updatedAt).toISOString()
            },
        };

        return ChangeSetComputer.filterFieldsByChangeSet(
            fieldTransformations,
            this.modifiedFields,
            isNewItem
        );
    }

    static transformFromLocalCache(userData) {
        return new this(
            userData.iri,
            userData.id,
            userData.username,
            userData.newPassword,
            userData.roles,
            userData.userFunction,
            userData.createdAt,
            userData.updatedAt,
            userData.syncedAt,
            userData.syncStatus,
            userData.modifiedFields,
        );
    }

    static createNew(formData) {
        const id = uuid.v4();
        var now = dayjs();

        return new this(
            this.buildIriFromId(id),
            id,
            formData.username,
            formData.newPassword,
            formData.roles,
            formData.userFunction,
            now.format(),
            now.format(),
            null,
            SyncStatusEnum.CREATED_LOCALLY,
            [],
        );
    }

    commitUpdate(formData) {
        ChangeSetComputer.computeChangeSet(this, formData);

        this.username = formData.username;
        this.newPassword = formData.newPassword;
        this.roles = formData.roles;
        this.userFunction = formData.userFunction;

        var now = dayjs();

        if (null === this.createdAt) {
            this.createdAt = now.format();
        }
        this.updatedAt = now.format();
        if (SyncStatusEnum.CREATED_LOCALLY !== this.syncStatus) {
            this.syncStatus = SyncStatusEnum.UPDATED_LOCALLY;
        }
    }

    toString() {
        return `${this.username}`;
    }

    static buildIriFromId(id) {
        return `/users/${id}`
    }

    static excelExportMapping(record) {
        return {
            'username': record.username,
            'roles': record.roles.join(','),
            'userFunction': record.userFunction,
        };
    }
}
