export default class SkuSurvey {
    constructor(
        iri,
        type,
        skuAmount,
        newSkuAmount,
        colliOrdered,
    ) {
        this.iri = iri;
        this.type = type;
        this.skuAmount = skuAmount;
        this.newSkuAmount = newSkuAmount;
        this.colliOrdered = colliOrdered;
    }

    static transformFromAPINested(skuSurveyData) {
        return new this(
            skuSurveyData['@id'],
            skuSurveyData.type,
            skuSurveyData.skuAmount,
            skuSurveyData.newSkuAmount,
            skuSurveyData.colliOrdered,
        );
    }

    static transformFromLocalCacheNested(skuSurveyData) {
        return new this(
            skuSurveyData.iri,
            skuSurveyData.type,
            skuSurveyData.skuAmount,
            skuSurveyData.newSkuAmount,
            skuSurveyData.colliOrdered,
        );
    }

    static createNewNested(formData) {
        return new this(
            null,
            formData.type,
            formData.skuAmount,
            formData.newSkuAmount,
            formData.colliOrdered,
        );
    }

    commitUpdateNested(formData) {
        this.skuAmount = formData.skuAmount;
        this.newSkuAmount = formData.newSkuAmount;
        this.colliOrdered = formData.colliOrdered;
    }
}
