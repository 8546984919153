import Storage from "../persistentStorage";
import FieldVisitModel from "../../../model/fieldVisit";

const KEY_FIELD_VISITS = "liq_field_visits";

export default {
    async findAll() {
        let fieldVisits = await Storage.getItem(KEY_FIELD_VISITS);

        if (null === fieldVisits) {
            return [];
        }

        let fieldVisitsObjects = [];
        const fieldVisitEntries = Object.entries(fieldVisits);
        for (const [fieldVisitIri, fieldVisitData] of fieldVisitEntries) {
            fieldVisitsObjects[fieldVisitIri] = FieldVisitModel.transformFromLocalCache(fieldVisitData);
        }

        return fieldVisitsObjects;
    },
    async saveNewFieldVisit(fieldVisit) {
        let currentFieldVisits = await this.findAll();

        currentFieldVisits[fieldVisit.iri] = fieldVisit;
        await this.overwriteAll(currentFieldVisits);

        return currentFieldVisits;
    },
    async saveExistingFieldVisit(updatedFieldVisit) {
        let currentFieldVisits = await this.findAll();

        currentFieldVisits[updatedFieldVisit.iri] = updatedFieldVisit;

        await this.overwriteAll(currentFieldVisits);

        return currentFieldVisits;
    },
    async deleteFieldVisit(fieldVisitToRemove) {
        let currentFieldVisits = await this.findAll();

        if (null === fieldVisitToRemove) {
            return currentFieldVisits;
        }

        delete currentFieldVisits[fieldVisitToRemove.iri];

        await this.overwriteAll(currentFieldVisits);

        return currentFieldVisits;
    },
    async overwriteAll(fieldVisits) {
        await Storage.setItem(KEY_FIELD_VISITS, fieldVisits);
    },
    async appendFieldVisits(newOrUpdatedFieldVisits) {
        let currentFieldVisits = await this.findAll();

        const newOrUpdatedFieldVisitEntries = Object.entries(newOrUpdatedFieldVisits);
        for (const [newOrUpdatedFieldVisitIri, newOrUpdatedFieldVisit] of newOrUpdatedFieldVisitEntries) {
            currentFieldVisits[newOrUpdatedFieldVisitIri] = newOrUpdatedFieldVisit;
        }

        await Storage.setItem(KEY_FIELD_VISITS, currentFieldVisits);

        return currentFieldVisits;
    },
};
