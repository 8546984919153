import store from "../store";

export default class Address {
    constructor(
        iri,
        city,
        number,
        postalCode,
        street,
        countryIri,
    ) {
        this.iri = iri;
        this.city = city;
        this.number = number;
        this.postalCode = postalCode;
        this.street = street;
        this.countryIri = countryIri;
    }

    static transformFromAPINested(addressData) {
        return new this(
            addressData['@id'],
            addressData.city,
            addressData.number,
            addressData.postalCode,
            addressData.street,
            addressData.country,
        );
    }

    static transformFromLocalCacheNested(addressData) {
        return new this(
            addressData.iri,
            addressData.city,
            addressData.number,
            addressData.postalCode,
            addressData.street,
            addressData.countryIri,
        );
    }

    static createNewNested(formData) {
        return new this(
            null,
            formData.city,
            formData.number,
            formData.postalCode,
            formData.street,
            formData.countryIri,
        );
    }

    commitUpdateNested(formData) {
        this.street = formData.street;
        this.number = formData.number;
        this.postalCode = formData.postalCode;
        this.city = formData.city;
        this.countryIri = formData.countryIri;
    }

    getCountry() {
        return store.getters["country/countryByIri"](this.countryIri);
    }

    toString() {
        const country = this.getCountry();

        return `${this.street} ${this.number} ${this.postalCode} ${this.city}, ${country ? country.name : 'Unknown country'}`;
    }
}
