const CONSTANTS = {
    DATETIME_FORMAT: "MMMM DD, YYYY",
};

CONSTANTS.install = function (Vue) {
    Vue.prototype.$getConstant = (key) => {
        return CONSTANTS[key];
    }
};

export default CONSTANTS;
