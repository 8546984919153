<template>
    <t-input-group
        :label="label ? (label + (field.$params.required ? ' *' : '')) : null"
        :variant="{
            error: field.$dirty && field.$error,
            success: field.$dirty && !field.$error
        }"
    >
        <t-datepicker
            v-model.trim.lazy="field.$model"
            date-format="YYYY-MM-DDTHH:mm:ss[Z]"
            user-format="MMMM DD, YYYY"
            :date-formatter="dateFormatter"
            :date-parser="dateParser"
            :clearable="false"
            :variant="{
                error: field.$dirty && field.$error,
                success: field.$dirty && !field.$error,
            }"
        />
        <template v-slot:feedback>
            <required-validation
                :field="field"
                :label="label"
            />
            <template v-if="field.$dirty && !field.minLength && field.$params.minLength">
                {{ label }} must have at least {{ field.$params.minLength.min }} letters.
            </template>
        </template>
    </t-input-group>
</template>

<script>

import dayjs from 'dayjs';
import RequiredValidation from "./validation/RequiredValidation";

export default {
    name: "ValidatedDatePicker",
    components: {RequiredValidation},
    props: {
        label: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
    },
    methods: {
        dateFormatter (date, format) {
            return dayjs(date).format(format);
        },
        dateParser (date, format) {
            return dayjs(date, format).toDate();
        },
    },
}
</script>
