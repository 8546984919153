import ApiException from "../../exception/apiException";
import baseAxios from "./baseAxios";

export default {
    async fetchCollectionPage(url, pageNumber, lastSyncedAt, itemTransformerCallback) {
        let collectedItems = [];

        let params = {
            page: pageNumber,
        };

        if (null !== lastSyncedAt) {
            params['syncedAt[after]'] = lastSyncedAt;
        }

        let response = await baseAxios.get(
            url,
            {
                addAuthorizationHeader: true,
                params: params,
            }
        );

        if (null === response || !Object.prototype.hasOwnProperty.call(response, "data")) {
            return Promise.reject(
                new ApiException("No response data returned", response)
            );
        }

        if (!Object.prototype.hasOwnProperty.call(response.data, "hydra:member")) {
            return Promise.reject(
                new ApiException("Unexpected response format", response)
            );
        }

        let pageData = response.data['hydra:member'];

        pageData.forEach(function(itemData) {
            let item = itemTransformerCallback(itemData);
            collectedItems[item.iri] = item;
        });

        if (
            !Object.prototype.hasOwnProperty.call(response.data, "hydra:view")
            || !Object.prototype.hasOwnProperty.call(response.data["hydra:view"], "hydra:next")
        ) {
            return collectedItems;
        }

        const nextPage = await this.fetchCollectionPage(
            url,
            pageNumber + 1,
            lastSyncedAt,
            itemTransformerCallback
        );
        collectedItems = { ...collectedItems, ...nextPage };

        return collectedItems;
    },
};
