<template>
    <t-input-group
        :label="label ? (label + (field.$params.required ? ' *' : '')) : null"
        :variant="{
            error: field.$dirty && field.$error,
            success: field.$dirty && !field.$error
        }"
    >
        <input
            ref="fileUploader"
            type="file"
            multiple
            @change="uploadHandler()"
        >
        <template v-slot:feedback>
            <required-validation
                :field="field"
                :label="label"
            />
            <template v-if="field.$dirty && !field.minValue && field.$params.minValue">
                You must upload at least {{ field.$params.minValue.min }} {{ label.toLowerCase() }}.
            </template>
        </template>
    </t-input-group>
</template>

<script>
import RequiredValidation from "./validation/RequiredValidation";
export default {
    name: "ValidatedFileInput",
    components: {RequiredValidation},
    props: {
        label: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
    },
    methods: {
        async uploadHandler() {
            if (undefined === this.$refs.fileUploader) {
                return;
            }

            const files = this.$refs.fileUploader.files;
            let mediaUploads = [];

            for (const file of files) {
                const fileBase64 = await this.convertFileToBase64(file)
                    .catch(error => console.error(error))
                ;

                if (fileBase64 instanceof Error) {
                    console.error("Error converting uploaded file to base64", fileBase64.message);
                    continue;
                }

                mediaUploads.push(
                    {
                        fileName: file.name,
                        fileBase64: fileBase64,
                    }
                );
            }

            this.$refs.fileUploader.value = null; // clear file input

            this.$emit('uploadSubmitted', mediaUploads);
        },
        convertFileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);

                reader.readAsDataURL(file);
            });
        },
    },
}
</script>
