<template>
    <div>
        <breadcrumbs
            v-if="retailChain"
            :override-route-name="retailChain.name"
        />

        <router-link
            :to="{ name: 'retail_chain_edit', params: { retailChainId: retailChainId }}"
            class="btn-liq-default inline-block float-right"
        >
            Edit
        </router-link>

        <h1>
            View retail chain detail
            <entity-sync-status v-if="retailChain" :sync-status="retailChain.syncStatus" />
        </h1>

        <div v-if="retailChain">
            <group-label>General</group-label>
            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <detail-item label="Name">
                        {{ retailChain.name }}
                    </detail-item>
                </div>
            </div>

            <group-label>Address</group-label>
            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <detail-item label="Street">
                        {{ retailChain.address.street }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Number">
                        {{ retailChain.address.number }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Postal code/zipcode">
                        {{ retailChain.address.postalCode }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="City">
                        {{ retailChain.address.city }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Country">
                        {{ retailChainCountry.name }}
                    </detail-item>
                </div>
            </div>

            <group-label>VAT</group-label>
            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <detail-item label="VAT number">
                        {{ retailChain.vatNumber }}
                    </detail-item>
                </div>
            </div>

            <template v-if="canViewSensitiveRetailChainFields">
                <group-label>Buyer</group-label>
                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <detail-item label="Name">
                            {{ retailChain.buyerName }}
                        </detail-item>
                    </div>
                    <div>
                        <detail-item label="Email">
                            <a class="text-light-text hover:text-liq-a" :href="'mailto:' + retailChain.buyerEmail">{{ retailChain.buyerEmail }}</a>
                        </detail-item>
                    </div>
                </div>
            </template>

            <group-label>Stores</group-label>
            <store-table :items="retailChainStores" :for-retail-chain-id="retailChainId" />

            <router-link
                :to="{ name: 'retail_chain_overview' }"
                class="btn-liq-default inline-block mt-4"
            >
                Back
            </router-link>
        </div>
        <div v-else>
            Loading data...
        </div>
    </div>
</template>

<script>

import GroupLabel from "../../components/ui-components/form-elements/GroupLabel";
import DetailItem from "../../components/ui-components/form-elements/DetailItem";
import StoreTable from "../Store/StoreTable";
import EntitySyncStatus from "../../components/EntitySyncStatus";
import Breadcrumbs from "../../components/Breadcrumbs";
import RetailChainVoter from "../../security/voter/retailChainVoter";

export default {
    name: "RetailChainDetail",
    components: {
        Breadcrumbs,
        EntitySyncStatus,
        StoreTable,
        DetailItem,
        GroupLabel,
    },
    props: {
        retailChainId: {
            type: [String],
            default: function () {
                return null;
            }
        }
    },
    computed: {
        retailChain() {
            return this.$store.getters["retailChain/retailChainById"](this.retailChainId);
        },
        retailChainCountry() {
            return this.$store.getters["country/countryByIri"](this.retailChain.address.countryIri);
        },
        retailChainStores() {
            return this.$store.getters["store/storesByRetailChainIri"](this.retailChain.iri);
        },
        canViewSensitiveRetailChainFields() {
            return RetailChainVoter.canViewSensitiveRetailChainFields();
        },
    },
};
</script>
