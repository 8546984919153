<template>
    <div>
        <breadcrumbs
            v-if="store"
            :override-route-name="store.name"
        />

        <router-link
            :to="{ name: 'store_edit', params: { storeId: storeId }}"
            class="btn-liq-default inline-block float-right"
        >
            Edit
        </router-link>

        <h1>
            View store detail
            <entity-sync-status v-if="store" :sync-status="store.syncStatus" />
        </h1>

        <div v-if="store">
            <group-label>General</group-label>
            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <detail-item label="Name">
                        {{ store.name }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Retail chain">
                        {{ store.getRetailChain() ? store.getRetailChain().name : 'None' }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Language">
                        {{ storeLanguage.name }}
                    </detail-item>
                </div>
            </div>

            <div>
                <group-label>SKU targets</group-label>
                <div v-for="(v, index) in store.skuTargets" :key="'sku_target' + index">
                    <detail-item :label="'SKU target ' + skuTypeLabelByKey(v.type).toLowerCase()">
                        {{ v.skuTarget }}
                    </detail-item>
                </div>
            </div>

            <group-label>Address</group-label>
            <div class="grid sm:grid-cols-2 gap-x-4">
                <div>
                    <detail-item label="Street">
                        {{ store.address.street }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Number">
                        {{ store.address.number }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Postal code/zipcode">
                        {{ store.address.postalCode }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="City">
                        {{ store.address.city }}
                    </detail-item>
                </div>
                <div>
                    <detail-item label="Country">
                        {{ storeCountry.name }}
                    </detail-item>
                </div>
            </div>

            <group-label>Field visits</group-label>
            <field-visit-table :items="storeFieldVisits" :for-store-id="storeId" />

            <router-link
                :to="{ name: 'store_overview' }"
                class="btn-liq-default inline-block mt-4"
            >
                Back
            </router-link>
        </div>
        <div v-else>
            Loading data...
        </div>
    </div>
</template>

<script>

import EntitySyncStatus from "../../components/EntitySyncStatus";
import GroupLabel from "../../components/ui-components/form-elements/GroupLabel";
import DetailItem from "../../components/ui-components/form-elements/DetailItem";
import SkuType from "../../util/skuType";
import FieldVisitTable from "../FieldVisit/FieldVisitTable";
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    name: "StoreDetail",
    components: {
        Breadcrumbs,
        FieldVisitTable,
        DetailItem,
        GroupLabel,
        EntitySyncStatus
    },
    props: {
        storeId: {
            type: [String],
            default: function () {
                return null;
            }
        }
    },
    computed: {
        store() {
            return this.$store.getters["store/storeById"](this.storeId);
        },
        storeLanguage() {
            return this.$store.getters["language/languageByIri"](this.store.languageIri);
        },
        storeCountry() {
            return this.$store.getters["country/countryByIri"](this.store.address.countryIri);
        },
        storeFieldVisits() {
            return this.$store.getters["fieldVisit/fieldVisitsByStoreIri"](this.store.iri);
        },
    },
    methods: {
        skuTypeLabelByKey(skuTypeKey) {
            return SkuType.getLabelForKey(skuTypeKey);
        },
    },
};
</script>
