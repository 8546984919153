<template>
    <div class="fixed w-full h-full bg-backdrop-primary flex flex-col items-center justify-center">
        <base-icon name="refreshAnimation" class="w-8 h-8 text-liq-b-alt" />

        <h1 class="mt-2">
            Signing in...
        </h1>

        <p v-if="authError" class="text-center">
            An error occurred during authentication: {{ authError }}
        </p>
        <p v-else>
            One moment
        </p>
    </div>
</template>

<script>
import BaseIcon from "./BaseIcon";
export default {
    name: "AuthenticationPreloader",
    components: {BaseIcon},
    props: {
        authError: {
            type: String,
            default: null,
        },
    },
};
</script>
