<template>
    <div>
        <required-validation
            :field="field"
            :label="label"
        />
        <template v-if="field.$dirty && !field.minLength && field.$params.minLength">
            {{ label }} must have at least {{ field.$params.minLength.min }} letters.
        </template>
        <template v-if="field.$dirty && !field.maxLength && field.$params.maxLength">
            {{ label }} must have no more than {{ field.$params.maxLength.max }} letters.
        </template>
        <template v-if="field.$dirty && !field.minValue && field.$params.minValue">
            {{ label }} must have a minimum value of {{ field.$params.minValue.min }}.
        </template>
        <template v-if="field.$dirty && !field.alpha && field.$params.alpha">
            {{ label }} must only contain alphabet characters.
        </template>
        <template
            v-if="field.$dirty
                && undefined !== field.sameAsPlaintextPassword
                && false === field.sameAsPlaintextPassword"
        >
            Passwords must be identical.
        </template>
        <template
            v-if="field.$dirty
                && undefined !== field.email
                && false === field.email"
        >
            This field is not a valid email address.
        </template>
        <template
            v-if="field.$dirty
                && undefined !== field.vat
                && false === field.vat"
        >
            This field is not a valid VAT number.
        </template>
        <template
            v-if="field.$dirty
                && undefined !== field.isUnique
                && false === field.isUnique"
        >
            {{ label }} must be unique.
        </template>
    </div>
</template>

<script>
import RequiredValidation from "./RequiredValidation";
export default {
    name: "TextualValidations",
    components: {RequiredValidation},
    props: {
        label: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
    },
}
</script>
