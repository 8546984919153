import UserModel from "../model/user";
import baseApi from "./base/baseApi";
import baseAxios from "./base/baseAxios";

export default {
    async create(user) {
        const payload = user.transformToApi(true);

        return await baseAxios.post(
            "/users",
            payload,
            {
                addAuthorizationHeader: true,
            }
        );
    },
    async partialUpdate(userId, user) {
        const payload = user.transformToApi(false);

        return await baseAxios.put(
            `/users/${userId}`,
            payload,
            {
                addAuthorizationHeader: true,
            }
        );
    },
    async findAllModifiedSince(lastSyncedAt) {
        return await baseApi.fetchCollectionPage(
            '/users',
            1,
            lastSyncedAt,
            (itemData) => {
                return UserModel.transformFromAPI(itemData)
            },
        );
    },
};
