<template>
    <div class="flex flex-col sm:flex-row items-center justify-between content-center">
        <div class="mb-2 sm:mb-0 text-center sm:text-left">
            <span class="pr-2">
                Showing
                <span v-if="props.records !== 0">
                    {{ ((props.page - 1) * props.perPage + 1) }}
                    to
                    {{ (props.page * props.perPage) > props.records ? props.records : props.page * props.perPage }}
                    of
                    {{ props.records }}
                </span>
                <span v-else>
                    0
                </span>
                items
            </span>

            <div
                v-if="props.opts.perPageValues.length > 1 || props.opts.alwaysShowPerPageSelect"
                :class="`VueTables__limit`"
                class="inline-block"
            >
                <vt-per-page-selector />
            </div>
        </div>

        <t-pagination
            v-model="props.page"
            :total-items="props.records"
            :per-page="props.perPage"
            @input="page => props.setPage(page)"
        />
    </div>
</template>

<script>
import VtPerPageSelector from 'vue-tables-2/compiled/components/VtPerPageSelector';

export default {
    name: "MyPagination",
    components: {
        VtPerPageSelector,
    },
    props: {
        props: {
            type: Object,
            default: null,
        },
    },
}
</script>
