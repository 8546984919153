<template>
    <div class="VueTables__search-field block sm:inline-block relative align-bottom mb-2 sm:mb-0">
        <!--<label :for="`VueTables__search_${props.id}`" :class="props.theme.label">
            {{props.display("filter")}}
        </label>-->

        <span class="absolute inset-y-0 right-0 flex items-center px-2 border-solid border-l border-edge-primary">
            <button type="submit" class="focus:outline-none">
                <base-icon name="search" class="w-4 h-4 md:w-6 md:h-6" />
            </button>
        </span>

        <input
            :id="`VueTables__search_${props.id}`"
            ref="filter"
            type="text"
            autocomplete="off"
            :class="`VueTables__search__input ${props.theme.input} ${props.theme.small}`"
            :placeholder="props.display('filterPlaceholder')"
            @keyup="e=>props.search(props.opts.debounce)(e)"
        >
    </div>
</template>

<script>
import BaseIcon from "../BaseIcon";
export default {
    name: "MyGenericFilter",
    components: {BaseIcon},
    props: {
        props: {
            type: Object,
            default: null,
        },
    },
    methods: {
        focus() {
            this.$refs.filter.focus()
        },
        blur() {
            this.$refs.filter.blur()
        }
    }
}
</script>