const SKU_TUB_475_ML = {
        'key': 'tub_475_ml',
        'label': 'Tub 475 ml',
    },
    SKU_STICK = {
        'key': 'stick',
        'label': 'Stick',
    };

function all() {
    return [
        SKU_TUB_475_ML,
        SKU_STICK,
    ];
}

function getLabelForKey(skuTypeKey) {
    const skuType = this.all().find(skuType => skuTypeKey === skuType.key);

    return skuType.label;
}

export default { all, getLabelForKey };
