<template>
    <div>
        <breadcrumbs />

        <h1>Users</h1>

        <user-table :items="users" :display-loader="isLoading" />
    </div>
</template>

<script>

import UserTable from "./UserTable";
import Breadcrumbs from "../../components/Breadcrumbs";

export default {
    name: "UserOverview",
    components: {
        Breadcrumbs,
        UserTable,
    },
    computed: {
        isLoading() {
            return this.$store.getters["user/isLoading"];
        },
        users() {
            return this.$store.getters["user/users"];
        },
    },
};
</script>