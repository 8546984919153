const NotificationPlugin = {
    install(Vue) {
        Vue.prototype.showErrorNotification = (title, errorMessage) => {
            Vue.prototype.$notify(
                {
                    group: "topNotifications",
                    type: 'error',
                    title: title,
                    text: errorMessage,
                },
                10000
            );
        };

        Vue.prototype.showSuccessNotification = (title, errorMessage) => {
            Vue.prototype.$notify(
                {
                    group: "topNotifications",
                    type: 'success',
                    title: title,
                    text: errorMessage,
                },
                2000
            );
        };

        Vue.prototype.showInfoNotification = (title, errorMessage) => {
            Vue.prototype.$notify(
                {
                    group: "topNotifications",
                    type: 'info',
                    title: title,
                    text: errorMessage,
                },
                5000
            );
        };
    },
}

export default NotificationPlugin;
