/* eslint-disable no-console */
/* eslint-disable no-process-env */

import {register} from 'register-service-worker';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import Storage from "./store/persistence/persistentStorage";

const notifyUserAboutUpdate = worker => {
    alertify.confirm(
        'New version available',
        'A new version of the app is available! Reload the app? Warning: this action will reset your data, make sure that you have no unsynced changes on your device!',
        () => {
            Storage.iterate(function(value, key/*, iterationNumber*/) {
                // Resulting key/value pair -- this callback will be executed for every item in the database.

                // Return the app to the minimal state without requiring the user to re-log in
                const ignoredItems = [
                    'liq_access_token',
                    'liq_app_user_id',
                    'liq_app_user_roles',
                    'liq_refresh_token',
                ];

                if (!ignoredItems.includes(key)) {
                    console.log('Deleting data with key', key);

                    Storage.removeItem(key);
                }
            }).then(function() {
                console.log('Clearing stored data has completed');

                worker.postMessage({action: "skipWaiting"});
            }).catch(function(err) {
                console.log(err);
            });
        },
        () => {
            console.log('User cancelled, not loading new version.')
        }
    );
};

if (process.env.NODE_ENV === 'production') {
    //navigator.serviceWorker.register('/static/mod/practice/service-worker.js', { scope: '/' })
    register(`${process.env.BASE_URL}service-worker.js`, {
        //registrationOptions: {scope: './'},
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            );
        },
        registered(registration) {
            console.log('Service worker has been registered.');

            setInterval(() => {
                console.log('Timeout reached, triggering automatic service worker update check');
                registration.update();
            }, 900000); // = every 15 minutes
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            console.log('New content is downloading.');
        },
        updated(registration) {
            console.log('New content is available; please refresh.');
            notifyUserAboutUpdate(registration.waiting);
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(error) {
            console.error('Error during service worker registration:', error);
        }
    });

    var refreshing;
    navigator.serviceWorker.addEventListener('controllerchange', function () {
        if (refreshing) {
            return;
        }
        window.location.reload();
        refreshing = true;
    });
}
